import React, { useState } from "react";
import { useEffect } from "react";

import { useSelector } from "react-redux";

import UserDropdown from "../UserDropdown";

import BootstrapTooltip from "../common/TooltipUI/customToolTip";
import BackIcon from "../../assets/icons/BackIcon";
import { IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import browserRoutes from "../../constants/browserRoutes";
import V2SearchIcon from "../../assets/svgs/V2SearchIcon";
import CommentSendIcon from "../../assets/svgs/CommentSendIcon";
import { Input } from "antd";
import { environment } from "../../constants/serverRoutes";
import NewCustomCopilot from "../NewCustomCopilot";

const DashboardTopNav = ({
  target,
  title,
  backButton,
  children,
  datePicker,
  setDateUpdate,
  deleteQuerywithoutDate,
  isTopNavSearch,
  clientDropDownComp,
  dateComp,
  searchComp,
  topSearchHide,
  topSearchBot,
  input,
  setInput,
  handleChange,
  handleSubmit,
  inputCognative,
  setInputCognative,
  handleChangeCognative,
  handleSubmitCognative,
}) => {
  const isTopSearchHidden = topSearchHide !== false; // Default to true if not explicitly false

  const { loginUserData, currentModule } = useSelector(
    (state) => state.pReducers.user
  );

  const nav = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const url = pathname.split("/")[1];
  const [searchVisible, setSearchVisible] = useState(false);
  const [inputMain, setInputMain] = useState("");
  const [inputCognativeSearch, setInputCognativeSearch] = useState("");
  const handleBackBtn = () => {
    nav(-1);
  };
  const handleChangeMain = (e) => {
    setInputMain(e.target.value);
  };

  const handleChangeCognativeSearch = (e) => {
    if (setInputCognative) {
      setInputCognative(e.target.value);
      handleChangeCognative();
    }
    setInputCognativeSearch(e.target.value);
  };

  const handleSubmitMain = (e) => {
    // if (inputMain) {
    //   nav(`${browserRoutes?.DR_SEARCH}?search=${inputMain}`);
    // }
    if (inputMain) {
      nav(`${browserRoutes?.CHAT_BOT_RESULT}?search=${inputMain}`);
    }
  };

  const handleSubmitCognativeSearch = (e) => {
    if (inputCognativeSearch) {
      nav(`${browserRoutes?.DR_SEARCH}?search=${inputCognativeSearch}`);
    }
  };

  useEffect(() => {
    if (
      pathname == browserRoutes.CLAIM_PAYMENT_835 ||
      pathname == browserRoutes.DENIALS_DETAIL ||
      pathname == browserRoutes.DENAIL_BY_SERVICELINE ||
      pathname == browserRoutes.CLAIM_MANAGER_837 ||
      pathname == browserRoutes.PARIALLY_DENIED ||
      pathname == browserRoutes.DRUG_ACC_RATE ||
      pathname == browserRoutes.USERS_LIST ||
      pathname == browserRoutes.REVERSAL_DENIED ||
      pathname == browserRoutes.CLAIM_PAYMENT_835_UNDER_PAYMENT
    ) {
      setSearchVisible(true);
    }
  }, []);

  return (
    <div
      className="DR-New-Design-top-bar"
      // className={
      //   environment === "dev"
      //     ? "DR-New-Design-top-bar DR-top-nav-dev"
      //     : "DR-New-Design-top-bar"
      // }
    >
      {backButton && (
        <BootstrapTooltip title="Back">
          <div className="pull-left mr-3">
            <IconButton
              className="DR-toll-btn back-icon-btn dr-tool-bd-rit"
              onClick={handleBackBtn}
            >
              <BackIcon />
            </IconButton>
          </div>
        </BootstrapTooltip>
      )}
      <div
        className={`DR-v2-New-title-side w-full ${backButton ? "pl-4" : ""}`}
      >
        {/* {currentModule.label === "Copilot" ? <NewCustomCopilot /> : null} */}

        <h2 className="V2-DR-page-title relative">{title}</h2>
        <div className="DR-Search-input-main-top">
          <div
            className="DR-Search-input"
            style={{
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {/* {topSearchBot && (
              <div style={{ position: "relative" }}>
                <input
                  htmlFor="search-new"
                  type="text"
                  id="name"
                  value={setInput ? input : inputMain}
                  onChange={setInput ? handleChange : handleChangeMain}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      setInput ? handleSubmit() : handleSubmitMain();
                    }
                  }}
                  placeholder="Write your question here..."
                  style={{ width: "650px", maxWidth: "650px" }}
                />

                <button
                  onClick={() => {
                    setInput ? handleSubmit() : handleSubmitMain();
                  }}
                >
                  <CommentSendIcon />
                </button>
              </div>
            )} */}

            {/* {!loginUserData?.isOnlyPolicyCopilotSelected &&
              currentModule.label !== "Copilot" &&
              window.location.pathname !== browserRoutes?.CHAT_BOT_RESULT &&
              currentModule.label !== "Smart Appeals" && (
                <div style={{ position: "relative" }}>
                  <input
                    htmlFor="search-new"
                    type="text"
                    id="name"
                    value={setInput ? input : inputMain}
                    onChange={setInput ? handleChange : handleChangeMain}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        setInput ? handleSubmit() : handleSubmitMain();
                      }
                    }}
                    placeholder="Write your question here..."
                    style={{ width: "550px", maxWidth: "650px" }}
                  />
                  <button
                    onClick={() => {
                      setInput ? handleSubmit() : handleSubmitMain();
                    }}
                  >
                    <CommentSendIcon />
                  </button>
                </div>
              )} */}

            {isTopSearchHidden && (
              <div style={{ position: "relative" }}>
                <input
                  htmlFor="search-new"
                  type="text"
                  id="name"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      setInputCognative
                        ? handleSubmitCognative()
                        : handleSubmitCognativeSearch();
                    }
                  }}
                  onChange={
                    setInputCognative
                      ? handleChangeCognative
                      : handleChangeCognativeSearch
                  }
                  value={inputCognative ? inputCognative : inputCognativeSearch}
                  placeholder="Search Claim"
                  style={{
                    width: "450px",
                    maxWidth: "650px",
                    backgroundColor:
                      inputCognative || inputCognativeSearch ? "#fff" : "",
                  }}
                />
                <button
                  className={
                    inputCognative || inputCognativeSearch
                      ? "DR-search-icon-value"
                      : ""
                  }
                  onClick={() => {
                    setInputCognative
                      ? handleSubmitCognative()
                      : handleSubmitCognativeSearch();
                  }}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <CommentSendIcon />
                </button>
              </div>
            )}

            {/* {!topSearchHide && (
              <div style={{ position: "relative" }}>
                <input
                  htmlFor="search-new"
                  type="text"
                  id="name"
                  value={setInput ? input : inputMain}
                  onChange={setInput ? handleChange : handleChangeMain}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      setInput ? handleSubmit() : handleSubmitMain();
                    }
                  }}
                  placeholder="Search"
                />

                <button
                  onClick={() => {
                    setInput ? handleSubmit() : handleSubmitMain();
                  }}
                >
                  <V2SearchIcon />
                </button>
              </div>
            )} */}

            {/* <p>Welcome to the Azure Cognitive Search!</p> */}
          </div>
        </div>
        {/* {environment === "dev" && <div className="Dev-tag">Development</div>} */}
        {children}
        <div className="DR-V2-profile-div line-op-mian">
          {datePicker && (
            <div
              style={{
                borderRadius: "4px",
                padding: "0 5px",
                marginRight: "2px",
                height: "30px",
              }}
              id="Step#5"
            >
              {dateComp}
            </div>
          )}
          {clientDropDownComp && (
            <div
              style={{
                borderRadius: "4px",
                padding: "0 5px",
                marginRight: "2px",
                height: "30px",
              }}
              id="Step#6"
            >
              {clientDropDownComp}
            </div>
          )}
          {/* <span className="DR-line-new-design mt-0 mr-2"></span> */}

          {/* <div className="DR-V2-top-search">
            <div
              className="tb-search-outr"
              style={{ display: "inline-block", float: "right" }}
            >
              <Input
                className="tb-search-st mr-2"
                allowClear={true}
                placeholder="Search"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    setInputCognative
                      ? handleSubmitCognative()
                      : handleSubmitCognativeSearch();
                  }
                }}
                onChange={
                  setInputCognative
                    ? handleChangeCognative
                    : handleChangeCognativeSearch
                }
                value={inputCognative ? inputCognative : inputCognativeSearch}
                prefix={<V2SearchIcon />}
                enterButton={false}
              />
            </div>
          </div> */}

          {/* {searchVisible && (
            <div className="DR-V2-top-search">{searchComp}</div>
          )} */}
          {/* above is ERA And Claim Search */}
          <UserDropdown target={target} />
        </div>
      </div>
    </div>
  );
};

export default DashboardTopNav;
