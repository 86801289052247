import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";

import {
  resetListingState,
  setInitialPageSize,
  setListingState,
} from "../../../Redux/Reducers/filters/claimManagerFilters";
import BootstrapTooltip from "../TooltipUI/customToolTip";
import { toast } from "react-toastify";

import { users } from "../../../actions";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import {
  setAllFiltersNoOfRecordInTable,
  setCurrentModuleRedux,
  // logoutUser,
  setLoginTime,
  setOrganization,
  setPreferences,
  // updateApplicationToken,
  updateApplicationTokenExpiry,
  userData,
} from "../../../Redux/Reducers/UserReducer";

import { gettingAdvSettingData_Call } from "../../../Redux/Reducers/Settings/advanceSettingSlice";

import { msalInstance } from "../../..";
import { loginRequest } from "../../../AuthenticationAD/authConfig";
import {
  getAllBoughtModules,
  getAllDenialCoordinator,
  getAllSubsriptionModules,
  getModulePricing,
  getUserDetailsForSelectedModules,
  updateMasterListCodesFunction,
} from "../../../Redux/Reducers/user/userSlice";
import { approvedTenants } from "../../../AuthenticationAD/tenantConfig";

import {
  domainID,
  environment,
  serverRoutes,
} from "../../../constants/serverRoutes";
import store from "../../../Redux/store";
import CPTPopOver from "../CPTPopOver";
import singleLogout, { singleLogout2 } from "../../../Routes/Logout";
import { claimFilingIndicatorMapping } from "./claimFilingIndicator";
import { executeQuery } from "../../../Redux/Reducers/QueueDetails/claimQueueSlice";
import browserRoute from "../../../constants/browserRoutes";

import CMSIcon from "../../../assets/ZoeImages/cms.svg";
import FDAIcon from "../../../assets/ZoeImages/fda.svg";
import PrivateIcon from "../../../assets/ZoeImages/private.svg";
import { getAllCopilotBot } from "../../../Redux/Reducers/Copilot/CopilotSlice";
import { gettingClientsConfigListForDropdown } from "../../../Redux/Reducers/ClientsConfig/ClientsConfigSlice";
import SortingDropDown from "../PageTitleDropDown/SortingDropDown";
import NewSortingDropDown from "../PageTitleDropDown/NewSortingDropDown";
import { NavLink } from "react-router-dom";
import { gettingDenialsConfigList_Call } from "../../../Redux/Reducers/DenialCategoriesConfig/DenialsCategoriesConfigSlice";
import ScoreUp from "../../../assets/svgs/ScoreUp";
import ScoreDown from "../../../assets/svgs/ScoreDown";
import DRFlagClaimIcon from "../../../assets/svgs/DRFlagClaim";
import { getClaimPaymentCustomColumns_Call } from "../../../Redux/Reducers/Claims/claimPaymentReducer/claimPaymentSlice";
import NewClaimIcon from "../../../assets/svgs/NewClaimIcon";

//domain extension
export const mainDomain = "ondatarovers.ai";
export const denialsHeatmapGroupByEnum = {
  AllMissedOpp: "AI (Opportunity)",
  AllActual: "All (Actual)",
};

export const subscriptionPlanNameKey = "moduleName";
export const subscriptionPlanDisplayNameKey = "displayName";
export const subscriptionPlanIdKey = "moduleId";
export const ERROR_CHATBOT = `We apologize, but our website is currently experiencing technical difficulties.
Our team is actively working to resolve the issue.Please check back shortly. 
Thank you for your patience.`;

export const moduleRolesEnum = {
  Appeals: "Appeals",
  Denials: "Denials",
  Policy: "Policy",
  Policy_API: "Policy API",
  Eligibility: "Eligibility",
  Eligibility_API: "Eligibility API",
  Custom_Knowledge: "Custom Knowledge",
  Custom_Knowledge_API: "Custom Knowledge API",
};
export const moduleRolesEnumPolicy = {
  Policy: "Policy",
  Policy_API: "Policy API",
  Eligibility: "Eligibility",
  Eligibility_API: "Eligibility API",
  Custom_Knowledge: "Custom Knowledge",
  Custom_Knowledge_API: "Custom Knowledge API",
};
export const botRoutes = [
  browserRoute?.ASK_ZOE,
  browserRoute?.ASK_ROVERS,
  browserRoute?.ASK_CUSTOM,
  browserRoute?.DOMAIN_COPILOT,
  browserRoute?.ASK_ZOE_CHAT,
  // browserRoute?.UPLOAD_DOCOMENTS,
];
export const botRoutesChatHistory = [
  browserRoute?.ASK_ZOE,
  browserRoute?.ASK_ZOE_CHAT,

  // browserRoute?.UPLOAD_DOCOMENTS,
];

export const payeeListArr = [
  {
    value: "Aetna",
    label: "Aetna",
  },

  {
    value: "BCBS California",
    label: "BCBS California",
  },
  {
    value: "BCBS Illinois",
    label: "BCBS Illinois",
  },
  {
    value: "BCBS Anthem",
    label: "BCBS Anthem",
  },
  {
    value: "Cigna",
    label: "Cigna",
  },

  {
    value: "CMS National",
    label: "CMS National",
  },
  // {
  //   value: "Custom",
  //   label: "Custom",
  // },
  {
    value: "FDA",
    label: "FDA",
  },

  {
    value: "Humana",
    label: "Humana",
  },

  {
    value: "Medicaid",
    label: "Medicaid",
  },
  {
    value: "NCCN",
    label: "NCCN",
  },
  {
    value: "UHC",
    label: "UHC",
  },
];
export const payeeList = {
  cigna: {
    value: "cigna",
    label: "cigna",
  },
  ambetter: {
    value: "ambetter",
    label: "ambetter",
  },

  signa: {
    value: "cigna",
    label: "cigna",
  },
  aetna: {
    value: "aetna",
    label: "aetna",
  },
  atena: {
    value: "aetna",
    label: "aetna",
  },
  clover_health: {
    value: "clover_health",
    label: "clover_health",
  },
  fidelis_care: {
    value: "fidelis_care",
    label: "fidelis_care",
  },
  oscar_health: {
    value: "oscar_health",
    label: "oscar_health",
  },
  wellcare: {
    value: "well_care",
    label: "well_care",
  },
  upmc_healthplan: {
    value: "upmc_health_plan",
    label: "upmc_healthplan",
  },
  cms: {
    value: "cms",
    label: "cms",
    variations: {
      cms_national: {
        value: "cms national",
        label: "cms national",
      },
    },
  },
  medicare: {
    value: "cms",
    label: "cms",
  },
  medicaid: {
    value: "medicaid",
    label: "medicaid",
  },
  nccn: {
    value: "nccn",
    label: "nccn",
  },
  custom: {
    value: "custom",
    label: "custom",
  },
  practitioner: {
    value: "practitioner",
    label: "practitioner",
  },
  fda: {
    value: "fda",
    label: "fda",
  },
  tricare_east: {
    value: "tricare_east",
    label: "tricare_east",
  },
  caresource: {
    value: "caresource",
    label: "caresource",
  },
  bcbs: {
    value: "bcbs anthem",
    label: "bcbs anthem",
    variations: {
      bcbs_illinois: {
        value: "bcbs illinois",
        label: "bcbs illinois",
      },
      bcbs_florida: {
        value: "bcbs_florida",
        label: "bcbs florida",
      },
      bcbsillinois: {
        value: "bcbs illinois",
        label: "bcbs illinois",
      },
      bcbs_anthem: {
        value: "bcbs anthem",
        label: "bcbs anthem",
      },
      bcbs_pennsylvania: {
        value: "bcbs_pennsylvania",
        label: "bcbs_pennsylvania",
      },
      bcbs_tennessee: {
        value: "bcbs_tennessee",
        label: "bcbs_tennessee",
      },
      bcbs_texas: {
        value: "bcbs_texas",
        label: "bcbs_texas",
      },

      bcbsanthem: {
        value: "bcbs anthem",
        label: "bcbs anthem",
      },
      bcbs_california: {
        value: "bcbs",
        label: "bcbs",
      },
      bcbscalifornia: {
        value: "bcbs",
        label: "bcbs",
      },
    },
  },
  humana: {
    value: "humana",
    label: "humana",
  },
  uhc: {
    value: "uhc",
    label: "uhc",
  },
  molina_healthcare: {
    value: "molina_healthcare",
    label: "molina_healthcare",
  },
};

export const payeeListArr2 = [
  { id: "Aetna", display: "Aetna" },
  { id: "Ambetter", display: "Ambetter" },
  { id: "BCBS_Anthem", display: "BCBS_Anthem" },
  { id: "BCBS_California", display: "BCBS_California" },
  { id: "BCBS_Florida", display: "BCBS_Florida" },
  { id: "BCBS_Illinois", display: "BCBS_Illinois" },
  { id: "BCBS_Pennsylvania", display: "BCBS_Pennsylvania" },
  { id: "BCBS_Tennessee", display: "BCBS_Tennessee" },
  { id: "BCBS_Texas", display: "BCBS_Texas" },
  { id: "CareSource", display: "CareSource" },
  { id: "Cigna", display: "Cigna" },
  { id: "Clover_Health", display: "Clover_Health" },
  { id: "CMS_National", display: "CMS_National" },
  { id: "FDA", display: "FDA" },
  { id: "Fidelis_Care", display: "Fidelis_Care" },
  { id: "Humana", display: "Humana" },
  { id: "Medicaid", display: "Medicaid" },
  { id: "Molina_Healthcare", display: "Molina_Healthcare" },
  { id: "NCCN", display: "NCCN" },
  { id: "Oscar_Health", display: "Oscar_Health" },
  { id: "Tricare_East", display: "Tricare_East" },
  { id: "UHC", display: "UHC" },
  {
    id: "UPMC_HealthPlan",
    display: "UPMC_HealthPlan",
  },
  { id: "WellCare", display: "WellCare" },
];
export const BCBS_AllowedStates = [
  {
    id: "illinois",
    value: "bcbs illinois",
  },
  {
    id: "florida",
    value: "bcbs florida",
  },
  {
    id: "illinois",
    value: "bcbs illinois",
  },
  {
    id: "anthem",
    value: "bcbs anthem",
  },
  {
    id: "california",
    value: "bcbs",
  },
  {
    id: "pennsylvania",
    value: "bcbs_pennsylvania",
  },
  {
    id: "tennessee",
    value: "bcbs_tennessee",
  },
  {
    id: "texas",
    value: "bcbs_texas",
  },
];
export const payeeListArrForPolicyCopilot = [
  { id: "Aetna", display: "Aetna" },
  { id: "Ambetter", display: "Ambetter" },
  { id: "BCBS", display: "BCBS" },
  { id: "CareSource", display: "CareSource" },
  { id: "Cigna", display: "Cigna" },
  { id: "Clover_Health", display: "Clover_Health" },
  { id: "CMS_National", display: "CMS_National" },
  { id: "FDA", display: "FDA" },
  { id: "Fidelis_Care", display: "Fidelis_Care" },
  { id: "Humana", display: "Humana" },
  { id: "Medicaid", display: "Medicaid" },
  { id: "Molina_Healthcare", display: "Molina_Healthcare" },
  { id: "NCCN", display: "NCCN" },
  { id: "Oscar_Health", display: "Oscar_Health" },
  { id: "Tricare_East", display: "Tricare_East" },
  { id: "UHC", display: "UHC" },
  {
    id: "UPMC_HealthPlan",
    display: "UPMC_HealthPlan",
  },
  { id: "WellCare", display: "WellCare" },
];

export const botNames = {
  Eligibility: "eligibilitycopilot",
  Policy: "policycopilot",
};
const garbageValues = [
  "(sandbox:/document_store/0/documents/Tennessee-Application-Form%20for%20Health%20Coverage%20%26%20Help%20Paying%20Costs.pdf)",
  "(sandbox:/document_store/0/documents/Tennessee-Application-Form%20for%20Health%20Coverage%20and%20Help%20Paying%20Costs.pdf)",
  "sandbox:/retrieved_document/",
  "###",
  "Answer",
];
export const neglectMessages = [
  "The requested information is not available in the retrieved data. Please try another query or topic.",
  "Sorry, required information is not available in the provided documents. Please try another query or topic.",
  "The requested information is not found in the retrieved data. Please try another query or topic.",
  "Sorry, required information is not available in provided documents.",
  "Sorry, required information is not available in the provided documents.",
  " Sorry, required information is not available in the provided documents.",
  "### Sorry, required information is not available in the provided documents.",
  "### Sorry, required information is not available in provided documents.",
  "The retrieved documents do not contain any information about the modifier required to be used with the procedure of induced abortion. The requested information is not available in the retrieved data. Please try another query or topic.",
  "The modifier required to be used with the procedure of induced abortion is not mentioned in the retrieved documents. Please consult the specific payer policy or guidelines for information on the required modifier for the procedure of induced abortion.",
  "The information in the retrieved documents does not specifically mention whether the diagnosis of obesity alone is sufficient for coverage of the Roux-en-Y procedure. Therefore, the required information is not available in the provided documents.",
  "This question is out of the scope of the provided documents. If you have any other questions related to the content of the documents, feel free to ask!",
  "The user question is out-of-domain.",
  "Sorry",
];
// NUMBER FORMATE FUNCTION
export const numberFormatIntoShortForm = (number) => {
  var SI_POSTFIXES = ["", "K", "M", "B", "T", "P", "E"];
  // what tier? (determines SI prefix)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;
  // if zero, we don't need a prefix
  if (tier === 0) return number;

  // get postfix and determine scale
  var postfix = SI_POSTFIXES[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;
  // format number and add postfix as suffix
  var formatted = scaled.toFixed(1) + "";

  // remove '.0' case
  if (/\.0$/.test(formatted))
    formatted = formatted.substr(0, formatted.length - 2);

  return formatted + postfix;
};
export const numberFormatIntoShortForm2 = (number) => {
  var SI_POSTFIXES = ["", "K", "M", "B", "T", "P", "E"];
  // what tier? (determines SI prefix)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;
  // if zero, we don't need a prefix
  if (tier === 0) return number;

  // get postfix and determine scale
  var postfix = SI_POSTFIXES[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;
  // format number and add postfix as suffix
  var formatted = scaled.toFixed(2) + "";

  // remove '.0' case
  if (/\.0$/.test(formatted))
    formatted = formatted.substr(0, formatted.length - 2);

  return formatted + postfix;
};

export const getUserFriendlyColumnNamesForQuerybuilder = (name) => {
  let name1 = name;
  let newName = name1.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
  return newName;
};

export function getMonthNumbersAndYear(dateRange) {
  const fromDate = new Date(dateRange.from);
  const toDate = new Date(dateRange.to);

  const monthNumbers = [];
  const year = fromDate.getFullYear();
  let currentDate = fromDate;

  while (currentDate <= toDate) {
    const month = currentDate.getMonth() + 1; // Adding 1 to get month number in digits
    monthNumbers.push(month);

    currentDate.setMonth(currentDate.getMonth() + 1); // Move to the next month
  }

  const monthNumbersString = monthNumbers.join(", ");

  return { monthNumbers: monthNumbersString, year };
}

export const returnUserId = () => {
  const userId = store.getState().pReducers.user.loginUserData?.userId;
  return userId;
};
export const getIsRCM = () => {
  const isRcm = store.getState().pReducers.user.loginUserData.isRcm;
  return isRcm;
};
export const getConvertedSQLColumnsForDB = (query, fields, queryType) => {
  let namesObj835 = {
    DOS: "CP.DOS",
    ClaimStatusCodeId: "CP.[ClaimStatusCodeId]",
    FilingIndicatorCodeId: "CP.FilingIndicatorCodeId",
    PayeeId: "CP.PayeeId",
    PayerId: "CP.PayerId",
    ChargeAmount: "CP.[ChargeAmount]",
    AllowedAmount: "CP.[AllowedAmount]",
    PaymentAmount: "CP.[PaymentAmount]",
    PatientResponsibilityAmount: "CP.PatientRespAmount",
    AdjudicatedProcedureCode1: "SP.AdjudicatedProcedureCode1",
    ReasonCode: "SA.ReasonCode",
    IPRCPaymentRemarkCode: "IPRC.PaymentRemarkCode",
    OPRCPaymentRemarkCode: "OPRC.PaymentRemarkCode",

    MLCPMLProbability: "MLCP.MLProbability",
    MLCPMLExpectedPayout: "MLCP.MLExpectedPayout",
    CPMedicalRecordNo: "CP.MedicalRecordNo",
    CPPrincipalDiagCode: "CP.PrincipalDiagCode",
  };

  let namesObj837 = {
    DOS: "CR.DOS",
    PayerId: "CR.PayerId",
    ChargeAmount: "CR.[ChargeAmount]",
    CRPhysicianId: "CR.PhysicianId",
    CRMedicalRecordNo: "CR.MedicalRecordNo",
    CRRepricedAllowedAmount: "CR.RepricedAllowedAmount",
    CRBillingProviderId: "CR.BillingProviderId",
    MLCRPApprovedPer: "MLCRP.ApprovedChargeAmountPer",
    MLServiceLinePer: "MLCRP.ApprovedServiceLinePer",
    CRFrequencyCode: "CR.FrequencyCode",
    SRProcedureCode: "SR.ProcedureCode",
  };

  if (query?.length) {
    if (queryType) {
      // 835 obj handle here
      fields &&
        fields?.length &&
        fields?.forEach((element, i) => {
          let check = query.replaceAll(
            element?.name,
            `${namesObj835[element?.name]}`
          );
          query = check;
        });
    } else {
      // 837 obj handle here
      fields &&
        fields?.length &&
        fields?.forEach((element, i) => {
          let check = query.replaceAll(
            element?.name,
            `${namesObj837[element?.name]}`
          );
          query = check;
        });
    }
  }
  return query;
};

export const nonDraggableAppealList = [
  "Insurance Cover Form",
  "Appeal letter.txt",
];
export const sortValuesAppealList = (arr) => {
  // Make a deep copy of the input array to avoid modifying the original
  const nonDraggable = ["Insurance Cover Form", "Appeal letter.txt"];
  if (arr && arr.length && nonDraggable && nonDraggable.length) {
    const x = [...arr];

    x.sort((a, b) => {
      let aIndex = nonDraggable.includes(a.fileName)
        ? nonDraggable.indexOf(a.fileName)
        : Number.MAX_VALUE;
      let bIndex = nonDraggable.includes(b.fileName)
        ? nonDraggable.indexOf(b.fileName)
        : Number.MAX_VALUE;

      return aIndex - bIndex;
    });

    return x;
  }
};
export const getLegendsShortName = (name) => {
  const nameArr = name.split(" ");
  let shortName = "";
  if (nameArr.length > 1) {
    shortName = `${nameArr[0]} ${nameArr[1]}`;
  } else {
    shortName = nameArr.join(" ");
  }
  return shortName;
};

export const setTargetConditionalVal = (denialPer, val) => {
  if (denialPer == 0) {
    return true;
  } else {
    if (val < denialPer) {
      return true;
    } else {
      return false;
    }
  }
};
export const returnIndexDocAttached = (string) => {
  if (string) {
    const inputString = string;
    const indexPattern = /\[doc(\d+)(?:,\s*doc\d+)*\]/g;
    const indexMatches = inputString?.match(indexPattern);
    const indexNumbers = indexMatches?.map((match) => match.match(/\d+/)[0]);
    return indexNumbers;
  }
  return [];
};

export const returnIndexDocAttachedWithBracket = (string) => {
  if (string) {
    const inputString = string;
    const indexPattern = /\(doc(\d+)(?:,\s*doc\d+)*\)/g;
    const indexMatches = inputString?.match(indexPattern);
    const indexNumbers = indexMatches?.map((match) => match.match(/\d+/)[0]);
    return indexNumbers;
  }
  return [];
};

export const returnIndexNonDocAttached = (string) => {
  if (string) {
    const inputString = string;
    const indexPattern = /\[(\d+)\]/g;
    const indexMatches = inputString?.match(indexPattern);
    const indexNumbers = indexMatches?.map((match) => match.match(/\d+/)[0]);
    return indexNumbers;
  }
  return [];
};
// export const groupAndFormat = () => {
//   const result = {};
//   const inputArray = [
//     "j9207-45,50,60",
//     "J9206-50,60,45",
//     "J9205-B5,45,60",
//     "J9203-Y2,45,60",
//     "J9204-45,60,B5",
//   ];
//   inputArray.forEach((item) => {
//     const [prefix, values] = item.split("-");

//     result[prefix] = result[prefix] || [];
//     result[prefix].push(values);
//   });

//   console.log("hey khizer===", result);
//   const output = [];

//   for (const prefix in result) {
//     if (result[prefix]) {
//       const formattedValues = result[prefix].join(",");
//       output.push(`${prefix}-${formattedValues}`);
//     }
//   }
//   console.log("hey khizer===", output);
//   return output;
// };
function compareAndJoinKeys(obj) {
  const result = {};
  // console.log();
  // Convert each array to a sorted, comma-separated string for comparison
  const sortedArrays = Object.keys(obj)?.reduce((acc, key) => {
    const sortedArray = obj[key].sort().join(",");
    acc[sortedArray] = acc[sortedArray] || [];
    acc[sortedArray].push(key);
    return acc;
  }, {});

  // Join keys with a hyphen
  for (const sortedArray in sortedArrays) {
    const keys = sortedArrays[sortedArray];
    if (keys.length > 1) {
      const joinedKeys = keys.join(",");
      result[joinedKeys] = obj[keys[0]];
    } else {
      result[keys[0]] = obj[keys[0]];
    }
  }

  return result;
}
export const groupAndFormat = (inputArray) => {
  // const inputObject = {
  //   J9203: ["Y2", "45", "60"],
  //   J9204: ["45", "60", "B5"],
  //   J9206: ["50", "60", "45"],
  //   j9207: ["45", "50", "60"],
  //   J9205: ["B5", "45", "60"],
  // };
  const joinedCompared = compareAndJoinKeys(inputArray);
  return Object.keys(joinedCompared)?.length ? joinedCompared : {};
};
export const areArraysEqual = (arr1, arr2) => {
  // Check if both arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Create copies of the arrays and sort them
  let sortedArr1 = arr1.slice().sort();
  let sortedArr2 = arr2.slice().sort();

  // Compare the sorted arrays
  return sortedArr1.every((element, index) => element === sortedArr2[index]);
};
export const removeBrackets = (string) => {
  if (!string) return "";

  const removableBrackets = ["]"];
  removableBrackets?.map((item) => {
    string = string.replaceAll(item, "");
  });
  return string;
};
export const returnLocationId = () => {
  if (window.location.pathname !== browserRoute?.ASK_ZOE_CHAT) {
    return window.location.href
      ?.split("?")?.[1]
      ?.split("id=")?.[1]
      ?.split("&")?.[0];
  } else {
    return null;
  }
};
export const removeHashes = (string) => {
  let remove = /#\[(.*?)\]/g;
  let result = string.replace(remove, "$1");
  return result;
};
export const removeGarbageValues = (string) => {
  garbageValues?.forEach((item) => {
    string = string.replaceAll(item, "");
  });
  return string;
};
// export const returnAnchorTag = (string) => {
//   if (!string) return ""; // If string is empty or null, return an empty string

//   let urlRegex =
//     /\[([^\]]+)\]\s*\(?(https?:\/\/[^\s\)]+)\)?|(https?:\/\/[^\s\)]+)/g;

//   // Replace URLs with anchor tags
//   let replacedString = string.replace(urlRegex, function (match, p1, p2, p3) {
//     // Selecting the correct capture group for the URL
//     let url = p2 || p3 || match; // If no capture group matches, use the entire match
//     // If there is associated anchor text

//     if (p1) {
//       let anchorText = p1;
//       return (
//         '<a class="DR-tb-link" href="' +
//         removeBrackets(url) +
//         '">' +
//         anchorText +
//         "</a>"
//       );
//     } else {
//       return (
//         '<a class="DR-tb-link" href="' +
//         removeBrackets(url) +
//         '">' +
//         url +
//         "</a>"
//       );
//     }
//   });

//   return replacedString;
// };
export const returnAnchorTag = (content) => {
  // 1. Handle markdown-style links and avoid double wrapping
  content = content.replace(
    /\[([^\]]+)\]\((https?:\/\/[^\s\)]+)\)/g,
    '<a href="$2" target="_blank">$1</a>'
  );

  // 2. Handle standalone URLs not already wrapped in <a> tags
  content = content.replace(
    /(?<!href=["'])https?:\/\/[^\s\)\]\"]+/g,
    '<a href="$&" target="_blank">$&</a>'
  );

  // 3. Ensure punctuation after URLs remains outside the link
  content = content.replace(
    /(<a href="https?:\/\/[^\s\)\]\"]+)(" target="_blank">)([.,!?)\]"\']+)/g,
    "$1$3$2"
  );
  content = content.replace(`</a></a>`, "</a>");
  content = content.replaceAll(`</a>"`, `"`);
  return content;
};
export const returnGroupAndFormatString = (obj, summary) => {
  let str = "";
  let descriptionStr = "";

  if (Object.keys(obj)?.length) {
    descriptionStr = descriptionStr + "Description of Codes\n";

    let procArr = [];
    let reasonArr = [];
    Object.keys(obj)?.forEach((item, index) => {
      // const split = item.split("-");
      const procedureSplit = item?.split(",");
      // const reasonSplit = split[1]?.split(",");
      procArr.push(...procedureSplit);
      reasonArr.push(...obj[item]);
      str = str + `Procedure Code ${index + 1}: ${item}\n`;
      str =
        str +
        `Reason Codes of Procedure code ${index + 1}: ${obj[
          item
        ]?.toString()}\n`;
    });

    str =
      str +
      `Payer Name: ${summary?.payerName}\nClaim Filing Indicator: ${
        summary?.filingIndicatorCode
      }-${claimFilingIndicatorMapping(summary?.filingIndicatorCode)}\n`;

    if (procArr?.length || reasonArr?.length) {
      removeDuplicatesFromArray(procArr)?.forEach((item) => {
        descriptionStr =
          descriptionStr +
          `${item}${
            getMasterListCodeDescription(item) &&
            `-${getMasterListCodeDescription(item)}`
          }`;
        descriptionStr = descriptionStr + "\n";
      });
      removeDuplicatesFromArray(reasonArr)?.forEach((item) => {
        descriptionStr =
          descriptionStr +
          `${item}${
            getMasterListCodeDescriptionReasonCode(item) &&
            `-${getMasterListCodeDescriptionReasonCode(item)}`
          }`;
        descriptionStr = descriptionStr + "\n";
      });
    }
  }
  return { mainString: str, codeDescriptionString: descriptionStr };
};

export const returnGroupAndFormatString2 = (obj, remarkCodeArr, summary) => {
  let str = "";
  let descriptionStr = "";

  if (Object.keys(obj)?.length) {
    descriptionStr = descriptionStr + "Description of Codes\n";

    let procArr = [];
    let reasonArr = [];
    Object.keys(obj)?.forEach((item, index) => {
      // const split = item.split("-");
      const procedureSplit = item?.split(",");
      // const reasonSplit = split[1]?.split(",");
      procArr.push(...procedureSplit);
      reasonArr.push(...obj[item]);
      str = str + `Procedure Code ${index + 1}: ${item}\n`;
      str =
        str +
        `Reason Codes of Procedure code ${index + 1}: ${obj[
          item
        ]?.toString()}\n`;

      if (remarkCodeArr?.[procArr[index]]?.length) {
        str =
          str +
          `Remark Code of Procedure code ${index + 1}: ${remarkCodeArr?.[
            procArr[index]
          ]?.toString()}\n`;
      }
    });

    str =
      str +
      `Payer Name: ${summary?.payerName}\nClaim Filing Indicator: ${
        summary?.filingIndicatorCode
      }-${claimFilingIndicatorMapping(summary?.filingIndicatorCode)}\n`;

    if (procArr?.length || reasonArr?.length) {
      removeDuplicatesFromArray(procArr)?.forEach((item) => {
        descriptionStr =
          descriptionStr +
          `${item}${
            getMasterListCodeDescription(item) &&
            `-${getMasterListCodeDescription(item)}`
          }`;
        descriptionStr = descriptionStr + "\n";
      });
      removeDuplicatesFromArray(reasonArr)?.forEach((item) => {
        descriptionStr =
          descriptionStr +
          `${item}${
            getMasterListCodeDescriptionReasonCode(item) &&
            `-${getMasterListCodeDescriptionReasonCode(item)}`
          }`;
        descriptionStr = descriptionStr + "\n";
      });
    }
  }
  return { mainString: str, codeDescriptionString: descriptionStr };
};

export const removeDocAttached = (string) => {
  if (string) {
    const indexPattern = /\[doc\d+\]/g;
    const cleanedString = string.replace(indexPattern, "");

    return cleanedString;
  }
  return string;
};
export const removeTagsAttached = (string) => {
  return string.replaceAll(/@\w+\s?/g, "");
};
export const removeTagsAttached2 = (string, tags) => {
  if (tags?.length && string) {
    tags.forEach((item) => {
      string = string.replace(item?.id, "");
    });
    return string?.trim();
  } else {
    return string;
  }
};
export const removePractitioner = (string) => {
  // return string?.toLocaleLowerCase()?.replaceAll("practitioner", "");
  return string?.replace(/practitioner/gi, "");
};
export const removeSupAttached = (string) => {
  if (string) {
    const indexPattern = /<b><sup>(\d+)<\/sup><\/b>/g;
    const cleanedString = string.replace(indexPattern, "");

    return cleanedString;
  }
  return string;
};

export const replaceDocAttached = (string) => {
  if (string) {
    const indexPattern = /\[doc(\d+)\]/g;
    const replacedString = string.replace(
      /\[doc(\d+)(?:,\s*doc\d+)*\]/g,
      "<b><sup>$1</sup></b>"
    );

    // const replacedString = string.replace(indexPattern, (match, docNumber) => {
    //   // Wrap the document number in <sup> tags to create superscript
    //   return <sup>{docNumber}</sup>;
    // });

    return replacedString;
  }
  return string;
};
export const makeBold = (str) => {
  return str.replaceAll(/\*\*(.*?)\*\*/g, "<b>$1</b>");
};
export const enhanceUserMsg = (string, tags) => {
  if (string && tags?.length) {
    tags.forEach((item) => {
      string = string.replace(
        item?.id,
        `<span class="boldTag">${item?.id}</span>`
      );
    });
    return string;
  } else {
    return string;
  }
};
export const enhanceBulletPoints = (inputString) => {
  // Wrap headers with <b> tags
  const headerRegex = /^(\d+\.\s*)(.*?:)/gm;

  // Replace headers with bold text
  let enhancedString = inputString.replace(
    headerRegex,
    function (match, p1, p2) {
      return `<b>${p1}${p2}</b>`;
    }
  );

  return enhancedString;
};
export const replaceDocAttachedWithBracket = (string) => {
  if (string) {
    const indexPattern = /\[doc(\d+)\]/g;
    const replacedString = string.replace(
      /\(doc(\d+)(?:,\s*doc\d+)*\)/g,
      "<b><sup>$1</sup></b>"
    );

    // const replacedString = string.replace(indexPattern, (match, docNumber) => {
    //   // Wrap the document number in <sup> tags to create superscript
    //   return <sup>{docNumber}</sup>;
    // });

    return replacedString;
  }
  return string;
};
export const replaceWithCommas = (string) => {
  if (string) {
    string = string.replaceAll("</b><b>", "</b><b><sup>,</sup></b><b>");
  }
  return string;
};
export const replaceWithIndex = (string, replaceItem, toBeReplacedItem) => {
  if (string) {
    string = string.replaceAll(
      `<b><sup>${toBeReplacedItem}</sup></b>`,
      `<b><sup>${replaceItem}</sup></b>`
    );
    string = string.replaceAll(
      `<b><sup>${replaceItem}</sup></b><b><sup>,</sup></b><b><sup>${replaceItem}</sup></b>`,
      `<b><sup>${replaceItem}</sup></b>`
    );
    string = string.replaceAll(
      `<b><sup>,</sup></b><b><sup>,</sup></b>`,
      "<b><sup>,</sup></b>"
    );
  }

  return string;
};
function modifyArray(arr) {
  const result = [];

  const titleMap = new Map();

  arr.forEach((item, index) => {
    const { chunk_id, title, content, filepath, id, metadata, url } = item;

    if (titleMap.has(title)) {
      const existingItem = titleMap.get(title);
      existingItem.indexes.push(index);
      existingItem.contentNew[`index${index}`] = content;
    } else {
      const newItem = {
        chunk_id,
        title,
        filepath,
        id,
        metadata,
        content,
        url,
        indexes: [index],
        contentNew: { [`index${index}`]: content },
      };
      titleMap.set(title, newItem);
      result.push(newItem);
    }
  });

  return result;
}
export const removeDuplicateFilePaths = (arr) => {
  const modifiedArray = modifyArray(arr);

  const uniqueFilePathsMap = new Map();

  modifiedArray.forEach((item) => {
    const { title } = item;
    if (uniqueFilePathsMap.has(title)) {
      const existingItem = uniqueFilePathsMap.get(title);
      existingItem.indexes = existingItem.indexes.concat(item.indexes);
      Object.assign(existingItem.content, item.content);
    } else {
      uniqueFilePathsMap.set(title, { ...item });
    }
  });

  return Array.from(uniqueFilePathsMap.values());
};
export const helpReplaceWithIndex = (string, arr) => {
  if (string?.length && arr?.length) {
    arr?.map((item, index) => {
      const { indexes } = item;
      indexes?.map((item2, index2) => {
        string = replaceWithIndex(string, index + 1, item2 + 1);
      });
    });
  }
  return string;
};

export const replaceNonDocAttached = (string) => {
  if (string) {
    // Define a regular expression pattern to match [N] where N is a sequence of digits.
    const indexPattern = /\[(\d+)\]/g;

    // Use the `replace` method to replace all occurrences of the pattern with the desired format.
    const replacedString = string.replace(
      /\[(\d+)(?:,\s*\d+)*\]/g,
      "<b><sup>$1</sup></b>"
    );

    return replacedString;
  }
  return string;
};
export const replaceSupAttached = (string) => {
  if (string) {
    const indexPattern = /\[doc(\d+)\]/g;
    const replacedString = string.replace(/<b><sup>(\d+)<\/sup><\/b>/g, "$1");

    return replacedString;
  }
  return string;
};
export const getLegendsExtraShortName = (name) => {
  const nameArr = name.split(" ");
  let shortName = "";
  if (nameArr.length === 1) {
    shortName = `${nameArr[0].slice(0, 2)}`;
  } else if (nameArr.length === 2) {
    shortName = `${nameArr[0].slice(0, 1)}${nameArr[1].slice(0, 1)}`;
  } else if (nameArr.length === 3) {
    shortName = `${nameArr[0].slice(0, 1)}${nameArr[1].slice(
      0,
      1
    )}${nameArr[2].slice(0, 1)} `;
  } else if (nameArr.length === 4) {
    shortName = `${nameArr[0].slice(0, 1)}${nameArr[1].slice(
      0,
      1
    )}${nameArr[2].slice(0, 1)}${nameArr[3].slice(0, 1)}`;
  } else if (nameArr.length > 4) {
    shortName = `${nameArr[0].slice(0, 1)}${nameArr[1].slice(
      0,
      1
    )}${nameArr[2].slice(0, 1)}${nameArr[3].slice(0, 1)}${nameArr[4].slice(
      0,
      1
    )}`;
  } else {
    shortName = `${nameArr[0].slice(0, 3)}`;
  }
  return shortName;
};

export const getOnlyFirstLetterOfName = (name) => {
  return name
    .split(" ")
    .map((i) => i.charAt(0))
    .toString()
    .toUpperCase()
    .split(",");
};

export const calculatePercentage = (num1, num2) => {
  const diff = num1 - num2;
  const denom = (num1 + num2) / 2;
  const res = (diff / denom) * 100;
  return Math.round(res);
};
export const calculatePercentage2 = (num1, num2) => {
  if (!num1 || !num2) {
    return 0;
  }
  const diff = num1 - num2;
  const denom = (num1 + num2) / 2;
  const res = (diff / denom) * 100;
  return Math.round(res);
};
export const getTrimmedText = (source) => {
  if (source.length > 20) {
    return source.substring(0, 20).trimEnd() + "...";
  } else {
    return source;
  }
};
export const formatDate = (date) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Function to add "st", "nd", "rd", or "th" to the day
  function getDayWithSuffix(day) {
    if (day >= 11 && day <= 13) {
      return day + "th";
    }
    switch (day % 10) {
      case 1:
        return day + "st";
      case 2:
        return day + "nd";
      case 3:
        return day + "rd";
      default:
        return day + "th";
    }
  }

  return `${month} ${getDayWithSuffix(day)}, ${year}`;
};

export const findDecreaseIncreasePercentage = (curr, old) => {
  if (curr === 0 && old === 0) {
    let res = roundToTwo(curr);
    return numberFormatIntoShortForm(res);
  } else if (old === curr) {
    // let res = roundToTwo(curr);
    // return numberFormatIntoShortForm(res);
    return 0;
  } else if (old === 0) {
    let res = roundToTwo(curr);
    return numberFormatIntoShortForm(res);
  } else if (curr === 0) {
    let res = roundToTwo(old);
    return `-${numberFormatIntoShortForm(res)}`;
  } else {
    let res = roundToTwo(((curr - old) / old) * 100);
    return numberFormatIntoShortForm(res);
  }
};

export function roundToTwo(num) {
  if (num) {
    return +(Math.round(num + "e+2") + "e-2");
  } else {
    return "0";
  }
}

export function removeAfterDotValue(num) {
  if (num) {
    return +Math.round(num);
  } else {
    return "0";
  }
}

export function roundToThree(num) {
  return +(Math.round(num + "e+3") + "e-3");
}

export const valueInPercentage = (curr, old) => {
  if (curr == 0 && old == 0) {
    return curr;
  } else if (old == 0) {
    return curr;
  } else if (curr == 0) {
    return `-${old}`;
  } else {
    return roundToTwo(((curr - old) / old) * 100);
  }
};

export function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// getting next month, week and quarterly dates
export function getNextWeekMonQuarterlyDate(type, dateVal) {
  var today = moment();
  let returnVal = "";

  try {
    switch (true) {
      case type === "Weekly":
        let w = new Date(moment(dateVal ? dateVal : today).add(7, "days"));
        returnVal = getDatetoIsoString(w);
        break;

      case type === "Monthly":
        let m = new Date(moment(dateVal ? dateVal : today).add(1, "months"));
        returnVal = getDatetoIsoString(m);

        break;

      case type === "Quarterly":
        let q = new Date(moment(dateVal ? dateVal : today).add(3, "months"));
        returnVal = getDatetoIsoString(q);
        break;

      default:
        returnVal = getDatetoIsoString(dateVal ? dateVal : today);
        break;
    }
    return returnVal;
  } catch (e) {
    // console.log("e--->>", e);
  }
}
//Handler toasts
export const SuccessToast = (response, templateMessage) => {
  toast.success(
    response?.data?.message ? `${response?.data?.message}` : templateMessage,
    {
      position: toast.POSITION.TOP_CENTER,
    }
  );
};
export const UnSuccessfullToast = (response) => {
  toast.error(
    response?.data?.message
      ? `${response?.data?.message}`
      : "Something went wrong!",
    {
      position: toast.POSITION.TOP_CENTER,
    }
  );
};
export const ErrorToast = (error) => {
  toast.error(
    error?.response?.data?.message
      ? `${error?.response?.data?.message}`
      : "Something went wrong!",
    {
      position: toast.POSITION.TOP_CENTER,
    }
  );
};
export const ErrorToastMsg = (error) => {
  toast.error(error, {
    position: toast.POSITION.TOP_CENTER,
  });
};
//Verify User
export const verifyUser = ({
  email,
  userId,
  code,
  navigate,
  setLoadingClass,
}) => {
  try {
    users
      .verifyUser({
        email: email,
        verificationCode: userId,
        code: code,
      })
      .then(async (response) => {
        const { data: result } = response;
        // console.log("res", result);
        if (
          result.success === true &&
          result.data !== "" &&
          result.data !== null
        ) {
          await setUserInfoForAppUsageIntoReduxNew(
            response.data?.data,
            code,
            navigate
          );
          setLoadingClass("");
        } else {
          setLoadingClass("");
          UnSuccessfullToast(response);
        }
      })
      .catch((err) => {
        setLoadingClass("");
        ErrorToast(err);
      });

    return false;
  } catch (e) {
    // console.log("ERR", e);
  }
};
export const extractDomain = (email) => {
  const atIndex = email.indexOf("@"); // Find the index of '@'
  const dotIndex = email.lastIndexOf("."); // Find the index of the last '.'

  // Extract the substring between '@' and the last '.'
  const domain = email.substring(atIndex + 1, dotIndex);

  return domain;
};
// getting next month, week and quarterly dates
export function getNextDate(type, dateVal) {
  var today = moment();
  let returnVal = "";

  try {
    switch (true) {
      case type === "Weekly":
        let w = new Date(moment(dateVal ? dateVal : today).add(6, "days"));
        // returnVal = getDatetoIsoString(w);
        returnVal = moment(w).format("YYYY-MM-DD");
        break;

      case type === "Monthly":
        let m = new Date(moment(dateVal ? dateVal : today).add(1, "month"));

        let mm = new Date(moment(m).subtract(1, "days"));
        // returnVal = getDatetoIsoString(m);
        returnVal = moment(mm).format("YYYY-MM-DD");

        break;

      case type === "Quarterly":
        let q = moment(dateVal ? dateVal : today).add(3, "month");

        let qq = new Date(moment(q).subtract(1, "days"));

        returnVal = moment(qq).format("YYYY-MM-DD");
        break;

      default:
        returnVal = moment(dateVal ? dateVal : today).format("YYYY-MM-DD");
        break;
    }
    return returnVal;
  } catch (e) {
    // console.log("e--->>", e);
  }
}
export const getGroupByHeatMapDataCPT = (finalData, range) => {
  let groupByDeptObj = {};

  let newArr = [];
  let countValue = 0;
  finalData?.map((item, index) => {
    let LevelName = item?.levelName ? item?.levelName : "Other";
    if (groupByDeptObj[LevelName]) {
      let byDept = groupByDeptObj[LevelName];
      byDept.value = byDept.value + item?.denielAmount;
      byDept.children.push({
        brandName: LevelName ? LevelName : "Other",
        name: item?.code,
        actualValue: item?.denielAmount,
        value:
          item?.denielAmount < 0 ? item?.denielAmount * -1 : item?.denielAmount,

        ranges: { range: range, denialAmount: item?.denielAmount },
        chargeAmount: item?.chargeAmount,
        denial23Amount: item?.deniel23Amount,
        denial23Count: item?.deniel23Count,
        denialPercentage: item?.denialPercentage,
        denielCount: item?.denielCount,
        description: item?.description,
        totalCount: item?.totalCount,
      });
      groupByDeptObj[LevelName] = byDept;
    } else {
      let byDept = { value: 0, children: [], brand: "" };
      byDept.value = byDept.value + item?.denielAmount;
      byDept.brand = LevelName;
      byDept.children.push({
        brandName: LevelName ? LevelName : "Other",
        name: item?.code,
        actualValue: item?.denielAmount,
        value:
          item?.denielAmount < 0 ? item?.denielAmount * -1 : item?.denielAmount,

        ranges: { range: range, denialAmount: item?.denielAmount },
        chargeAmount: item?.chargeAmount,
        denial23Amount: item?.deniel23Amount,
        denial23Count: item?.deniel23Count,
        denialPercentage: item?.denialPercentage,
        denielCount: item?.denielCount,
        description: item?.description,
        totalCount: item?.totalCount,
      });
      groupByDeptObj[LevelName] = byDept;
    }
    newArr.push({
      name: item?.code,
      actualValue: item?.denielAmount,
      value:
        item?.denielAmount < 0 ? item?.denielAmount * -1 : item?.denielAmount,

      ranges: { range: range, denialAmount: item?.denielAmount },
      chargeAmount: item?.chargeAmount,
      denialPercentage: item?.denialPercentage,
      denielCount: item?.denielCount,
      description: item?.description,
      totalCount: item?.totalCount,
    });

    countValue = countValue + item?.denielAmount;
  });
  let finalArr = [];
  Object.keys(groupByDeptObj).map((item, index) => {
    let obj = groupByDeptObj[item];

    finalArr.push({
      name: obj?.brand,
      brand: obj?.brand,
      value: obj?.value,
      children: obj?.children,
    });
  });
  return {
    countValue: countValue,
    newArr: newArr,
    groupByDeptObj: groupByDeptObj,
    finalArr: finalArr,
  };
};
export const getGroupByHeatMapDataCPTDepartment = (finalData) => {
  let groupByDeptObj = {};

  let newArr = [];
  let countValue = 0;
  finalData?.map((item, index) => {
    let LevelName = item?.levelName ? item?.levelName : "Other";
    if (groupByDeptObj[LevelName]) {
      let byDept = groupByDeptObj[LevelName];
      byDept.value = byDept.value + item?.denielAmount;
      byDept.children.push({
        brandName: LevelName ? LevelName : "Other",
        // name: item?.cptCode,
        name: `${item?.cptCode} - $${numberFormatIntoShortForm(
          item?.denielAmount
        )}`,
        code: item?.cptCode,
        actualValue: item?.denielAmount,
        value:
          item?.denielAmount < 0 ? item?.denielAmount * -1 : item?.denielAmount,

        // ranges: { range: range, denialAmount: item?.denielAmount },
        chargeAmount: item?.chargeAmount,
        denial23Amount: item?.deniel23Amount,
        denial23Count: item?.deniel23Count,
        denialPercentage: item?.denialPercentage,
        denielCount: item?.denielCount,
        description: item?.description,
        totalCount: item?.totalCount,
      });
      groupByDeptObj[LevelName] = byDept;
    } else {
      let byDept = { value: 0, children: [], brand: "" };
      byDept.value = byDept.value + item?.denielAmount;
      byDept.brand = LevelName;
      byDept.children.push({
        brandName: LevelName ? LevelName : "Other",
        // name: item?.cptCode,
        name: `${item?.cptCode} - $${numberFormatIntoShortForm(
          item?.denielAmount
        )}`,
        code: item?.cptCode,
        actualValue: item?.denielAmount,
        value:
          item?.denielAmount < 0 ? item?.denielAmount * -1 : item?.denielAmount,

        // ranges: { range: range, denialAmount: item?.denielAmount },
        chargeAmount: item?.chargeAmount,
        denial23Amount: item?.deniel23Amount,
        denial23Count: item?.deniel23Count,
        denialPercentage: item?.denialPercentage,
        denielCount: item?.denielCount,
        description: item?.description,
        totalCount: item?.totalCount,
      });
      groupByDeptObj[LevelName] = byDept;
    }
  });
  let finalArr = [];
  Object.keys(groupByDeptObj).map((item, index) => {
    let obj = groupByDeptObj[item];

    finalArr.push({
      name: obj?.brand,
      brand: obj?.brand,
      value: obj?.value,
      children: obj?.children,
    });
  });
  return {
    countValue: countValue,
    newArr: newArr,
    groupByDeptObj: groupByDeptObj,
    finalArr: finalArr,
  };
};
export const getListOfMessages = (output) => {
  const formattedArray = [];

  for (let i = 0; i < output?.length; i++) {
    const current = output?.[i];
    if (current.role === "user") {
      // Find the bot's response after the user message
      const next = output[i + 1];
      if (next && next.role === "bot") {
        formattedArray.push({
          Question: current.msg,
          Answer: next.msg,
          Feedback: next?.feedbackType,
          Reason: next?.feedbackReason,
          Usage: next?.usage,
        });
      }
    }
  }
  return formattedArray;
};
export const getGroupByCodeDataReasonCodeDepartment = (finalData) => {
  let groupByDeptObj = {};

  let newArr = [];
  let countValue = 0;
  finalData?.map((item, index) => {
    let LevelName = item?.reasonCode ? item?.reasonCode : "Other";
    if (groupByDeptObj[LevelName]) {
      let byDept = groupByDeptObj[LevelName];
      byDept.value = byDept.value + item?.denielAmount;
      byDept.children.push({
        brandName: LevelName ? LevelName : "Other",
        // name: item?.groupCode,
        name: `${item?.groupCode} (${
          item?.reasonCode
        }) - $${numberFormatIntoShortForm(item?.denielAmount)}`,
        code: item?.groupCode,
        actualValue: item?.denielAmount,
        value:
          item?.denielAmount < 0 ? item?.denielAmount * -1 : item?.denielAmount,

        // ranges: { range: range, denialAmount: item?.denielAmount },
        chargeAmount: item?.chargeAmount,
        denial23Amount: item?.deniel23Amount,
        denial23Count: item?.deniel23Count,
        denialPercentage: item?.denialPercentage,
        denielCount: item?.denielCount,
        description: item?.description,
        totalCount: item?.totalCount,
      });
      groupByDeptObj[LevelName] = byDept;
    } else {
      let byDept = { value: 0, children: [], brand: "" };
      byDept.value = byDept.value + item?.denielAmount;
      byDept.brand = LevelName;
      byDept.children.push({
        brandName: LevelName ? LevelName : "Other",
        // name: item?.groupCode,
        name: `${item?.groupCode} (${
          item?.reasonCode
        }) - $${numberFormatIntoShortForm(item?.denielAmount)}`,
        code: item?.groupCode,
        actualValue: item?.denielAmount,
        value:
          item?.denielAmount < 0 ? item?.denielAmount * -1 : item?.denielAmount,

        // ranges: { range: range, denialAmount: item?.denielAmount },
        chargeAmount: item?.chargeAmount,
        denial23Amount: item?.deniel23Amount,
        denial23Count: item?.deniel23Count,
        denialPercentage: item?.denialPercentage,
        denielCount: item?.denielCount,
        description: item?.description,
        totalCount: item?.totalCount,
      });
      groupByDeptObj[LevelName] = byDept;
    }
  });
  let finalArr = [];
  Object.keys(groupByDeptObj).map((item, index) => {
    let obj = groupByDeptObj[item];

    finalArr.push({
      name: obj?.brand,
      brand: obj?.brand,
      value: obj?.value,
      children: obj?.children,
    });
  });
  return {
    finalArr: finalArr,
  };
};
export const getGroupByRevenueHeatmapDataCPTDepartment = (finalData) => {
  let groupByDeptObj = {};
  let newArr = [];
  let countValue = 0;
  finalData?.map((item, index) => {
    let LevelName = item?.levelName ? item?.levelName : "Other";
    const level1Name = item?.level1Name ? item?.level1Name : "Other";
    if (groupByDeptObj[LevelName]) {
      let byDept = groupByDeptObj[LevelName];
      byDept.value = byDept.value + item?.paymentAmount;
      byDept.children.push({
        brandName: LevelName ? LevelName : "Other",
        // name: item?.cptCode,
        name: `${level1Name} - $${numberFormatIntoShortForm(
          item?.paymentAmount
        )} (${LevelName})`,
        code: level1Name,
        actualValue: item?.paymentAmount,
        value:
          item?.paymentAmount < 0
            ? item?.paymentAmount * -1
            : item?.paymentAmount,

        // ranges: { range: range, denialAmount: item?.paymentAmount },
        chargeAmount: item?.chargeAmount,
        denial23Amount: item?.deniel23Amount,
        denial23Count: item?.deniel23Count,
        denialPercentage: item?.denialPercentage,
        denielCount: item?.paymentCount,
        description: item?.description,
        totalCount: item?.totalCount,
      });
      groupByDeptObj[LevelName] = byDept;
    } else {
      let byDept = { value: 0, children: [], brand: "" };
      byDept.value = byDept.value + item?.paymentAmount;
      byDept.brand = LevelName;

      byDept.children.push({
        brandName: LevelName ? LevelName : "Other",
        // name: item?.cptCode,
        name: `${level1Name} - $${numberFormatIntoShortForm(
          item?.paymentAmount
        )} (${LevelName})`,

        code: level1Name,
        actualValue: item?.paymentAmount,
        value:
          item?.paymentAmount < 0
            ? item?.paymentAmount * -1
            : item?.paymentAmount,

        // ranges: { range: range, denialAmount: item?.denielAmount },
        chargeAmount: item?.chargeAmount,
        denial23Amount: item?.deniel23Amount,
        denial23Count: item?.deniel23Count,
        denialPercentage: item?.denialPercentage,
        denielCount: item?.paymentCount,
        description: item?.description,
        totalCount: item?.totalCount,
      });
      groupByDeptObj[LevelName] = byDept;
    }
  });
  let finalArr = [];
  Object.keys(groupByDeptObj).map((item, index) => {
    let obj = groupByDeptObj[item];

    finalArr.push({
      name: obj?.brand,
      brand: obj?.brand,
      value: obj?.value,
      children: obj?.children,
    });
  });
  return {
    countValue: countValue,
    newArr: newArr,
    groupByDeptObj: groupByDeptObj,
    finalArr: finalArr,
  };
};
export const getGroupByHeatMapDataCARC = (finalData, range) => {
  let groupByDeptObj = {};
  let groupByCategoryObj = {};

  let newArr = [];
  let countValue = 0;
  finalData?.map((item, index) => {
    let LevelName = item?.levelName ? item?.levelName : "Other";
    let CategoryName = item?.rcCategory ? item?.rcCategory : "Other";

    //Group By Category

    if (groupByCategoryObj[CategoryName]) {
      let byCategory = groupByCategoryObj[CategoryName];
      byCategory.value = byCategory.value + item?.denielAmount;
      byCategory.children.push({
        brandName: CategoryName ? CategoryName : "Other",
        ranges: { range: range, denialAmount: item?.denielAmount },
        // name: item?.reasonCode,
        name: `${item?.reasonCode} - $${numberFormatIntoShortForm(
          item?.denielAmount
        )}`,
        code: item?.reasonCode,
        actualValue: item?.denielAmount,
        value:
          item?.denielAmount < 0 ? item?.denielAmount * -1 : item?.denielAmount,

        chargeAmount: item?.chargeAmount,
        denial23Amount: item?.deniel23Amount,
        denial23Count: item?.deniel23Count,
        denialPercentage: item?.denialPercentage,
        denielCount: item?.denielCount,
        description: item?.description,
        totalCount: item?.totalCount,
      });
      groupByCategoryObj[CategoryName] = byCategory;
    } else {
      let byCategory = { value: 0, children: [], brand: "" };
      byCategory.value = byCategory.value + item?.denielAmount;
      byCategory.brand = CategoryName;
      byCategory.children.push({
        brandName: CategoryName ? CategoryName : "Other",
        ranges: { range: range, denialAmount: item?.denielAmount },
        // name: item?.reasonCode,
        name: `${item?.reasonCode} - $${numberFormatIntoShortForm(
          item?.denielAmount
        )}`,
        code: item?.reasonCode,
        actualValue: item?.denielAmount,
        value:
          item?.denielAmount < 0 ? item?.denielAmount * -1 : item?.denielAmount,

        chargeAmount: item?.chargeAmount,
        denial23Amount: item?.deniel23Amount,
        denial23Count: item?.deniel23Count,
        denialPercentage: item?.denialPercentage,
        denielCount: item?.denielCount,
        description: item?.description,
        totalCount: item?.totalCount,
      });
      groupByCategoryObj[CategoryName] = byCategory;
    }
  });
  let finalArrByDept = [];
  let finalArrByCategory = [];

  Object.keys(groupByCategoryObj).map((item, index) => {
    let obj = groupByCategoryObj[item];
    finalArrByCategory.push({
      name: obj?.brand,
      brand: obj?.brand,
      value: obj?.value,
      children: obj?.children,
    });
  });
  return {
    countValue: countValue,
    newArr: newArr,
    groupByDeptObj: groupByDeptObj,
    finalArrByCategory: finalArrByCategory,
    finalArrByDept: finalArrByDept,
  };
};
export const callGlobalErrorLog = (error) => {
  const params = {
    UserId: getUserId() ? getUserId() : "",
    DomainId: getDomainId() ? getDomainId() : "",
    Subscriptionid: getSubscriptionId() ? getSubscriptionId() : "",
    Screen: window?.location?.href,
    APIURL: error?.config?.url,
    JsonBody: error?.config?.data,
    Response: JSON.stringify(error),
    AdditionalInfo: error?.response?.data?.title,
  };
  genericAxiosCall(serverRoutes?.LOG_ERROR, "post", params, "");
  // axios
  //   .post(serverRoutes?.LOG_ERROR, params)
  //   .then((res) => {
  //     console.log("hey khizer===", res);
  //   })
  //   .catch((err) => {
  //     console.log("hey khizer error===", err);
  //   });
};
export const getGroupByHeatMapDataCARCCategory = (finalData) => {
  let groupByDeptObj = {};
  let groupByCategoryObj = {};

  let newArr = [];
  let countValue = 0;
  finalData?.map((item, index) => {
    let LevelName = item?.levelName ? item?.levelName : "Other";
    let CategoryName = item?.rcCategory ? item?.rcCategory : "Other";
    if (groupByCategoryObj[CategoryName]) {
      let byCategory = groupByCategoryObj[CategoryName];
      byCategory.value = byCategory.value + item?.denielAmount;
      byCategory.children.push({
        brandName: CategoryName ? CategoryName : "Other",

        name: `${item?.reasonCode} - $${numberFormatIntoShortForm(
          item?.denielAmount
        )}`,
        code: item?.reasonCode,
        actualValue: item?.denielAmount,
        value:
          item?.denielAmount < 0 ? item?.denielAmount * -1 : item?.denielAmount,

        chargeAmount: item?.chargeAmount,
        denialPercentage: item?.denialPercentage,
        denial23Amount: item?.deniel23Amount,
        denial23Count: item?.deniel23Count,
        denielCount: item?.denielCount,
        description: item?.description,
        totalCount: item?.totalCount,
      });
      groupByCategoryObj[CategoryName] = byCategory;
    } else {
      let byCategory = { value: 0, children: [], brand: "" };
      byCategory.value = byCategory.value + item?.denielAmount;
      byCategory.brand = CategoryName;
      byCategory.children.push({
        brandName: CategoryName ? CategoryName : "Other",
        // ranges: { range: range, denialAmount: item?.denielAmount },
        // name: item?.reasonCode,
        name: `${item?.reasonCode} - $${numberFormatIntoShortForm(
          item?.denielAmount
        )}`,
        code: item?.reasonCode,
        actualValue: item?.denielAmount,
        value:
          item?.denielAmount < 0 ? item?.denielAmount * -1 : item?.denielAmount,

        chargeAmount: item?.chargeAmount,
        denial23Amount: item?.deniel23Amount,
        denial23Count: item?.deniel23Count,
        denialPercentage: item?.denialPercentage,
        denielCount: item?.denielCount,
        description: item?.description,
        totalCount: item?.totalCount,
      });
      groupByCategoryObj[CategoryName] = byCategory;
    }
  });
  let finalArrByDept = [];
  let finalArrByCategory = [];

  Object.keys(groupByCategoryObj).map((item, index) => {
    let obj = groupByCategoryObj[item];
    finalArrByCategory.push({
      name: obj?.brand,
      brand: obj?.brand,
      value: obj?.value,
      children: obj?.children,
    });
  });

  return {
    countValue: countValue,
    newArr: newArr,
    groupByDeptObj: groupByDeptObj,
    finalArrByCategory: finalArrByCategory,
    finalArrByDept: finalArrByDept,
  };
};
export const getGroupByHeatMapDataRemarkCodeCategory = (finalData) => {
  let groupByDeptObj = {};
  let groupByCategoryObj = {};

  let newArr = [];
  let countValue = 0;
  finalData?.map((item, index) => {
    let LevelName = item?.levelName ? item?.levelName : "Other";
    let CategoryName = item?.claimPaymentRemarkCodes
      ? item?.claimPaymentRemarkCodes
      : "Other";
    if (groupByCategoryObj[CategoryName]) {
      let byCategory = groupByCategoryObj[CategoryName];
      byCategory.value = byCategory.value + item?.denielAmount;
      byCategory.children.push({
        brandName: CategoryName ? CategoryName : "Other",

        name: `${item?.reasonCode} - $${numberFormatIntoShortForm(
          item?.denielAmount
        )}`,
        code: item?.reasonCode,
        actualValue: item?.denielAmount,
        value:
          item?.denielAmount < 0 ? item?.denielAmount * -1 : item?.denielAmount,

        chargeAmount: item?.chargeAmount,
        denialPercentage: item?.denialPercentage,
        denial23Amount: item?.deniel23Amount,
        denial23Count: item?.deniel23Count,
        denielCount: item?.denielCount,
        description: item?.description,
        totalCount: item?.totalCount,
      });
      groupByCategoryObj[CategoryName] = byCategory;
    } else {
      let byCategory = { value: 0, children: [], brand: "" };
      byCategory.value = byCategory.value + item?.denielAmount;
      byCategory.brand = CategoryName;
      byCategory.children.push({
        brandName: CategoryName ? CategoryName : "Other",
        // ranges: { range: range, denialAmount: item?.denielAmount },
        // name: item?.reasonCode,
        name: `${item?.reasonCode} - $${numberFormatIntoShortForm(
          item?.denielAmount
        )}`,
        code: item?.reasonCode,
        actualValue: item?.denielAmount,
        value:
          item?.denielAmount < 0 ? item?.denielAmount * -1 : item?.denielAmount,

        chargeAmount: item?.chargeAmount,
        denial23Amount: item?.deniel23Amount,
        denial23Count: item?.deniel23Count,
        denialPercentage: item?.denialPercentage,
        denielCount: item?.denielCount,
        description: item?.description,
        totalCount: item?.totalCount,
      });
      groupByCategoryObj[CategoryName] = byCategory;
    }
  });
  let finalArrByDept = [];
  let finalArrByCategory = [];

  Object.keys(groupByCategoryObj).map((item, index) => {
    let obj = groupByCategoryObj[item];
    finalArrByCategory.push({
      name: obj?.brand,
      brand: obj?.brand,
      value: obj?.value,
      children: obj?.children,
    });
  });

  return {
    countValue: countValue,
    newArr: newArr,
    groupByDeptObj: groupByDeptObj,
    finalArrByCategory: finalArrByCategory,
    finalArrByDept: finalArrByDept,
  };
};
export const getGroupByHeatMapDataCARCDepartment = (finalData) => {
  let groupByDeptObj = {};
  let groupByCategoryObj = {};

  let newArr = [];
  let countValue = 0;
  finalData?.map((item, index) => {
    let LevelName = item?.levelName ? item?.levelName : "Other";
    let CategoryName = item?.rcCategory ? item?.rcCategory : "Other";
    //Group By Dept
    if (groupByDeptObj[LevelName]) {
      let byDept = groupByDeptObj[LevelName];
      byDept.value = byDept.value + item?.denielAmount;
      byDept.children.push({
        brandName: LevelName ? LevelName : "Other",
        // ranges: { range: range, denialAmount: item?.denielAmount },
        // name: item?.reasonCode,
        name: `${item?.reasonCode} - $${numberFormatIntoShortForm(
          item?.denielAmount
        )}`,
        code: item?.reasonCode,
        actualValue: item?.denielAmount,
        value:
          item?.denielAmount < 0 ? item?.denielAmount * -1 : item?.denielAmount,

        chargeAmount: item?.chargeAmount,
        denial23Amount: item?.deniel23Amount,
        denial23Count: item?.deniel23Count,
        denialPercentage: item?.denialPercentage,
        denielCount: item?.denielCount,
        description: item?.description,
        totalCount: item?.totalCount,
      });
      groupByDeptObj[LevelName] = byDept;
    } else {
      let byDept = { value: 0, children: [], brand: "" };
      byDept.value = byDept.value + item?.denielAmount;
      byDept.brand = LevelName;
      byDept.children.push({
        brandName: LevelName ? LevelName : "Other",
        // ranges: { range: range, denialAmount: item?.denielAmount },
        // name: item?.reasonCode,
        name: `${item?.reasonCode} - $${numberFormatIntoShortForm(
          item?.denielAmount
        )}`,
        code: item?.reasonCode,
        actualValue: item?.denielAmount,
        value:
          item?.denielAmount < 0 ? item?.denielAmount * -1 : item?.denielAmount,

        chargeAmount: item?.chargeAmount,
        denial23Amount: item?.deniel23Amount,
        denial23Count: item?.deniel23Count,
        denialPercentage: item?.denialPercentage,
        denielCount: item?.denielCount,
        description: item?.description,
        totalCount: item?.totalCount,
      });
      groupByDeptObj[LevelName] = byDept;
    }
    //Group By Category
  });
  let finalArrByDept = [];
  let finalArrByCategory = [];
  Object.keys(groupByDeptObj).map((item, index) => {
    let obj = groupByDeptObj[item];
    finalArrByDept.push({
      name: obj?.brand,
      brand: obj?.brand,
      value: obj?.value,
      children: obj?.children,
    });
  });

  return {
    countValue: countValue,
    newArr: newArr,
    groupByDeptObj: groupByDeptObj,
    finalArrByCategory: finalArrByCategory,
    finalArrByDept: finalArrByDept,
  };
};

// pass date and it will provide you toISOString date
export function getDatetoIsoString(date) {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num) {
      return (num < 10 ? "0" : "") + num;
    };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ":" +
    pad(Math.abs(tzo) % 60)
  );
}

export const encodeData = (data) =>
  Object.keys(data)
    .map(function (key) {
      return [key, data[key]].map(encodeURIComponent).join("=");
    })
    .join("&");

export function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}
export const encodeString = (string) => {
  let encodedURI = encodeURIComponent(string);
  return btoa(encodedURI);
};
export const decodeString = (string) => {
  let decodedURI = atob(string);
  return decodeURIComponent(decodedURI);
};

const getCurrentQuarter = () => {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const quarters = ["Q1", "Q2", "Q3", "Q4"];
  const currentQuarter = quarters[Math.floor(currentMonth / 3)];
  return `${currentQuarter}-${currentYear}`;
};

export const getLastEightQuarters = () => {
  const quarters = [];
  let currentQuarter = getCurrentQuarter();

  for (let i = 0; i < 8; i++) {
    quarters.push({
      label: currentQuarter,
      value: currentQuarter,
    });

    const [quarter, year] = currentQuarter.split("-");
    const currentYear = parseInt(year);
    const currentQuarterIndex = parseInt(quarter.slice(1)) - 1;

    if (currentQuarterIndex === 0) {
      currentQuarter = `Q4-${currentYear - 1}`;
    } else {
      currentQuarter = `Q${currentQuarterIndex}-${currentYear}`;
    }
  }

  return quarters;
};

export const formatDateCustomColumn835 = (date) => {
  try {
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0"); // Days are 1-based
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  } catch (err) {
    console.log("Err--->>", err);
  }
};

export const getDefaultDateForDashboard = (option) => {
  // console.log("getDefaultDateForDashboard_val--->>", option);
  try {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const getCurrentMonthLabel = (month) => {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return monthNames[month - 1];
    };

    const getDateForMonth = (monthOffset) => {
      const date = new Date();
      date.setMonth(date.getMonth() + monthOffset);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return {
        label: getCurrentMonthLabel(month),
        value: month.toString(),
      };
    };

    const getDateForYear = () => ({
      label: currentYear.toString(),
      value: currentYear,
    });

    const getDateForQuarter = (quarterOffset) => {
      const quarters = getLastEightQuarters();
      return quarters[quarterOffset];
    };

    switch (option) {
      case "Current Month":
        return {
          dateForMonth: getDateForMonth(0),
          dateForYear: getDateForYear(),
          dateForQuarter: getDateForQuarter(0),
          dateMainState: "Months",
        };
      case "Last Month":
        return {
          dateForMonth: getDateForMonth(-1),
          dateForYear: getDateForYear(),
          dateForQuarter: getDateForQuarter(0),
          dateMainState: "Months",
        };
      case "Current Quarter":
        return {
          dateForMonth: getDateForMonth(0),
          dateForYear: getDateForYear(),
          dateForQuarter: getDateForQuarter(0),
          dateMainState: "Quarter",
        };
      case "Last Quarter":
        return {
          dateForMonth: getDateForMonth(0),
          dateForYear: getDateForYear(),
          dateForQuarter: getDateForQuarter(1),
          dateMainState: "Quarter",
        };
      default:
        return null;
    }
  } catch (err) {
    console.log("err--->>", err);
  }
};

// update all Dashboard datePickers for default date

export function setDefaultDateOnAllDashboards(dispatch, defaultDate) {
  //  add other dashboard filters as well
  const dashboardsFilterList = ["insightsSreensCommonFilter"];

  // Example usage
  const result = getDefaultDateForDashboard(defaultDate);
  // console.log("result--->>", result);

  if (result) {
    dashboardsFilterList.map((listing) =>
      dispatch(
        setListingState({
          listing,
          value: {
            dateForMonth: result?.dateForMonth,
            dateForQuarter: result?.dateForQuarter,
            dateForYear: result?.dateForYear,
            dateMainState: result?.dateMainState,
          },
        })
      )
    );
  }
}

export const timeZoneArray = [
  {
    timeZoneTitleLabel: "Pacific Time",
    timeZoneValue: "America/Los_Angeles",
  },
  {
    timeZoneTitleLabel: "Mountain Time",
    timeZoneValue: "America/Denver",
  },
  {
    timeZoneTitleLabel: "Central Time",
    timeZoneValue: "America/Chicago",
  },
  // {
  //   timeZoneTitleLabel: "Central Time (UTC -6:00 / -5:00)",
  //   timeZoneValue: "America/Chicago",
  // },
  {
    timeZoneTitleLabel: "Eastern Time",
    timeZoneValue: "America/New_York",
  },
  {
    timeZoneTitleLabel: "Alaska Time",
    timeZoneValue: "America/Anchorage",
  },
  {
    timeZoneTitleLabel: "Hawaii-Aleutian Time",
    timeZoneValue: "Pacific/Honolulu",
  },
  {
    timeZoneTitleLabel: "Atlantic Time",
    timeZoneValue: "America/Puerto_Rico",
  },
  {
    timeZoneTitleLabel: "Chamorro Time",
    timeZoneValue: "Pacific/Guam",
  },
  {
    timeZoneTitleLabel: "Samoa Time",
    timeZoneValue: "Pacific/Pago_Pago",
  },
];

export function ConvertUTCTimeToSelectedTimezone(
  utcTime,
  selectedTimeZoneTitle
) {
  // console.log(
  //   "ConvertUTCTimeToSelectedTimezone--->>",
  //   utcTime,
  //   selectedTimeZoneTitle
  // );

  try {
    // Example: selected time zone title
    // const selectedTimeZoneTitle = "Central Time"; // This could be dynamically set

    // Get the time zone object from timeZoneArray based on selected time zone title
    const selectedTimeZoneObj = timeZoneArray.find(
      (tz) => tz.timeZoneTitleLabel === selectedTimeZoneTitle
    );

    // If a matching time zone is found, get the timeZoneValue
    const selectedTimeZone = selectedTimeZoneObj
      ? selectedTimeZoneObj.timeZoneValue
      : null;

    // console.log("selectedTimeZone--->>", selectedTimeZone); // Output: "America/Chicago"

    if (!selectedTimeZone) {
      // const utcDate = new Date(utcTime); // Convert UTC time to Date object
      // return `1122- ${formatDate(utcDate)}`; // Format the UTC date

      return `${moment(utcTime, "YYYY-MM-DD HH:mm:ss").format(
        "MM/DD/YYYY hh:mm:ss a"
      )}`;

      // return `123- ${utcTime}`;
    }

    // Parse the UTC time and add "Z" to ensure it's treated as UTC
    const utcDate = new Date(utcTime + "Z");

    // Convert to the desired timezone using Intl.DateTimeFormat
    const options = {
      timeZone: selectedTimeZone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // 24-hour format
    };

    // Format the date using the selected time zone
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const parts = formatter.formatToParts(utcDate);

    // Extract the formatted parts of the date
    const dateParts = {};
    parts.forEach(({ type, value }) => {
      if (type !== "literal") {
        dateParts[type] = value;
      }
    });

    // Return the formatted date and time as a string in the desired format: YYYY-MM-DD HH:MM:SS
    return `${dateParts.month}/${dateParts.day}/${dateParts.year} ${dateParts.hour}:${dateParts.minute}:${dateParts.second} ${dateParts.dayPeriod}`;
  } catch (error) {
    console.error("Error converting time:--->>", error);
    return null;
  }
}

export function ConvertUTCTimeToSelectedTimezone123(
  utcTime,
  selectedTimeZoneTitle
) {
  try {
    console.log(
      "ConvertUTCTimeToSelectedTimezone--->>",
      utcTime,
      selectedTimeZoneTitle
    );

    // Validate if the utcTime is a valid string or Date object
    if (!utcTime || isNaN(new Date(utcTime).getTime())) {
      throw new Error("Invalid UTC time format.");
    }

    // Get the time zone object from timeZoneArray based on selected time zone title
    const selectedTimeZoneObj = timeZoneArray.find(
      (tz) => tz.timeZoneTitleLabel === selectedTimeZoneTitle
    );

    // If a matching time zone is found, get the timeZoneValue
    const selectedTimeZone = selectedTimeZoneObj
      ? selectedTimeZoneObj.timeZoneValue
      : null;

    // Function to format the date to "YYYY-MM-DD HH:MM:SS"
    const formatDate = (date, timeZone = null) => {
      const options = {
        timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true, // 12-hour format
      };

      // Format the date using Intl.DateTimeFormat
      const formatter = new Intl.DateTimeFormat("en-US", options);
      const parts = formatter.formatToParts(date);

      const dateParts = {};
      parts.forEach(({ type, value }) => {
        if (type !== "literal") {
          dateParts[type] = value;
        }
      });

      return `${dateParts.year}-${dateParts.month}-${dateParts.day} ${dateParts.hour}:${dateParts.minute}:${dateParts.second} ${dateParts.dayPeriod}`;
    };

    // If no selected time zone is found, log a warning and return the UTC time formatted
    if (!selectedTimeZone) {
      console.warn(
        `--->> Invalid time zone title: "${selectedTimeZoneTitle}". Defaulting to UTC.`
      );
      const utcDate = new Date(utcTime); // Convert UTC time to Date object
      return formatDate(utcDate); // Format the UTC date
    }

    // Convert to the desired time zone if a valid time zone was provided
    const utcDate = new Date(utcTime); // Convert UTC time to Date object
    return formatDate(utcDate, selectedTimeZone); // Format the date with the selected time zone
  } catch (error) {
    console.error(" --->> Error converting time:--->>", error.message);
    return ` --->> Error: ${error.message}`; // Ensure the function always returns a value
  }
}

// Test case
// console.log(ConvertUTCTimeToSelectedTimezone("2024-12-25T07:58:48.597", null));

export function getMonthName(monthNumber) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Check if the monthNumber is valid
  if (monthNumber >= 1 && monthNumber <= 12) {
    return months[monthNumber - 1];
  } else {
    // Handle invalid monthNumber
    return "Invalid Month";
  }
}

// get random string
export function getRandomString() {
  //Can change 7 to 2 for longer results.
  let r = (Math.random() + 1).toString(36).substring(7);
  // console.log("random", r);
  return `${r}`;
}

// sort date
export function sortByDate(arr, Key) {
  try {
    let restult = arr?.sort(function compare(a, b) {
      var dateA = new Date(a?.[`${Key}`]).getTime();
      var dateB = new Date(b?.[`${Key}`]).getTime();
      return dateA < dateB ? 1 : -1;
    });
    return restult;
  } catch (e) {
    // console.log("e--->>", e);
  }
}

// ascending order
export function ascendingArray(arr) {
  var ascending = arr.sort((a, b) => Number(a.value) - Number(b.value));
  return ascending;
}

// ascending order
export function getAscArray(arr, fieldName) {
  try {
    var ascending = arr.sort(
      (a, b) => parseFloat(a[fieldName]) - parseFloat(b[fieldName])
    );

    return ascending;
  } catch (error) {
    // console.log("err-->>", error);
  }
}

// descending order
export function getDescArray(arr, fieldName) {
  try {
    var descending = arr.sort(
      (a, b) => parseFloat(b[fieldName]) - parseFloat(a[fieldName])
    );
    return descending;
  } catch (error) {
    // console.log("getDescArray====>>", error);
  }
}

// ascending order
export function getAscArrayForNumberValus(Arr) {
  var numArray = new Float64Array(Arr);
  numArray = numArray.sort();
  return numArray;
}

// descending order
export function descendingArray(arr, fieldName) {
  var descending = arr.sort(
    (a, b) => Number(b[fieldName]) - Number(a[fieldName])
  );
  return descending;
}

// get short Names and show full name in tooltip
export function getShortNames(item, condition, limit) {
  return (
    <BootstrapTooltip sx={{ zIndex: "1610" }} title={item}>
      <span>
        {item.length > condition ? `${item.substring(0, limit)}...` : item}
      </span>
    </BootstrapTooltip>
  );
}

// get short Names and show full name in tooltip
export function getShortNameForColumn(item, condition, limit) {
  if (item?.length > condition) {
    return (
      <BootstrapTooltip
        PopperProps={{ disablePortal: true }}
        sx={{ zIndex: "1610" }}
        title={item}
      >
        <span>
          {item?.length > condition ? `${item.substring(0, limit)}...` : item}
        </span>
      </BootstrapTooltip>
    );
  } else {
    return <span>{item}</span>;
  }
}
export function getShortNameWithoutTooltip(item, condition, limit) {
  return (
    <span>
      {item?.length > condition ? `${item.substring(0, limit)}...` : item}
    </span>
  );
}
export function getShortNameForToastText(message, condition, limit) {
  // Identify the suffix (creation or update message)
  let suffix = "";
  if (message.includes("Queue created successfully.")) {
    suffix = "Queue created successfully.";
  } else if (message.includes("Queue updated successfully.")) {
    suffix = "Queue updated successfully.";
  }

  // Split the message into the main part and suffix
  const [firstPart] = message.split(suffix);

  // Shorten the first part if it exceeds the condition
  const shortenedFirstPart =
    firstPart?.length > condition
      ? `${firstPart.substring(0, limit)}...`
      : firstPart;

  // Recombine the message with the suffix
  return `${shortenedFirstPart} ${suffix}`;
}
export function getShortNameForDeleteModalText(message, condition, limit) {
  // Apply the shortening logic directly
  return message?.length > condition
    ? `${message.substring(0, limit)}...`
    : message;
}

export function getShortNameForColumn2(item, condition = 24, limit = 24) {
  if (item?.length > condition) {
    return (
      <BootstrapTooltip
        PopperProps={{ disablePortal: true }}
        sx={{ zIndex: "1610" }}
        title={item}
      >
        <span>
          {item?.length > condition ? `${item.substring(0, limit)}...` : item}
        </span>
      </BootstrapTooltip>
    );
  } else {
    return <span>{item}</span>;
  }
}

// get short Names and show full name in tooltip
export function getShortDescriptionNameForColumn(item, condition, limit) {
  // console.log("getShortDescriptionNameForColumn--->>", item, condition, limit);
  try {
    if (item?.length > condition) {
      return (
        <BootstrapTooltip title={item}>
          <span>
            {item?.length > condition ? `${item.substring(0, limit)}...` : item}
          </span>
        </BootstrapTooltip>
      );
    } else {
      return <span>{item}</span>;
    }
  } catch (err) {
    // console.log("err--->>", err);
  }
}

// get multiSelectFilter comma seperated value for sending api
export const exceptionArray = (arr, mainArr) => {
  let temp = arr.map((item) => item.value);
  let newArr = [];
  mainArr.map((item, index) => {
    if (!temp.includes(item?.value)) {
      newArr.push(item);
    }
  });
  return getMultiSelectFilterValueForApi(newArr);
};
export const checkAndConsider23 = (arr) => {
  const exceptionListArr = ["23"];
  if (arr?.length > 0) {
    let result2 = arr.filter((o1) =>
      exceptionListArr.some((o2) => o1.value.toString() === o2.toString())
    );
    if (result2?.length > 0) {
      return true;
    } else {
      return false;
    }
  }
};
export const trimReasonCodes = (arr) => {
  const { smartFilterArr, smartFilter } =
    store.getState().npReducers.filters.reasonCodeDashboardFilter;

  return arr;
  // }
};

export const getterOrganizationName = () => {
  const { OrganizationName } = store.getState().pReducers.user.loginUserData;
  return OrganizationName;
};
export const getterAllSubscriptionModules = () => {
  const { allSubscriptionModules } = store.getState().pReducers.filters.user;
  return allSubscriptionModules;
};
export const getterAllBoughtSubscriptionModules = () => {
  const { modules } = store.getState().pReducers.user.loginUserData;

  return modules;
};
export const getterRoleBasedModules = () => {
  const { selectedModules } = store.getState().pReducers.user.loginUserData;
  return selectedModules;
};
export const setCurrentModule = async () => {
  const userRoles = getterRoleBasedModules();
  const boughtRole = getterAllBoughtSubscriptionModules();
  const { currentModule } = store.getState().pReducers.user;
  if (
    !userRoles?.find(
      (item) => item?.[subscriptionPlanNameKey] === currentModule?.value
    ) ||
    !boughtRole?.find(
      (item) => item?.[subscriptionPlanNameKey] === currentModule?.value
    )
  ) {
    if (
      userRoles?.find(
        (item) => item?.[subscriptionPlanNameKey] === moduleRolesEnum?.Denials
      ) &&
      boughtRole?.find(
        (item) => item?.[subscriptionPlanNameKey] === moduleRolesEnum?.Denials
      )
    ) {
      // store.dispatch(
      //   setListingState({
      //     listing: "userPreferencesFilter",
      //     value: {
      //       currentModule: {
      //         label: "Denials 360",
      //         value: "Denials",
      //       },
      //     },
      //   })
      // );
      await store.dispatch(
        setCurrentModuleRedux({
          label: "Denials 360",
          value: "Denials",
        })
      );
    } else if (
      userRoles?.find(
        (item) => item?.[subscriptionPlanNameKey] === moduleRolesEnum?.Appeals
      ) &&
      boughtRole?.find(
        (item) => item?.[subscriptionPlanNameKey] === moduleRolesEnum?.Appeals
      )
    ) {
      await store.dispatch(
        setCurrentModuleRedux({
          label: "Smart Appeals",
          value: "Appeals",
        })
      );
    } else if (
      userRoles?.find(
        (item) => item?.[subscriptionPlanNameKey] === moduleRolesEnum?.Policy
      ) &&
      boughtRole?.find(
        (item) => item?.[subscriptionPlanNameKey] === moduleRolesEnum?.Policy
      )
    ) {
      await store.dispatch(
        setCurrentModuleRedux({
          label: "Copilot",
          value: "Policy",
        })
      );
    }
  }
};
export const toggleOption = (mainArr, option, selectBy) => {
  // if it's in, remove

  const newArray = mainArr?.length ? [...mainArr] : [];
  let finalOutput;
  let toCheck = newArray?.map((item, index) => {
    return item?.[selectBy];
  });
  if (toCheck.includes(option?.[selectBy])) {
    finalOutput = newArray.filter(
      (item) => item?.[selectBy] !== option?.[selectBy]
    );
  } else {
    newArray.push(option);
    finalOutput = newArray;
  }
  return finalOutput;
};
export const toggleLoading = (mainArr, option) => {
  if (mainArr?.includes(option)) {
    return mainArr?.filter((item) => item !== option);
  } else {
    const newArr = [...mainArr];
    newArr.push(option);
    return newArr;
  }
};
export const toggleLoadingAdd = (mainArr, option) => {
  const newArr = [...mainArr];
  newArr.push(option);
  return newArr;
};
export const toggleLoadingRemove = (mainArr, option) => {
  if (mainArr?.includes(option)) {
    return mainArr?.filter((item) => item !== option);
  }
};
const returnFileName = (fileName, mimeType) => {
  switch (mimeType?.toLocaleLowerCase()) {
    case "application/pdf":
      return fileName;

    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return `${fileName?.split(".")?.[0]}.xlsx`;
    default:
      return fileName;
  }
};
const convertFileComma = (data) => {
  let newArr = [];
  let concat = "";
  for (let i = 0; i < data?.length; i++) {
    if (!data[i]?.includes(`"`) && !concat) {
      newArr.push(data[i]);
    } else {
      if (data[i]?.includes(`"`) && concat) {
        concat = concat + data[i];
        newArr.push(concat);
        concat = "";
      } else {
        concat = concat + " " + data[i];
      }
    }
  }
  return newArr;
};

export const readCsvFile = (file) => {
  try {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const csvData = reader.result;

        // Split CSV data into rows
        const rows = csvData.split("\n");

        // Split each row into columns
        let data = [];
        rows.map((row, index) => {
          let split = row?.split(",");

          let newArr = convertFileComma(split);

          data.push(newArr);
        });
        // Update state with CSV data
        resolve(data);
      };

      reader.onerror = () => {
        reject(reader.error);
      };

      reader.readAsText(file);
    });
  } catch (err) {
    console.log("Err--->>", err);
  }
};

export const downloadFileFromApiResponse = (apiResponse) => {
  try {
    const { content, contentType, fileName } = apiResponse;

    // Decode Base64 content
    const byteCharacters = atob(content);
    const byteNumbers = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const blob = new Blob([byteNumbers], { type: contentType });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); // Use the provided file name
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url); // Clean up
  } catch (e) {
    console.error("Download error:--->>", e);
  }
};
export const convertImageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject("No file provided");
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64String = reader.result.split(",")[1]; // Remove the MIME type prefix
      resolve(base64String);
    };

    reader.onerror = (error) => reject(error);
  });
};

export const convertBase64ToImageFile = (
  base64String,
  fileName = "image.png"
) => {
  let base64 = `data:image/png;base64,${base64String}`;

  const arr = base64.split(",");
  const mime = arr[0].match(/:(.*?);/)[1]; // Extract MIME type
  const byteString = atob(arr[1]); // Decode Base64
  const uint8Array = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return new File([uint8Array], fileName, { type: mime });
};

export const base64ToPdfAndRedirect = (base64String, filename) => {
  // Decode the Base64 string to binary data
  const binaryData = atob(base64String);

  // Create a Blob object from the binary data
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([uint8Array], { type: "application/pdf" });

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  window.open(url);
};
export const base64ToPdfAndDownload = (base64String, mimeType, filename) => {
  // Decode the Base64 string to binary data
  const binaryData = atob(base64String);

  // Create a Blob object from the binary data
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], { type: mimeType });

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);
  // Create a link element with download attribute and trigger download
  const link = document.createElement("a");
  link.href = url;
  link.download = returnFileName(filename, mimeType);

  // Append the link to the document and trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
const downloadAndCloseFile = (url, filename, mimeType) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = returnFileName(filename, mimeType);

  // Append the link to the document and trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
  window.close();
};
const documentFormats = {
  pdf: "application/pdf",
  excel: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};
export const base64ToPdfAndReturn = (fileName, base64String, mimeType) => {
  // Decode the Base64 string to binary data
  const binaryData = atob(base64String);

  // Create a Blob object from the binary data
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], { type: mimeType });

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);
  if (mimeType !== "application/pdf") {
    downloadAndCloseFile(url, fileName, mimeType);
  } else {
    return url;
  }
};
export const parsePatientName = (patientName) => {
  if (!patientName) return { firstName: "", middleName: "", lastName: "" };

  const parts = patientName.trim().split(/\s+/); // Split by whitespace
  const firstName = parts[0] || "";
  const lastName = parts.length > 1 ? parts[parts.length - 1] : "";

  // Middle name logic: Check if the middle part is an initial (single letter with a dot)
  const middleName =
    parts.length > 2
      ? parts[1].length === 2 && parts[1].endsWith(".")
        ? ""
        : parts.slice(1, -1).join(" ")
      : "";

  return { firstName, middleName, lastName };
};
export const removeOption = (mainArr, option, selectBy) => {
  // if it's in, remove

  const newArray = mainArr?.length ? [...mainArr] : [];

  const finalOutput = newArray?.filter(
    (item) => item?.[selectBy] !== option?.[selectBy]
  );
  return finalOutput;
};
export const getSubscriptionId = () => {
  const { subscriptionID } = store.getState().pReducers.user;
  return subscriptionID;
};

export const headerVersion = () => {
  const { applicationToken, subscriptionID, domainID } =
    store.getState().pReducers.user;
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${applicationToken}`,
    ["X-Version"]: "1.0",
    [!domainID ? "verification-api" : "X-Domain"]: domainID,
    ["X-Subscription"]: subscriptionID,
    ["TimeZoneHeader"]: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};

export const headerVersion2 = () => {
  const { applicationToken, subscriptionID, domainID } =
    store.getState().pReducers.user;
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${applicationToken}`,
    ["X-Version"]: "2.0",
    [!domainID ? "verification-api" : "X-Domain"]: domainID,
    ["X-Subscription"]: subscriptionID,
    ["TimeZoneHeader"]: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};
export const headerVersion3 = () => {
  const { subscriptionID } = store.getState().pReducers.user;
  return {
    "Content-Type": "application/json",
    ["X-Version"]: "1.0",
    ["X-Subscription"]: subscriptionID,
    ["TimeZoneHeader"]: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};
export const handle_Bot_Version_Response = (response) => {
  if (
    response?.data?.success &&
    response?.data?.data?.choices[0]?.message?.content
  ) {
    return {
      msg: response?.data?.data?.choices[0]?.message?.content,
      citation: JSON.stringify(
        response?.data?.data?.choices[0]?.message?.context
      ),
      usage: response?.data?.data?.usage,
    };
  } else {
    return null;
  }
};
export const isToggleSelected = (mainArr, option, selectBy) => {
  const find = mainArr?.find((item) => item?.[selectBy] === option?.[selectBy]);
  return find ? true : false;
};
export function getMultiSelectFilterValueForApi(arr) {
  try {
    let newArr = [];
    if (arr?.length) {
      newArr = arr?.map((elem) => elem?.value);
    }
    return newArr;
  } catch (err) {
    console.log("err--->>", err);
  }
}

export function getFilterValuesByKey(arr, key) {
  let newArr = [];
  if (arr?.length) {
    newArr = arr?.map((elem) => elem[key]);
  }
  return newArr;
}

export function getMultiSelectFilterValueIntoString(arr, requiredId) {
  let str = "";
  if (arr?.length) {
    str = arr.map((elem) => elem[requiredId]).join(",");
  }
  return str;
}

export function getMultiSelectFilterLabelSpitOnlyNames(arr) {
  // console.log("getMultiSelectFilterLabelSpitOnlyNames--->>", arr);
  try {
    let result = [];
    if (arr?.length) {
      // result = arr?.map((elem) => elem.label.split("-")[0]);
      result = arr?.map((elem) =>
        elem?.label ? elem.label.split("-")[0] : elem.value
      );
    }
    return result.join(", ");
  } catch (err) {
    // console.log("err--->>", err);
  }
}

export function getExludedReasonCode(
  isSmartFilter,
  smartFilterArray,
  reasonCodeFilterArray,
  dispatch,
  fieldName,
  targetFilter
) {
  try {
    if (!isSmartFilter) {
      // If isSmartFilter is false, return an empty array
      return { reasonCodeFinalArr: [] };
    }

    // Create a Set of unique values from smartFilterArray
    const smartFilterValues = new Set(
      smartFilterArray.map((filter) => filter.value)
    );

    if (reasonCodeFilterArray?.length > 0) {
      // Remove objects from ReasonCode that also exist in smartFilterArray
      const filteredReasonCodeArray = reasonCodeFilterArray.filter(
        (reasonCode) => !smartFilterValues.has(reasonCode.value)
      );

      // Dispatch the updated reasonCodeFilterArray to the store
      dispatch(
        setListingState({
          listing: [targetFilter],
          value: {
            [fieldName]: filteredReasonCodeArray,
          },
        })
      );

      return { reasonCodeFinalArr: filteredReasonCodeArray };
    } else {
      return { reasonCodeFinalArr: [] };
    }
  } catch (e) {
    // console.log("getExcludedReasonCode_e--->>", e);
  }
}

export function getMultiSelectFilterValue(arr) {
  let newArr = [];
  if (arr?.length) {
    newArr = arr?.map((elem) => elem.label);
  }
  return newArr;
}

export function getMultiSelectFilterValueFor837Payers(arr) {
  let newArr = [];
  if (arr?.length) {
    newArr = arr?.map((elem) => {
      // Extract the string without the "(23)" values
      const newArr = elem.label.replace(/\(\d+\)/, "").trim();
      return newArr;
    });
  }
  return newArr;
}

const handleOncologyAll = (filterArr, query) => {
  let oncology;

  oncology = filterArr?.find((item) => item?.Name?.trim() === "Oncology");

  if (oncology) {
    let tempQuery = `&treeNodeServiceLine=!${oncology?.Name}@${
      oncology?.Id
    }@${getLevel(oncology?.Level)}`;

    query = query + tempQuery;
    return query;
  }
};
export const convertDateToFormat = (date) => {
  return moment(`${date}`, "MM/DD/YYYY").format("MM/DD/YYYY");
};
const handleOncologyAncillary = (filterArr, query) => {
  let oncology;
  oncology = filterArr?.find((item) => item?.Name?.trim() === "Oncology");
  if (oncology) {
    let child;
    child = oncology?.Child?.find(
      (item) =>
        item?.Name?.trim() === "Drugs Administered other than oral method"
    );

    if (child) {
      let tempQuery = `&treeNodeServiceLine=!${child?.Name}@${
        child?.Id
      }@${getLevel(child?.Level)}`;

      query = query + tempQuery;
    }

    return query;
  }
};
const handleOncologyJcode = (filterArr, query) => {
  let oncology;
  const list = [
    "Chemotherapy Medications",
    "Cancer Treatment Drugs",
    "Chemotherapy Drugs",
  ];
  let tempQuery = `&treeNodeServiceLine=`;
  oncology = filterArr?.find((item) => item?.Name?.trim() === "Oncology");

  if (oncology) {
    oncology?.Child?.map((item) => {
      if (list?.includes(item?.Name?.trim())) {
        tempQuery =
          tempQuery + `!${item?.Name}@${item?.Id}@${getLevel(item?.Level)}`;
      }
    });

    query = query + tempQuery;
    return query;
  }
};
const handleOncologyPreMed = (filterArr, query) => {
  let oncology;
  oncology = filterArr?.find((item) => item?.Name?.trim() === "Oncology");
  if (oncology) {
    let child;
    child = oncology?.Child?.find(
      (item) => item?.Name?.trim() === "Chemotherapy Anti-emetic medications"
    );

    if (child) {
      let tempQuery = `&treeNodeServiceLine=!${child?.Name}@${
        child?.Id
      }@${getLevel(child?.Level)}`;

      query = query + tempQuery;
    }

    return query;
  }
};
const handleOncologyAdminCode = (filterArr, query) => {
  let oncology;

  oncology = filterArr?.find((item) => item?.Name?.trim() === "Medicine");

  if (oncology) {
    let child;
    child = oncology?.Child?.find(
      // (item) => item?.Name?.trim() === "Administration Codes"
      (item) =>
        item?.Name?.trim() ===
        "Hydration, Therapeutic, Prophylactic, Diagnostic Injections and Infusions, and Chemotherapy and Other Highly Complex Drug or Highly Complex Biologic Agent Administration"
    );

    if (child) {
      let tempQuery = `&treeNodeServiceLine=!${child?.Name}@${
        child?.Id
      }@${getLevel(child?.Level)}`;

      query = query + tempQuery;
    }

    return query;
  }
};

// getting bucket and queue names from string
export function gettingBucketAndQueueNames(ab, returnType) {
  try {
    let parts = ab.split(">");
    if (parts.length === 2) {
      let firstPart = parts[0].trim();
      let secondPart = parts[1].trim();

      return returnType == "bucket"
        ? firstPart
        : returnType == "queue"
        ? secondPart
        : "";
    } else {
      // console.log(
      //   "String does not contain > separator or contains more than one. --->>"
      // );
      return "";
    }
  } catch (err) {
    // console.log("gettingBucketAndQueueNames_err--->>", err);
  }
}

export const extractServiceLineByName = (serviceLineFilterArr, query, type) => {
  try {
    // console.log(
    //   "extractServiceLineByName--->>",
    //   "serviceLineFilterArr->",
    //   serviceLineFilterArr,
    //   "query->",
    //   query,
    //   "type->",
    //   type,
    //   "condition->",
    //   serviceLineFilterArr?.length > 0 && query && type
    // );
    if (serviceLineFilterArr?.length > 0 && query && type) {
      let queryTemp;
      type = type?.trim();
      switch (type?.toLocaleLowerCase()) {
        case "all":
          queryTemp = handleOncologyAll(serviceLineFilterArr, query);
          break;
        case "acillary code":
          queryTemp = handleOncologyAncillary(serviceLineFilterArr, query);
          break;
        case "ancillary":
          queryTemp = handleOncologyAncillary(serviceLineFilterArr, query);
          break;
        case "jcode":
          queryTemp = handleOncologyJcode(serviceLineFilterArr, query);
          break;
        case "pre-med":
          queryTemp = handleOncologyPreMed(serviceLineFilterArr, query);
          break;
        case "admin code":
          queryTemp = handleOncologyAdminCode(serviceLineFilterArr, query);

          break;
        case "admin":
          queryTemp = handleOncologyAdminCode(serviceLineFilterArr, query);

          break;

        default:
          break;
      }
      return queryTemp;
    }
  } catch (err) {
    // console.log("extractServiceLineByName_err--->>", err);
  }
};
export const getLevel = (level) => {
  return Number(level.split("Level ")[1]);
};

export const removeDuplicatesByProperty = (array, property) => {
  const hasProperty = array.some((obj) => obj.hasOwnProperty(property));

  if (!hasProperty) {
    return [];
  }
  const uniqueArray = array.filter(
    (object, index, self) =>
      index === self.findIndex((obj) => obj[property] === object[property])
  );

  return uniqueArray;
};
export const checkIsSelected = (arrMain, obj, key) => {
  return arrMain?.map((item) => item[key])?.includes(obj[key]);
};
export const removeDuplicatesFromArray = (array) => {
  const uniqueArray = [...new Set(array)];

  return uniqueArray;
};
export const returnRawMsg = (msg) => {
  const garbageValues = ["**", "###"];
  garbageValues?.map((item) => {
    msg = msg.replaceAll(item, "");
  });
  return msg;
};
export const getTreeNodeObj = (data) => {
  try {
    let obj = {};
    if (data?.length > 0) {
      data?.map((item, index) => {
        if (obj[`Level${getLevel(item?.Level)}Ids`]) {
          let val = obj[`Level${getLevel(item?.Level)}Ids`];
          obj[
            `Level${getLevel(item?.Level)}Ids`
          ] = `${val},${item?.Id.toString()}`;
        } else {
          obj[`Level${getLevel(item?.Level)}Ids`] = item?.Id.toString();
        }
      });
      return obj;
    }
  } catch (error) {}
};
export const getTreeNodeObjInsights = (data) => {
  try {
    let obj = {};
    if (data?.length > 0) {
      data?.map((item, index) => {
        if (obj[`level${getLevel(item?.Level)}`]) {
          let val = obj[`level${getLevel(item?.Level)}`];
          obj[
            `level${getLevel(item?.Level)}`
          ] = `${val},${item?.Id.toString()}`;
        } else {
          obj[`level${getLevel(item?.Level)}`] = item?.Id.toString();
        }
      });
      return obj;
    }
  } catch (error) {}
};

export const getTreeNodeObjFullPath = (data) => {
  // console.log("getTreeNodeObjFullPath--->>", data);
  try {
    let obj = {};
    if (data?.length > 0) {
      data?.map((item, index) => {
        if (!item?.fullPath) {
          // console.log("getTreeNodeObjFullPath_if--->>", data);
        } else {
          item?.fullPath?.map((item2, index2) => {
            if (obj[`level${getLevel(item2?.Level)}`]) {
              let val = obj[`level${getLevel(item2?.Level)}`];
              obj[
                `level${getLevel(item2?.Level)}`
              ] = `${val},${item2?.Id.toString()}`;
            } else {
              obj[`level${getLevel(item2?.Level)}`] = item2?.Id.toString();
            }
          });
        }
      });
      // console.log("obj--->>", obj);

      return obj;
    }
  } catch (error) {
    // console.log("e_--->", error);
  }
};

// get multiSelectFilter comma seperated id for sending api
export function getMultiSelectFilterIdForApi(arr) {
  let newArr = [];
  if (arr?.length) {
    newArr = arr?.map((elem) => elem.id);
  }
  return newArr;
}

// get multiSelectFilter comma seperated id for sending api
export function getMultiSelectFilterIdForApi_ForId(arr) {
  let newArr = [];
  if (arr?.length) {
    newArr = arr?.map((elem) => elem.value);
  }
  return newArr;
}

// get Claim Type for Manager Screen (837)
export function getClaimTypeForManager(type) {
  if (type) {
    if (type === "005010X222A2" || type === "005010X222A1") {
      // return "Professional";
      return true;
    }
    if (type === "005010X223A2" || type === "005010X223A3") {
      // return "Institutional";
      return false;
    } else {
      return "Something Went Wrong";
    }
  } else {
    return null;
  }
}

// get Claim Status short form
export function getClaimStatusShortForm(value) {
  let shortForm = "";
  if (value) {
    switch (value) {
      case "Processed as Primary":
        shortForm = "PRIMARY";
        break;
      case "Processed as Secondary":
        shortForm = "SECONDARY";
        break;
      case "Processed as Tertiary":
        shortForm = "TERTIARY";
        break;
      case "Denied":
        shortForm = "DENIED";
        break;
      case "Processed as Primary, Forwarded to Additional Payer(s)":
        shortForm = "PRIM-FWD";
        break;
      case "Processed as Secondary, Forwarded to Additional Payer(s)":
        shortForm = "SEC-FWD ";
        break;
      case "Processed as Tertiary, Forwarded to Additional Payer(s)":
        shortForm = "TER-FWD";
        break;
      case "Reversal of Previous Payment":
        shortForm = "REVERSAL";
        break;
      case "Not Our Claim, Forwarded to Additional Payer(s)":
        shortForm = "NOT OUR - FWD";
        break;
      case "Predetermination Pricing Only - No Payment":
        shortForm = "PRICING ONLY";
        break;
      case "Partial":
        shortForm = "Partial";
        break;
      case "Paid":
        shortForm = "Paid";
        break;
      case "Admit thru discharge claim":
        shortForm = "ADMIT-DIS";
        break;
      case "Replacement of prior claim":
        shortForm = "RE-PC";
        break;
      case "Non-payment/zero claims":
        shortForm = "NP-C";
        break;
      case "Interim - first claim":
        shortForm = "IF-C";
        break;
      case "Interim - continuing claim":
        shortForm = "IC-C";
        break;
      case "Interim - last claim":
        shortForm = "IL-C";
        break;
      case "Late charge(s) only claim":
        shortForm = "LC-OC";
        break;
      case "Void/cancel prior claim":
        shortForm = "VC-=PC";
        break;
      case "Final claim (for HH PPS = process as a debit/credit to RAP claim)":
        shortForm = "FCF-RAP-C";
        break;
      case "Common Working File (NCH) generated adjustment claim":
        shortForm = "CWF-GAC";
        break;
      case "CMS generated adjustment claim":
        shortForm = "CMS-GAC";
        break;
      case "Misc adjustment claim (e.g., initiated by intermediary or QIO)":
        shortForm = "MAC-II/QIO";
        break;
      case "Other adjustment request":
        shortForm = "OAR";
        break;
      case "Medicare secondary payer (MSP) adjustment":
        shortForm = "MSPA";
        break;
      case "Adjustment required by QIO":
        shortForm = "AR-QIO";
        break;
      default:
        shortForm = value;
        break;
    }
    return shortForm;
  } else {
    return "";
  }
}

// get numbers with comma and uptoOne decimal numbers for
export function getUpToOneDecimal(val) {
  var number = val;

  var rounded = Math.round(number * 10) / 10;
  return rounded;
}

// get numbers with comma and uptoTwo decimal numbers for
export function numberWithCommasWithUpToTwoDecimalNew(val) {
  if (val === 0) return "0"; // No decimal points for 0

  // Check if the value has a decimal part
  const hasDecimal = val % 1 !== 0;

  // Format accordingly
  const formattedValue = hasDecimal
    ? parseFloat(val).toFixed(2) // Show 2 decimal points if there's a decimal part
    : val; // Keep as is if no decimal part

  // Add commas
  return formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function formatValue(val) {
  if (!isFinite(val)) return 0; // Handle non-numeric or invalid values
  return val % 1 === 0 ? val : parseFloat(val.toFixed());
}

export function numberWithCommasWithUpToTwoDecimal(val) {
  let a = intToDecimalUptoTwoPoint(val, 2);
  return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function numberWithCommasWithUpToThreeDecimal(val) {
  let a = intToDecimalUpToThreePoint(val, 3);
  return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function intToDecimalUpToThreePoint(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals).toFixed(
    decimals
  );
}

export function intToDecimalUptoTwoPoint(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals).toFixed(
    decimals
  );
}

export const reformatDate = (dateString) => {
  // Check for the MM-DD-YYYY format
  const match = dateString.match(/^(\d{2})-(\d{2})-(\d{4})$/);
  if (match) {
    return moment(dateString, "MM-DD-YYYY").format("MM/DD/YYYY");
  }

  // Check for the format with time component and strip the time
  const dateTimeMatch = dateString.match(
    /^\d{1,2}\/\d{1,2}\/\d{4} \d{2}:\d{2}:\d{2} (AM|PM)$/
  );
  if (dateTimeMatch) {
    return moment(dateString, "M/D/YYYY h:mm:ss A").format("MM/DD/YYYY");
  }

  // Check for the YYYY-MM-DD format
  const isoDateMatch = dateString.match(/^\d{4}-\d{2}-\d{2}$/);
  if (isoDateMatch) {
    return moment(dateString, "YYYY-MM-DD").format("MM/DD/YYYY");
  }

  // Check for the YYYY-MM-DD HH:mm:ss format
  const isoDateTimeMatch = dateString.match(
    /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/
  );
  if (isoDateTimeMatch) {
    return moment(dateString, "YYYY-MM-DD HH:mm:ss").format("MM/DD/YYYY");
  }

  // Return the original string if no format matches
  return dateString;
};

// get numbers with comma
// export function numberWithCommas(x) {
//   try {
//     if (typeof x != "undefined") {
//       return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     } else {
//       return 0;
//     }
//   } catch (e) {
//     // console.log("numberWithCommas_e--->>", e);
//   }
// }
// get numbers with comma without decimal points

export function numberWithCommas(x) {
  try {
    if (typeof x !== "undefined") {
      // Convert the number to an integer to remove decimal points
      const integerPart = Math.trunc(x);
      return integerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "0";
    }
  } catch (e) {
    // console.log("numberWithCommas_e--->>", e);
    return "0";
  }
}
export const returnCategoriesArray = (array, key) => {
  const categorizedData = array.reduce((result, obj) => {
    const category = obj[key];

    if (!result.some((item) => item.id === category)) {
      result.push({ id: category, details: [] });
    }

    const categoryObj = result.find((item) => item.id === category);
    categoryObj.details.push(obj);

    return result;
  }, []);

  return categorizedData;
};
// get comma seperted integer ids for ant design multi select dropdown
export function getCommaSeperated_IntIds_ForMultiselect_Dropdown(data) {
  let commaSeperatedArr = data.split(",");
  let commaSepterated_IntArr = commaSeperatedArr.map(function (x) {
    return parseInt(x, 10);
  });
  return commaSepterated_IntArr;
}

// convert string to camelCase
export function getCamelizeString(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}
// random rgb color generator
export function getRandom_rgba() {
  var o = Math.round,
    r = Math.random,
    s = 255;
  return (
    "rgba(" +
    o(r() * s) +
    "," +
    o(r() * s) +
    "," +
    o(r() * s) +
    "," +
    r().toFixed(1) +
    ")"
  );
}

// get total denial amt for onCology TreeChart
export function getTotalDenialAmt(arr) {
  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    sum = sum + arr[i]?.denailAmount;
  }
  return `$${numberFormatIntoShortForm(roundToTwo(sum))}`;
}

export function getDataForLineChart(arr, monthKey) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let denailAmount = [];
  try {
    arr.forEach((item, index) => {
      denailAmount.push({
        // x: item?.monthName,
        x: item?.[monthKey],
        y: item?.denialAmount,
        denialCount: item?.denialCount,
        billedAmount: item?.totalAmount,
        description: item?.description,
        year: item?.year,
      });
    });
  } catch (e) {
    // console.log("getDataForLineChart_e--->>", e);

    return false;
  }

  return denailAmount;
}
export const macAddressOptions = [
  {
    label: "Palmetto GBA",
    value: "Palmetto GBA",
    mainValue: "Palmetto GBA",
  },
  {
    label: "Novitas Solutions",
    value: "Novitas Solutions",
    mainValue: "Novitas Solutions",
  },
  {
    label: "NGS",
    value: "NGS",
    mainValue: "NGS",
  },
  {
    label: "CGS Administrators",
    value: "CGS Administrators",
    mainValue: "CGS Administrators",
  },
  {
    label: "Noridian",
    value: "Noridian",
    mainValue: "Noridian",
  },
  {
    label: "First Coast Service Options, Inc.",
    value: "First Coast Service Options, Inc.",
    mainValue: "First Coast Service Options, Inc.",
  },
  {
    label: "WPS Insurance Corporation",
    value: "WPS Insurance Corporation",
    mainValue: "WPS Insurance Corporation",
  },
];
export const stateOptions = [
  {
    label: "Oregon",
    value: "Oregon",
  },
  // {
  //   label: "Oregon (Web)",
  //   value: "Oregon (Web)",
  // },
  {
    label: "South Carolina",
    value: "South Carolina",
  },
  {
    label: "Tennessee",
    value: "Tennessee",
  },
  // {
  //   label: "Tennessee (Web)",
  //   value: "Tennessee (Web)",
  // },
  {
    label: "Texas",
    value: "Texas",
  },
  // {
  //   label: "Texas (Web)",
  //   value: "Texas (Web)",
  // },
];

//Function calling

const setUserInfoForAppUsageIntoRedux = async (
  data,
  accessToken,
  expiry,
  dispatch
) => {
  // console.log("setUserInfoForAppUsageIntoRedux_1_data--->>", data);

  let userRoles = data?.roles.map((item, index) => {
    return item?.roleId;
  });
  let subscription = "";
  if (data?.subscriptions != null) {
    subscription = data?.subscriptions[0]?.subscriptionId;
  }

  let preferences = {
    NoOfRecordInTable: data?.NoOfRecordInTable ? data?.NoOfRecordInTable : 10,
    dashboardTimePeriod: data?.dashboardTimePeriod,
    Theme: data?.Theme,
    Navigation: data?.Navigation,
    DateRange: data?.DateRange,
    PinFilter: data?.PinFilter,
    CoordinatorPreference: data?.CoordinatorPreference
      ? data?.CoordinatorPreference?.toString()
      : null,
    MacAddressPreference: data?.MacAddressPreference
      ? data?.MacAddressPreference
      : macAddressOptions?.[0]?.value,
  };

  localStorage.setItem("NoOfRecordInTable", preferences.NoOfRecordInTable);
  localStorage.setItem("defaultClient", data?.defaultClient);

  dispatch(setInitialPageSize(preferences.NoOfRecordInTable));
  // setDefaultDateOnAllDashboards(dispatch, data?.dashboardTimePeriod);

  if (data?.AdditionalDetail) {
    const json = data?.AdditionalDetail;
    const obj = JSON.parse(json);
    // console.log("obj--->>", obj);

    getDefaultInitialDate4App(dispatch, obj);
  }

  const time = moment().format("HH:mm:ss");
  let user = {
    userName: data?.firstName + " " + data?.lastName,
    subscriptionID: subscription,
    domainID: data?.domainId,
    loginUserData: {
      ...data,
      isOnlyPolicyCopilotSelected:
        data?.selectedModules?.some((item) =>
          [
            "Policy",
            "Policy API",
            "Eligibility Copilot",
            "Policy Copilot",
            "Custom Copilot",
          ].includes(item.moduleName)
        ) &&
        !data?.selectedModules?.some((item) =>
          ["Appeals", "Denials"].includes(item.moduleName)
        ),
    },
    userToken: data?.userId,
    applicationToken: accessToken,
    applicationTokenExpiry: expiry,
    userRoles: userRoles,
    permission: userRoles,
    organizationName: data?.OrganizationName,
    domainRealName: data?.DomainName,
    domainName: data?.LocalDomain,

    code: "",
    loginType: "Azure AD",
    loginTypeForProfile: "Azure AD",
    userTimeZone: data?.timeZone,
    SubsCustomColumn: data?.EnableCustomColumn,
    AdditionalDetail: data?.AdditionalDetail,
  };

  try {
    if (data?.AdditionalDetail) {
      const json = data?.AdditionalDetail;
      const obj = JSON.parse(json);
      // console.log("obj--->>", obj);

      getDefaultInitialDate4App(dispatch, obj);
    }

    // console.log("user__1--->>", user);
    await dispatch(userData(user));
    await dispatch(setPreferences(preferences));
    dispatch(
      setAllFiltersNoOfRecordInTable({
        noOfRecords: data?.NoOfRecordInTable ? data?.NoOfRecordInTable : 10,
      })
    );

    dispatch(setLoginTime(time));
    dispatch(gettingAdvSettingData_Call());
    dispatch(updateMasterListCodesFunction());
    dispatch(getAllDenialCoordinator());
    dispatch(getAllSubsriptionModules());
    dispatch(getClaimPaymentCustomColumns_Call());

    // dispatch(saveLogForLogActivity("Successfully logged in..."));

    // dispatch,stateValueSetter,userId,pageName
  } catch (err) {
    console.log("--- >> ===", err);

    // console.log("error occured while dispatching", err);
  }

  if (userRoles.includes("e005c454-f8a8-4329-a32a-2527142995a7")) {
    window.localStorage.setItem("logged_in", true);
    window.localStorage.setItem("logged_in_Type", "Azure AD");
  } else {
    window.localStorage.setItem("logged_in", true);
    window.localStorage.setItem("logged_in_Type", "Azure AD");
  }
};
const setUserInfoForAppUsageIntoReduxSSO = async (
  data,
  accessToken,
  expiry,
  dispatch
) => {
  // console.log("setUserInfoForAppUsageIntoReduxSSO_2_data--->>", data);

  let userRoles = data?.roles.map((item, index) => {
    return item?.roleId;
  });
  let subscription = "";
  if (data?.subscriptions != null) {
    subscription = data?.subscriptions[0]?.subscriptionId;
  }

  let preferences = {
    NoOfRecordInTable: data?.NoOfRecordInTable ? data?.NoOfRecordInTable : 10,
    dashboardTimePeriod: data?.dashboardTimePeriod,
    Theme: data?.Theme,
    Navigation: data?.Navigation,
    DateRange: data?.DateRange,
    PinFilter: data?.PinFilter,
    CoordinatorPreference: data?.CoordinatorPreference
      ? data?.CoordinatorPreference?.toString()
      : null,
    MacAddressPreference: data?.MacAddressPreference
      ? data?.MacAddressPreference
      : macAddressOptions?.[0]?.value,
  };

  localStorage.setItem("NoOfRecordInTable", preferences.NoOfRecordInTable);
  localStorage.setItem("defaultClient", data?.defaultClient);

  dispatch(setInitialPageSize(preferences.NoOfRecordInTable));
  // setDefaultDateOnAllDashboards(dispatch, data?.dashboardTimePeriod);

  const time = moment().format("HH:mm:ss");
  let user = {
    userName: data?.firstName + " " + data?.lastName,
    subscriptionID: subscription,
    domainID: data?.domainId,
    loginUserData: {
      ...data,
      isOnlyPolicyCopilotSelected:
        data?.selectedModules?.some((item) =>
          [
            "Policy",
            "Policy API",
            "Eligibility Copilot",
            "Policy Copilot",
            "Custom Copilot",
          ].includes(item.moduleName)
        ) &&
        !data?.selectedModules?.some((item) =>
          ["Appeals", "Denials"].includes(item.moduleName)
        ),
    },
    userToken: data?.userId,
    dashboardTimePeriod: data?.dashboardTimePeriod,
    applicationToken: accessToken,
    applicationTokenExpiry: expiry,
    userRoles: userRoles,
    permission: userRoles,
    organizationName: data?.OrganizationName,
    domainRealName: data?.DomainName,

    domainName: data?.LocalDomain,

    code: "",
    loginType: "Local",
    loginTypeForProfile: "SSO",

    userTimeZone: data?.timeZone,
    SubsCustomColumn: data?.EnableCustomColumn,
    AdditionalDetail: data?.AdditionalDetail,
  };

  // setDefaultDateOnAllDashboards(dispatch, data?.dashboardTimePeriod);

  if (data?.AdditionalDetail) {
    const json = data?.AdditionalDetail;
    const obj = JSON.parse(json);
    // console.log("obj--->>", obj);

    getDefaultInitialDate4App(dispatch, obj);
  }

  try {
    // console.log("user__2--->>", user);

    await dispatch(userData(user));
    await dispatch(setPreferences(preferences));
    dispatch(
      setAllFiltersNoOfRecordInTable({
        noOfRecords: data?.NoOfRecordInTable ? data?.NoOfRecordInTable : 10,
      })
    );
    dispatch(setLoginTime(time));
    dispatch(gettingAdvSettingData_Call());
    dispatch(updateMasterListCodesFunction());
    dispatch(getAllDenialCoordinator());
    dispatch(getAllSubsriptionModules());
    dispatch(getClaimPaymentCustomColumns_Call());

    // dispatch(saveLogForLogActivity("Successfully logged in..."));

    // dispatch,stateValueSetter,userId,pageName
  } catch (err) {
    console.log("error occured while dispatching --->>", err);
  }

  if (userRoles.includes("e005c454-f8a8-4329-a32a-2527142995a7")) {
    window.localStorage.setItem("logged_in", true);
    window.localStorage.setItem("logged_in_Type", "Azure AD");
  } else {
    window.localStorage.setItem("logged_in", true);
    window.localStorage.setItem("logged_in_Type", "Azure AD");
  }
};
const setUserInfoForAppUsageIntoReduxNew = async (data, code, navigate) => {
  // console.log("setUserInfoForAppUsageIntoReduxNew_3_data--->>", data);
  let userRoles = data?.roles.map((item, index) => {
    return item?.roleId;
  });
  let subscription = "";
  if (data?.subscriptions != null) {
    subscription = data?.subscriptions[0]?.subscriptionId;
  }

  let preferences = {
    NoOfRecordInTable: data?.NoOfRecordInTable ? data?.NoOfRecordInTable : 10,
    dashboardTimePeriod: data?.dashboardTimePeriod,
    Theme: data?.Theme,
    Navigation: data?.Navigation,
    DateRange: data?.DateRange,
    PinFilter: data?.PinFilter,
    CoordinatorPreference: data?.CoordinatorPreference
      ? data?.CoordinatorPreference?.toString()
      : null,
    MacAddressPreference: data?.MacAddressPreference
      ? data?.MacAddressPreference
      : macAddressOptions?.[0]?.value,
  };

  localStorage.setItem("NoOfRecordInTable", data.NoOfRecordInTable);
  localStorage.setItem("defaultClient", data?.defaultClient);

  const time = moment().format("HH:mm:ss");

  // console.log("3--->>", data);
  let user = {
    userName: data?.firstName + " " + data?.lastName,
    subscriptionID: subscription,
    domainID: data?.domainId,
    loginUserData: {
      ...data,

      // isOnlyPolicyCopilotSelected: data?.selectedModules?.some((item) =>
      //   [
      //     "Policy",
      //     "Policy API",
      //     "Eligibility Copilot",
      //     "Policy Copilot",
      //     "Custom Copilot",
      //   ].includes(item.moduleName)
      // ),

      isOnlyPolicyCopilotSelected:
        data?.selectedModules?.some((item) =>
          [
            "Policy",
            "Policy API",
            "Eligibility Copilot",
            "Policy Copilot",
            "Custom Copilot",
          ].includes(item.moduleName)
        ) &&
        !data?.selectedModules?.some((item) =>
          ["Appeals", "Denials"].includes(item.moduleName)
        ),
    },
    userToken: data?.userId,
    applicationToken: data?.AccessToken,
    applicationTokenExpiry: data?.AccessTokenExpiresOn,
    userRoles: userRoles,
    permission: userRoles,
    code: code,
    domainRealName: data?.DomainName,
    // domainName: data?.DomainName,
    domainName: data?.LocalDomain,
    loginType: "Local",
    loginTypeForProfile: "Local",
    userTimeZone: data?.timeZone,
    SubsCustomColumn: data?.EnableCustomColumn,
    AdditionalDetail: data?.AdditionalDetail,
  };
  try {
    if (data?.AdditionalDetail) {
      const json = data?.AdditionalDetail;
      const obj = JSON.parse(json);
      // console.log("obj--->>", obj);

      getDefaultInitialDate4App(store.dispatch, obj);
    }

    // console.log("user__3--->>", user);

    await store.dispatch(userData(user));
    await store.dispatch(setPreferences(preferences));
    store.dispatch(
      setAllFiltersNoOfRecordInTable({
        noOfRecords: data?.NoOfRecordInTable ? data?.NoOfRecordInTable : 10,
      })
    );
    store.dispatch(setLoginTime(time));
    store.dispatch(gettingAdvSettingData_Call());
    store.dispatch(updateMasterListCodesFunction());
    store.dispatch(getAllDenialCoordinator());
    store.dispatch(getAllSubsriptionModules());
    store.dispatch(getClaimPaymentCustomColumns_Call());

    // dispatch(saveLogForLogActivity("Successfully logged in..."));

    // dispatch,stateValueSetter,userId,pageName

    window.localStorage.setItem("logged_in", true);
    window.localStorage.setItem("Logged_in_Type", "Local");

    navigate(browserRoute.REACT_SERVER_DEFAULT_URL);
  } catch (err) {
    console.log("error occured while dispatching--->>", err);
  }
};

let cacheParams = {};
let cacheDate = "";
function deleteCookie() {
  Cookies.remove("SignInStateCookie", { path: "/", httpOnly: true });
}
export const validateEmail = (email) => {
  let regEmail =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regEmail.test(email)) {
    return false;
  } else {
    return true;
  }
};

export const getOrganizationName = async ({
  email,
  applicationToken,
  dispatch,
}) => {
  await axios
    .get(`${serverRoutes?.RETRIEVE_USER_BY_EMAIL}${email}`, {
      headers: {
        Authorization: `Bearer ${applicationToken}`,
        ["X-Version"]: "1.0",
        ["X-Domain"]: "8700a71f-b5f5-43a5-be17-492c67008132",
      },
    })
    .then((res) => {
      if (res?.data?.success) {
        dispatch(setOrganization(res?.data?.data?.OrganizationName));

        return res;
      }
    })
    .catch();
};
export const getUserName = () => {
  const { loginUserData: user } = store.getState().pReducers.user;
  let userInfo = user;
  let userNameDisplay = userInfo?.displayName;
  if (userNameDisplay) {
    // Trim whitespace and split the name into parts
    const nameParts = userNameDisplay?.trim()?.split(/\s+/);
    if (nameParts?.length === 0) {
      return ""; // Handle the edge case of an empty name
    }

    // Take the first initial
    const firstInitial = nameParts[0][0].toUpperCase();

    // Take the last initial (handle case where name might have one part)
    const lastInitial =
      nameParts.length > 1
        ? nameParts[nameParts.length - 1][0].toUpperCase()
        : "";

    // Return the concatenated initials
    return firstInitial + lastInitial;
  } else {
    return 0;
  }
};
export const returnOrganizationName = () => {
  return store.getState().pReducers.user?.loginUserData?.OrganizationName;
};
export const getApplicationToken = () => {
  return store.getState()?.pReducers?.user?.applicationToken;
};
export const getDomainId = () => {
  return store.getState().pReducers.user.loginUserData?.domainId;
};
export const validateTenant = (tenantId) => {
  if (approvedTenants.includes(tenantId)) {
    return true;
  } else return false;
};
export const handleUserVeification = ({
  email,
  accessToken,
  tenantId,
  expiry,
  setAzureLoading,
  dispatch,
  setAzureError,
  azureError,
  instance,
  history,
}) => {
  axios
    .get(`${serverRoutes?.RETRIEVE_USER_BY_EMAIL_SSO}${email}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ["X-Version"]: "1.0",
        // ["X-Domain"]: domainID,
        // ["X-Domain"]: "0E02C258-3EB9-4E39-B60B-71EA229D48A3",
      },
    })
    .then(async (res) => {
      const data = res?.data;
      if (res?.data?.success) {
        const AzureTenantId = data?.data?.AzureTenantId;
        if (
          AzureTenantId.toString().trim("") === tenantId.toString().trim("")
        ) {
          if (data?.data?.IsActive === false) {
            setAzureLoading(false);
            window.localStorage.clear();

            setAzureError({
              state: true,
              message: "User is not active, please contact administrator.",
            });
            return;
          }
          await setUserInfoForAppUsageIntoRedux(
            data?.data,
            accessToken,
            expiry,
            dispatch
          );
        } else {
          setAzureLoading(false);
          window.localStorage.clear();
          // window.caches.delete();
          // dispatch(logoutUser());
          // toast.error(
          //   error.response.data.message
          //     ? error.response.data.message
          //     : "Something went wrong!",
          //   {
          //     position: toast.POSITION.TOP_CENTER,
          //   }
          // );
          setAzureError({
            state: true,
            message:
              "Oops! It seems you don't have access to DataRovers. Try signing in with a different account that has the correct permissions.",
          });
        }
      } else {
        setAzureLoading(false);
        window.localStorage.clear();
        // window.caches.delete();

        // dispatch(logoutUser());

        // toast.error(data?.message ? data?.message : "Something went wrong!", {
        //   position: toast.POSITION.TOP_CENTER,
        // });

        setAzureError({
          state: true,
          message:
            "The user doesn’t exist in our record please sign in again with a different user account",
        });

        // history.push("/logout");
        // deleteCookie();
        // setTimeout(() => {
        //   instance.logoutRedirect({
        //     postLogoutRedirectUri: "/",
        //   });
        // }, 1000);
      }
    })

    .catch((error) => {
      console.log("hey khizer===", error);
      setAzureLoading(false);
      window.localStorage.clear();
      // window.caches.delete();
      // dispatch(logoutUser());
      // toast.error(
      //   error.response.data.message
      //     ? error.response.data.message
      //     : "Something went wrong!",
      //   {
      //     position: toast.POSITION.TOP_CENTER,
      //   }
      // );
      setAzureError({
        state: true,
        message:
          "The user doesn’t exist in our record please sign in again with a different user account",
      });

      // history.push("/logout");

      // deleteCookie();
      // setTimeout(() => {
      //   instance.logoutRedirect({
      //     postLogoutRedirectUri: "/",
      //   });
      // }, 2000);
    });
};
export const handleUserSSO = ({
  email,
  accessToken,
  expiry,
  setAzureLoading,
  dispatch,
  setAzureError,
}) => {
  axios
    .get(`${serverRoutes?.RETRIEVE_USER_BY_EMAIL_SSO}${email}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ["X-Version"]: "1.0",
        // ["X-Domain"]: domainID,
        // ["X-Domain"]: "0E02C258-3EB9-4E39-B60B-71EA229D48A3",
      },
    })
    .then(async (res) => {
      const data = res?.data;
      if (res?.data?.success) {
        if (data?.data?.IsActive === false) {
          setAzureLoading(false);
          window.localStorage.clear();
          // window.caches.delete();
          // dispatch(logoutUser());
          // toast.error(
          //   error.response.data.message
          //     ? error.response.data.message
          //     : "Something went wrong!",
          //   {
          //     position: toast.POSITION.TOP_CENTER,
          //   }
          // );
          setAzureError({
            state: true,
            message: "User is not active, please contact administrator.",
          });
          return;
        }
        await setUserInfoForAppUsageIntoReduxSSO(
          data?.data,
          accessToken,
          expiry,
          dispatch
        );
      } else {
        setAzureLoading(false);
        window.localStorage.clear();

        setAzureError({
          state: true,
          message:
            "The user doesn’t exist in our record please sign in again with a different user account",
        });
      }
    })

    .catch((error) => {
      setAzureLoading(false);
      window.localStorage.clear();

      setAzureError({
        state: true,
        message:
          "The user doesn’t exist in our record please sign in again with a different user account",
      });
    });
};
export const handleNewNotification = async (message) => {
  try {
    // let msg = JSON.parse(message);
    let msg = message;
    const { newNotificationCount } =
      store.getState().npReducers.filters.notificationsFilter;
    store.dispatch(
      setListingState({
        listing: "notificationsFilter",
        value: {
          newNotification: true,
          newNotificationValue: msg,
          newNotificationCount: newNotificationCount + 1,
        },
      })
    );
  } catch (error) {}
};
export const initialRenderNotification = async () => {
  try {
    const { newNotificationCount } =
      store.getState().npReducers.filters.notificationsFilter;
    const params = {
      start: 0,
      limit: 10,
      timestamp: new Date().getTime(),
    };

    genericAxiosCall(
      serverRoutes?.GET_ALL_NOTIFICATIONS,
      "get",
      "",
      params
    ).then((res) => {
      if (res?.data?.data?.length > 0) {
        const data = res?.data?.data;
        const filtered = data?.filter(
          (item) => item?.isViewed?.toString() === "false"
        );
        if (filtered?.length) {
          store.dispatch(
            setListingState({
              listing: "notificationsFilter",
              value: {
                newNotification: true,
                newNotificationCount: filtered?.length,
              },
            })
          );
        }
        // data?.map((item) => {
        //   if (item?.isViewed?.toString() === "false") {
        //     store.dispatch(
        //       setListingState({
        //         listing: "notificationsFilter",
        //         value: {
        //           newNotification: true,
        //           newNotificationCount: newNotificationCount + 1,
        //         },
        //       })
        //     );
        //   }
        // });
      }
    });
  } catch (error) {}
};
export const initialRenderModules = async () => {
  try {
    store.dispatch(getAllSubsriptionModules());
    store.dispatch(getAllBoughtModules());
    store.dispatch(getUserDetailsForSelectedModules());
    store.dispatch(getAllCopilotBot());
    store.dispatch(gettingClientsConfigListForDropdown());
    store.dispatch(getModulePricing());
    store.dispatch(gettingDenialsConfigList_Call());
    await setCurrentModule();
  } catch (error) {}
};

export const getQuarterMonths = (value) => {
  try {
    const split = value?.split("-");
    if (split?.length > 0) {
      const btn = split[0];
      let months = {
        startMonth: "",
        endMonth: "",
        year: split[1],
      };
      switch (btn) {
        case "Q1":
          months = {
            ...months,
            startMonth: "01",
            endMonth: "03",
          };
          break;
        case "Q2":
          months = {
            ...months,
            startMonth: "04",
            endMonth: "06",
          };
          break;
        case "Q3":
          months = {
            ...months,
            startMonth: "07",
            endMonth: "09",
          };
          break;
        case "Q4":
          months = {
            ...months,
            startMonth: "10",
            endMonth: "12",
          };
          break;
        default:
          break;
      }
      return months;
    }
  } catch (err) {
    console.log("err--->>", err);
  }
};
export const forMonth = [
  {
    label: "Jan",
    value: "1",
  },
  {
    label: "Feb",
    value: "2",
  },
  {
    label: "Mar",
    value: "3",
  },
  {
    label: "April",
    value: "4",
  },
  {
    label: "May",
    value: "5",
  },
  {
    label: "June",
    value: "6",
  },
  {
    label: "July",
    value: "7",
  },
  {
    label: "Aug",
    value: "8",
  },
  {
    label: "Sept",
    value: "9",
  },
  {
    label: "Oct",
    value: "10",
  },
  {
    label: "Nov",
    value: "11",
  },
  {
    label: "Dec",
    value: "12",
  },
];

export const handleMonthsForDashBeforeAPICall = (
  monthParams,
  dateForYear,
  mainStateParams,
  target,
  dispatch,
  setDateUpdate
) => {
  // console.log(
  //   "handleMonthsForDashBeforeAPICall--->>",
  //   monthParams,
  //   dateForYear
  // );
  try {
    const { value: month } = monthParams;
    const { value: year } = dateForYear;

    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    };
    let totalDays = daysInMonth(month, year);
    const startDate = new Date();
    startDate.setDate(1);
    startDate.setMonth(month - 1);
    startDate.setFullYear(year);
    const endDate = new Date();
    endDate.setDate(1);
    endDate.setMonth(month - 1);
    endDate.setFullYear(year);
    endDate.setDate(totalDays);

    const fromDate = startDate.toLocaleDateString("en-US", options);
    const toDate = endDate.toLocaleDateString("en-US", options);

    // console.log("handleMonthsForDashBeforeAPICall_fromDate--->>", fromDate);
    // console.log("handleMonthsForDashBeforeAPICall_toDate--->>", toDate);
    // console.log(
    //   "handleMonthsForDashBeforeAPICall_monthParams--->>",
    //   monthParams
    // );
    // console.log(
    //   "handleMonthsForDashBeforeAPICall_dateForYear--->>",
    //   dateForYear
    // );
    // console.log(
    //   "handleMonthsForDashBeforeAPICall_mainStateParams--->>",
    //   mainStateParams
    // );
    dispatch(
      setListingState({
        listing: [target],
        value: {
          from: fromDate,
          to: toDate,
          dateForMonth: monthParams,
          dateForYear: dateForYear,
          dateMainState: mainStateParams,
        },
      })
    );

    if (setDateUpdate) {
      // console.log("setDateUpdated_001--->>");
      setDateUpdate(true);
    }
  } catch (err) {
    console.log("err--->>", err);
  }
};

const handleSelectionYearsForDashBeforeAPICall = (
  dateForYear,
  target,
  dispatch,
  setDateUpdate
) => {
  // console.log("handleSelectionYearsForDashBeforeAPICall--->>", dateForYear);
  try {
    const diff = Number(new Date().getFullYear()) - dateForYear?.value;
    const endDate = moment()
      .endOf("year")
      .subtract(diff, "year")
      .format("MM/DD/YYYY");
    const startDate = moment()
      .startOf("year")
      .subtract(diff, "year")
      .format("MM/DD/YYYY");
    dispatch(
      setListingState({
        listing: [target],
        value: {
          from: startDate,
          to: endDate,
          dateForYear: dateForYear,
        },
      })
    );

    if (setDateUpdate) {
      setDateUpdate(true);
      // console.log("setDateUpdated_002--->>");
    }
  } catch (err) {
    console.log("err--->>", err);
  }
};

const handleSelectionQuarterForDashBeforeAPICall = (
  dateForQuarter,
  target,
  dispatch,
  setDateUpdate
) => {
  try {
    // console.log("11--handleSelectionQuarterForDashBeforeAPICall--->>");
    // const btn = dateForQuarter?.value;
    const monthRange = getQuarterMonths(dateForQuarter?.value);
    if (monthRange) {
      let totalEndDays = daysInMonth(
        parseInt(monthRange?.endMonth),
        parseInt(monthRange?.year)
      );
      const endDate = `${monthRange?.endMonth}/${totalEndDays}/${monthRange?.year}`;
      const startDate = `${monthRange?.startMonth}/01/${monthRange?.year}`;
      dispatch(
        setListingState({
          listing: [target],
          value: {
            from: startDate,
            to: endDate,
            dateForQuarter: dateForQuarter,
          },
        })
      );

      if (setDateUpdate) {
        // console.log("setDateUpdate_003--->>");
        setDateUpdate(true);
      }
    }
  } catch (err) {
    console.log("err--->>", err);
  }
};

export const setDateForDashboardsBeforeAPICall = (
  target,
  setDateUpdate,
  dispatch,
  isParams
) => {
  try {
    const { dateMainState, dateForMonth, dateForYear, dateForQuarter } =
      store.getState().npReducers.filters[target];

    // console.log("setDateForDashboardsBeforeAPICall--->>", target);
    // console.log("dateMainState--->>", dateMainState);
    // console.log("dateForMonth--->>", dateForMonth);
    // console.log("dateForYear--->>", dateForYear);
    // console.log("dateForQuarter--->>", dateForQuarter);

    if (isParams) {
      // console.log("setDateForDashboardsBeforeAPICall_params--->>");
      //  =============  params date handle here ================

      const params = new URLSearchParams(window.location.search);
      if (params.get("year") && params.get("month") && params.get("quarter")) {
        // console.log("just___if--->>");

        const mainState = params.get("dateMain");
        const year = {
          label: params.get("year"),
          value: Number(params.get("year")),
        };

        let month;
        forMonth.map((item) => {
          if (params.get("month") === item?.value) {
            month = item;
          }
        });
        const quarter = {
          label: params.get("quarter"),
          value: params.get("quarter"),
        };

        // console.log("params--->>", target);
        // console.log("params_mainState--->>", mainState);
        // console.log("params_month--->>", month);
        // console.log("params_year--->>", year);
        // console.log("params_quarter--->>", quarter);

        // console.log("ooo----->>", dateMainState == "Quarter");

        dispatch(
          setListingState({
            listing: [target],
            value: {
              dateMainState: mainState,
              dateForYear: year,
              dateForQuarter: quarter,
              dateForMonth: month,
            },
          })
        );

        if (mainState == "Months") {
          // console.log("call_Months2--->>");
          handleMonthsForDashBeforeAPICall(
            month,
            year,
            mainState,
            target,
            dispatch,
            setDateUpdate
          );
        } else if (mainState == "Years") {
          // console.log("call_Years2--->>");
          handleSelectionYearsForDashBeforeAPICall(
            year,
            target,
            dispatch,
            setDateUpdate
          );
        } else if (mainState == "Quarter") {
          // console.log("call_Quarter2--->>");
          handleSelectionQuarterForDashBeforeAPICall(
            quarter,
            target,
            dispatch,
            setDateUpdate
          );
        }
      } else {
        console.log("just_else----->>");
      }
    } else {
      // console.log("setDateForDashboardsBeforeAPICall_non-params--->>");

      //  ============= non params date handle here ================
      if (dateMainState == "Months") {
        // console.log("call_Months2--->>");
        handleMonthsForDashBeforeAPICall(
          dateForMonth,
          dateForYear,
          dateMainState,
          target,
          dispatch,
          setDateUpdate
        );
      } else if (dateMainState == "Years") {
        // console.log("call_Years2--->>");
        handleSelectionYearsForDashBeforeAPICall(
          dateForYear,
          target,
          dispatch,
          setDateUpdate
        );
      } else if (dateMainState == "Quarter") {
        // console.log("call_Quarter2--->>");
        handleSelectionQuarterForDashBeforeAPICall(
          dateForQuarter,
          target,
          dispatch,
          setDateUpdate
        );
      }
    }

    return 0;
  } catch (err) {
    console.log("err--->>", err);
  }
};

export const getUserId = () => {
  return store.getState().pReducers.user.loginUserData.userId;
};
export const markAsViewed = async () => {
  try {
    genericAxiosCall(
      serverRoutes?.MARK_AS_VIEWED_NOTIFICATION,
      "put",
      "",
      ""
    ).then((res) => {
      if (res?.data?.success) {
        store.dispatch(
          setListingState({
            listing: "notificationsFilter",
            value: {
              newNotification: false,
              newNotificationCount: 0,
            },
          })
        );
      }
    });
  } catch (error) {}
};

export const convertServiceLineToQueryFullPath = (serviceLineArr) => {
  let str = "";
  if (serviceLineArr && serviceLineArr?.length > 0) {
    serviceLineArr?.map((d) => {
      let c = `${d?.Name}@${d?.Id}@${getLevel(d?.Level)}^`;
      d?.fullPath?.map((item) => {
        let x = `${item?.Id}@${getLevel(item?.Level)}`;
        c = c + "-" + x;
      });
      str = str + "!" + c;
    });
  }
  return str ? str : "";
  // convertQueryToServiceLine(str);
};

export const convertQueryToServiceLine = (query) => {
  let arr = convertToTreeObj(query);
  return arr;

  // dispatch(
  //   setListingState({
  //     listing: target,
  //     value: {
  //       [item]: arr,
  //       pageNumber: 1,
  //     },
  //   })
  // );
};

const convertToTreeObj = (data) => {
  let arr = [];
  data?.map((item, index) => {
    let d;
    let val = item.split("^");
    if (val?.length > 0) {
      let split = val[0]?.split("@");
      let fullPath = getFullPath(val[1]);
      if (split?.length >= 3) {
        d = {
          Name: split[0],
          Id: split[1],
          Level: `Level ${split[2]}`,
          fullPath: fullPath,
        };
        arr.push(d);
      }
    }
  });
  return arr;
};
const getFullPath = (arr) => {
  let newArr = [];

  if (arr) {
    let split = arr?.split("-");
    split?.map((item) => {
      let childSplit = item?.split("@");
      if (childSplit?.length >= 2) {
        newArr.push({
          Id: childSplit[0],
          Level: `Level ${childSplit[1]}`,
        });
      }
    });
  }
  return newArr;
};
export const convertArrayToString = (array) => {
  if (array?.length) {
    // Join all elements with commas
    const commaSeparatedString = array.join(", ");

    // Get the last element
    const lastElement = array[array.length - 1];

    // Replace the last element with "and" + last element
    const resultString = commaSeparatedString.replace(
      `, ${lastElement}`,
      ` and ${lastElement}`
    );

    return resultString;
  }
};
export const splitStringByWordCount = (inputString) => {
  const maxWordCount = 445;

  const words = inputString
    // .replace(/\n/g, " ")
    // .replace(/\s+/g, " ")
    .trim()
    .split(" ");
  const result = [];
  let currentLine = "";

  words.forEach((word) => {
    if ((currentLine + word).split(" ").length <= maxWordCount) {
      currentLine += word + " ";
    } else {
      result.push(currentLine.trim());
      currentLine = word + " ";
    }
  });

  if (currentLine.trim() !== "") {
    result.push(currentLine.trim());
  }

  return result;
};
export const sampleQuestions = [
  {
    image: CMSIcon,
    title: "CMS National",
    msg: `Inquire about policy coverage from CMS National for accurate guidance. `,
  },
  {
    image: PrivateIcon,
    title: "MAC",
    msg: `Seek advice from your Medicare Administrative Contractor for payment-related queries. `,
  },
  {
    image: PrivateIcon,
    title: "Private Payers",
    msg: `Get personalized coverage insights by consulting with private payers. `,
  },
  {
    image: FDAIcon,
    title: "FDA",
    msg: `Access procedural recommendations and regulatory insights from the FDA.`,
  },
];

export const refreshMsalToken = async () => {
  const accounts = msalInstance.getAllAccounts();
  await msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    })
    .then((response) => {
      store.dispatch(
        updateApplicationTokenExpiry({
          applicationToken: response?.accessToken,
          applicationTokenExpiry: response?.expiresOn,
        })
      );
    })
    .catch((error) => {
      singleLogout2();
    });
};
export const refreshToken = async () => {
  const { confirmCode_screen_info, code, applicationToken } =
    store.getState().pReducers.user;

  if (applicationToken) {
    await users
      .renewToken({
        email: confirmCode_screen_info.email,
        verificationCode: confirmCode_screen_info.id,
        code: code,
        accessToken: applicationToken,
      })
      .then(async (response) => {
        const { data: result } = response;
        if (
          result.success === true &&
          result.data !== "" &&
          result.data !== null
        ) {
          let data = response.data?.data;

          await store.dispatch(
            updateApplicationTokenExpiry({
              applicationToken: data?.AccessToken,
              applicationTokenExpiry: data?.AccessTokenExpiresOn,
            })
          );
        } else {
          singleLogout2();
        }
      })
      .catch((newerror) => {
        singleLogout2();
      });
  }
};
export const getMasterListCodeDescription = (code) => {
  const { masterListCodes } = store.getState().pReducers.user;
  try {
    if (masterListCodes[code.toString()]) {
      return masterListCodes[code]?.description;
    } else {
      return "-";
    }
  } catch (err) {
    // console.log("err--->>", err);
  }
};
export const getMasterListCodeList = (code) => {
  return store.getState().pReducers.user?.masterListCodes;
};
export const getMasterListCodeDescriptionReasonCode = (code) => {
  const { masterListReasonCodes } = store.getState().pReducers.user;
  try {
    if (masterListReasonCodes[code.toString()]) {
      return masterListReasonCodes[code]?.description;
    } else {
      return "";
    }
  } catch (error) {}
};
export const getMasterListCodeReasonCode = (code) => {
  const { masterListReasonCodes } = store.getState().pReducers.user;
  try {
    return Object.keys(masterListReasonCodes)?.map(
      (item) => masterListReasonCodes[item]
    );
  } catch (error) {}
};
export const getMasterListCodeReasonCodeForMultiSelect = (code) => {
  const { masterListReasonCodes } = store.getState().pReducers.user;
  try {
    return Object.keys(masterListReasonCodes)?.map((item) => {
      return {
        value: masterListReasonCodes[item]?.code,
        label: `${masterListReasonCodes[item]?.code}-${masterListReasonCodes[item]?.description}`,
      };
    });
  } catch (error) {}
};

export const returnEmptyTableColumn = (type, row, isFlag) => {
  if (type === "string") {
    return <div style={{ textAlign: "right" }}>-</div>;
  } else if (type === "number") {
    return `$${0}`;
  } else if (type === "percentage") {
    return <span className="V2-pyt-lable-red">{`${0}%`}</span>;
  } else if (type === "score") {
    const displayValue =
      row?.value === null || row?.value === undefined || row?.value === ""
        ? "-"
        : `${row?.value === 0 ? "0%" : `${row?.value}%`}`;

    return (
      <div className="flex items-center">
        <span
          style={{
            display: "flex",
            alignItems: "center",
            padding: "2px 0px",
            background: "none",
            minWidth: "55px",
          }}
        >
          {displayValue}
          {row?.value !== null &&
            row?.value !== undefined &&
            row?.value !== "" &&
            row?.value === 0 && <ScoreDown className="DR-sc-red" />}
        </span>
        {isFlag == true ? (
          <span>
            {(row?.isExceedUnites === true ||
              row?.suggestedCPTFlag === true ||
              row?.isBundledServiceNotAllowed === true) && (
              <span
                className="DR-flag-red DR-flag-blue"
                style={{ width: "25px", marginLeft: "10px" }}
              >
                <DRFlagClaimIcon />
              </span>
            )}
          </span>
        ) : null}
      </div>
    );
  } else {
    // Default case
    return "-";
  }
};

//CPT Multiple Description
// export const handleMultipleCptCodeDecs = (item) => {
//   let mappedArray = item.includes(",") ? (
//     item.split(",").map((code) => (
//       <BootstrapTooltip
//         placement="bottom"
//         title={getMasterListCodeDescription(code.trim())}
//       >
//         <span>
//           {code}
//           {","}
//         </span>
//       </BootstrapTooltip>
//     ))
//   ) : (
//     <BootstrapTooltip
//       placement="bottom"
//       title={getMasterListCodeDescription(item)}
//     >
//       <span>{item}</span>
//     </BootstrapTooltip>
//   );

//   return mappedArray;
// };
export const handleMultipleCptCodeDecs = (item) => {
  let mappedArray = item.includes(",") ? (
    item.split(",").map((code, index, array) => (
      <span key={index}>
        <BootstrapTooltip
          placement="bottom"
          title={getMasterListCodeDescription(code.trim())}
        >
          <span>
            {code}
            {index !== array.length - 1 && ","}
          </span>
        </BootstrapTooltip>
      </span>
    ))
  ) : (
    <BootstrapTooltip
      placement="bottom"
      title={getMasterListCodeDescription(item)}
    >
      <span>{item}</span>
    </BootstrapTooltip>
  );

  return mappedArray;
};

export const handleCptCodeDecs = (item) => {
  let mappedArray;

  if (item.includes(",")) {
    const codes = item.split(",").map((code) => code.trim()); // Trim leading/trailing spaces
    const firstThreeCodes = codes.slice(0, 3);
    const remainingCodes = codes.slice(3);

    mappedArray = firstThreeCodes.map((code, index) => (
      <BootstrapTooltip
        placement="bottom"
        title={getMasterListCodeDescription(code)}
        key={index}
      >
        <span>
          {code}
          {index < firstThreeCodes.length - 1 && ","}
        </span>
      </BootstrapTooltip>
    ));

    if (remainingCodes.length > 0) {
      mappedArray.push(<span key="ellipsis"></span>);
    }
  } else {
    mappedArray = (
      <BootstrapTooltip
        placement="bottom"
        title={getMasterListCodeDescription(item)}
      >
        <span>{item}</span>
      </BootstrapTooltip>
    );
  }

  return mappedArray;
};

export const handleCptLenghtCheck = (item) => {
  const cptArray = handleCptCodeDecs(item);

  if (cptArray.length > 3) {
    return (
      <span className="DR-reason-text-cpt">
        {cptArray}
        <CPTPopOver cptArray={item} />
      </span>
    );
  } else {
    return <span className="DR-reason-text-cpt">{cptArray}</span>;
  }
};
export const handleReasonCodeLenghtCheck = (item) => {
  const reasonCodes = item.split(",");
  const visibleReasonCodes = reasonCodes.slice(0, 8);
  const hiddenReasonCodes = reasonCodes.slice(8);

  const reasonCodeElements = visibleReasonCodes.map((code, index) => (
    <BootstrapTooltip
      key={index}
      placement="bottom"
      title={getMasterListCodeDescriptionReasonCode(
        code.trim().split("-").pop()
      )}
    >
      <span>
        {code.trim()}
        {index !== visibleReasonCodes.length - 1 && ","}
      </span>
    </BootstrapTooltip>
  ));

  const popoverElement = hiddenReasonCodes.length > 0 && (
    <CPTPopOver reasonCodeArray={item} />
  );

  return (
    <span className="DR-reason-text-cpt">
      {reasonCodeElements}
      {popoverElement}
    </span>
  );
};

// Multiple Reason Code Description
export const handleReasonCodeDesc = (item) => {
  let reasonCodeArray = item.includes(",") ? (
    item.split(",").map((code, index) => (
      <BootstrapTooltip
        key={index}
        placement="bottom"
        title={getMasterListCodeDescriptionReasonCode(
          code.trim().split("-").pop()
        )}
      >
        <span>
          {code.trim()}
          {index !== item.split(",").length - 1 ? "," : ""}
        </span>
      </BootstrapTooltip>
    ))
  ) : (
    <BootstrapTooltip
      placement="bottom"
      title={getMasterListCodeDescriptionReasonCode(
        item.trim().split("-").pop()
      )}
    >
      <span>{item.trim()}</span>
    </BootstrapTooltip>
  );

  return reasonCodeArray;
};
export const handleTableColumn = (
  value,
  feature,
  row,
  orderBy,
  order,
  createSortHandlerCustom
) => {
  try {
    switch (true) {
      case feature?.isDate:
        return feature?.isDateFunction(value);
      case feature?.reasonCodeDescription:
        // return feature?.reasonCodeDescriptionFunc(value);
        return (
          <div
            style={{
              textAlign: "right",
            }}
          >
            {feature?.reasonCodeDescriptionFunc(value)}
          </div>
        );
      case feature?.isComaSeperated:
        return `$${feature?.comaSeperatedFunction(value)}`;
      case feature?.isNavlink:
        const navLinkState = feature?.isFromURL
          ? {
              pathname: `${feature?.targetURL}/${row[feature?.targetURLId]}`,
              // state: { from: `${feature?.fromURL}/${feature?.fromUrlParams}` },
            }
          : {
              pathname: `${feature?.targetURL}/${row[feature?.targetURLId]}`,
            };

        return (
          <>
            {row?.isNewClaim && feature?.isClaimCheck ? (
              <div
                style={{
                  cursor: "pointer",
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <span className="DR-tb-link">
                  <span
                    style={{
                      position: "absolute",
                      top: "-5px",
                      left: "-8px",
                      fontSize: "12px",
                    }}
                  >
                    <NewClaimIcon />
                  </span>

                  {value ? value : "-"}
                </span>
              </div>
            ) : (
              <NavLink to={navLinkState}>
                <span className="DR-tb-link">{value ? value : "-"}</span>
              </NavLink>
            )}
          </>
        );

      case feature?.isCpt:
        return (
          <div
            style={{
              textAlign: "right",
            }}
          >
            {feature?.cptLenghtFunc(value)}
          </div>
        );

      case feature?.payerNameLength:
        return feature?.payerNameFunc(value);
      case feature?.isClaimType:
        const claimType = feature?.claimTypeFunc(value);
        return claimType === true ? "Professional" : "Institutional";

      case feature?.isExpiredOn:
        return (
          <span
            style={{
              color: row?.appealExpiredOn?.toLowerCase().includes("days")
                ? parseInt(row?.appealExpiredOn) <= 30
                  ? "#ff9800" // Orange for days <= 30
                  : "#3f943f" // Green for days > 30
                : row?.appealExpiredOn?.toLowerCase() === "expired"
                ? "red" // Red for expired
                : "#000", // Default color for other cases
            }}
          >
            {row?.appealExpiredOn || "-"}
          </span>
        );

      case feature?.isPyt:
        return (
          <span
            className={
              row.diff <= 70 ? "V2-pyt-lable-red" : "V2-pyt-lable-green"
            }
          >
            {row?.diff}%
          </span>
        );
      case feature?.isPredicted:
        return (
          <div className="flex">
            <span className="Dr-pre-n-Status-normal">
              {typeof row?.finalScoredProbability !== "undefined" &&
              row?.finalScoredProbability !== "" &&
              row?.finalScoredProbability !== null
                ? `${Math.round(row?.finalScoredProbability * 100)}%`
                : "-"}
              <span
                // className={`gap-1 DR-sc-${
                //   row?.finalScoredProbability * 100 >= 50
                //     ? "green"
                //     : "red"
                // }`}
                className={`gap-1 DR-sc-${
                  row?.finalScoredProbability * 100 >= 60
                    ? "green"
                    : row?.finalScoredProbability * 100 > 40
                    ? "yellow"
                    : "red"
                }`}
                style={{
                  marginLeft: "0px",
                  marginRight: "5px",
                  padding: "2px 0px",
                  background: "none",
                }}
              >
                {typeof row?.finalScoredProbability !== "undefined" &&
                row?.finalScoredProbability !== "" &&
                row?.finalScoredProbability !== null ? (
                  row?.finalScoredProbability * 100 >= 50 ? (
                    <ScoreUp />
                  ) : (
                    <ScoreDown />
                  )
                ) : (
                  ""
                )}
              </span>
            </span>
          </div>
        );
      case feature?.isPredictedClaims:
        try {
          return (
            <div className="flex items-center">
              <BootstrapTooltip
                title={
                  <div>
                    <div>
                      {row?.approvedChargeAmountPer !== null &&
                      row?.approvedChargeAmountPer !== undefined
                        ? row?.approvedChargeAmountPer
                        : 0}
                      %
                    </div>
                  </div>
                }
              >
                <span
                  className={`flex items-center Dr-pre-n-Status-${
                    row?.approvedChargeAmountPer < 40
                      ? "red"
                      : row?.approvedChargeAmountPer >= 40 &&
                        row?.approvedChargeAmountPer <= 60
                      ? "yellow"
                      : row?.approvedChargeAmountPer > 60
                      ? "green"
                      : "normal"
                  }`}
                  style={{ minWidth: "55px" }}
                >
                  {~~parseInt(row?.approvedChargeAmountPer)}%
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "2px 0px",
                      background: "none",
                    }}
                    className={
                      row?.approvedChargeAmountPer < 40
                        ? "DR-sc-red"
                        : row?.approvedChargeAmountPer >= 40 &&
                          row?.approvedChargeAmountPer <= 60
                        ? "DR-sc-yellow"
                        : row?.approvedChargeAmountPer > 60
                        ? "DR-sc-green"
                        : ""
                    }
                  >
                    {row?.approvedChargeAmountPer < 50 ? (
                      <ScoreDown />
                    ) : (
                      <ScoreUp />
                    )}
                  </span>
                </span>
              </BootstrapTooltip>
              <span>
                {(row?.isExceedUnites === true ||
                  row?.suggestedCPTFlag === true ||
                  row?.isBundledServiceNotAllowed === true) && (
                  <span
                    className="DR-flag-red DR-flag-blue"
                    style={{ width: "25px", marginLeft: "10px" }}
                  >
                    <DRFlagClaimIcon />
                  </span>
                )}
              </span>
            </div>
          );
        } catch (err) {
          console.log("err--->>", err);
        }

      case feature?.isStatus:
        return (
          <BootstrapTooltip title={row?.claimPaymentStatus}>
            <span className="DR-v2-status-lable">
              {row?.claimPaymentStatus
                ? getClaimStatusShortForm(row?.claimPaymentStatus)
                : "-"}
            </span>
          </BootstrapTooltip>
        );

      default:
        return value;

      case feature?.isDrg:
        return (
          <BootstrapTooltip title={row?.drgDescription}>
            {row?.drgCode ? row?.drgCode : "-"}
          </BootstrapTooltip>
        );
    }
  } catch (err) {
    console.log("err--->>", err);
  }
};

// Define the handleTableHeader function
export const handleTableHeader = (
  columnName,
  feature,
  handleSorting,
  target
) => {
  switch (true) {
    case feature?.isSorting:
      return (
        <NewSortingDropDown
          orderBy={feature?.orderBy}
          order={feature?.order}
          label={feature?.sortingId}
          handler={(label, order) => handleSorting(label, order)}
          title={feature?.sortingTitle}
          target={target}
        />
      );
    default:
      // Display sorting title if isSorting is true; otherwise, display header title
      return feature?.isSorting ? feature?.sortingTitle : feature?.headerTitle;
  }
};

export const handleReasonCodeDescMod = (item) => {
  if (item === null) {
    return null; // or handle this case appropriately
  }

  let reasonCodeArray = item.includes(",") ? (
    item.split(",").map((code, index) => (
      <BootstrapTooltip
        key={index}
        placement="bottom"
        title={getMasterListCodeDescriptionReasonCode(
          code.trim().split("-").pop()
        )}
      >
        <span>
          {code.trim()}
          {index !== item.split(",").length - 1 ? "," : ""}
        </span>
      </BootstrapTooltip>
    ))
  ) : (
    <BootstrapTooltip
      placement="bottom"
      title={getMasterListCodeDescriptionReasonCode(
        item.trim().split("-").pop()
      )}
    >
      <span>{item.trim()}</span>
    </BootstrapTooltip>
  );

  return reasonCodeArray;
};

export async function commonPagination(
  getState,
  paramsTemp,
  params,
  dispatch,
  stateValueSetter,
  listingAction,
  apiURL,
  conditionalDispatch,
  conditionalApiURL,
  conditionalStateValueSetter,
  isCustomErrMsg,
  isError
) {
  try {
    let sliceState = getState().npReducers.filters[listingAction];
    let cacheData = { ...sliceState.cachePagination };
    let cachParamsState = { ...sliceState.cacheParams };
    let cache = cacheData[`page_${sliceState.pageNumber}`];
    if (
      cache &&
      // cache.claims.length > 0 &&
      JSON.stringify(cachParamsState) === JSON.stringify(paramsTemp)
    ) {
      dispatch(stateValueSetter(cache));
      // console.log("if>>");
      // return;
    } else {
      dispatch(
        setListingState({
          listing: listingAction,
          value: {
            loadingMore: "data-loading",
            // loadingFilters: "data-loading",
          },
        })
      );
      // console.log("else>>");

      if (JSON.stringify(cachParamsState) !== JSON.stringify(paramsTemp)) {
        await dispatch(
          setListingState({
            listing: listingAction,
            value: {
              cachePagination: {},
            },
          })
        );
        sliceState = getState().npReducers.filters[listingAction];
      }
      let filteredItems;
      // conditional func call here
      if (conditionalDispatch) {
        let dateObj = {
          to: params.to,
          from: params.from,
        };

        ///// Conditional API CALL start //////
        dispatch(
          setListingState({
            listing: listingAction,
            value: {
              // loadingMore: "data-loading",
              loadingFilters: "data-loading",
            },
          })
        );

        filteredItems = await genericAxiosCall(
          serverRoutes[conditionalApiURL],
          "get",
          "",
          params
        ).then((res) => {
          if (res?.data?.success) {
            dispatch(conditionalStateValueSetter(res?.data?.data));
            filteredItems = res?.data?.data;
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  loadingFilters: "",
                },
              })
            );
          } else {
            dispatch(conditionalStateValueSetter([]));
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  loadingFilters: "",
                },
              })
            );
          }
        });

        ///// Conditional API CALL end //////
      }

      ///// API CALL //////
      dispatch(
        setListingState({
          listing: listingAction,
          value: {
            cacheParams: paramsTemp,
          },
        })
      );
      cacheParams = paramsTemp;

      const dataItems = await genericAxiosCall(
        serverRoutes[apiURL],
        "get",
        "",
        params
      )
        .then((res) => {
          if (!res?.data?.success) {
            // toast[isError ? "error" : "info"](
            if (environment == "dev") {
              if (isError) {
                toast.error(
                  isCustomErrMsg ? res?.data?.message : `Something went wrong`,
                  {
                    position: toast.POSITION.TOP_CENTER,
                  }
                );
              }
            }

            dispatch(stateValueSetter([]));

            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  // hasMore: res?.data?.data?.claims?.length === sliceState.pageSize,
                  loadingMore: "",
                },
              })
            );
          } else {
            // cachePagination[`page_${sliceState.pageNumber}`] = res?.data?.data;
            let data = { ...sliceState.cachePagination };

            data[`page_${sliceState.pageNumber}`] = res.data.data;

            dispatch(stateValueSetter(res?.data?.data));
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  // hasMore: res?.data?.data?.claims?.length === sliceState.pageSize,
                  cachePagination: data,
                },
              })
            );
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  // hasMore: res?.data?.data?.claims?.length === sliceState.pageSize,
                  loadingMore: "",
                },
              })
            );
          }
          // axios.all([dataItems, filteredItems]).then((res) => {
          //   dispatch(
          //     setListingState({
          //       listing: listingAction,
          //       value: {
          //         loadingMore: "",
          //       },
          //     })
          //   );
          // });
          return res?.data;
        })
        .catch((error) => {
          // console.log("_error--->>", error);
          dispatch(
            setListingState({
              listing: listingAction,
              value: {
                initialLoading: false,
                loadingMore: "",
                hasMore: false,
              },
            })
          );
        });

      ////// //////////////
    }
  } catch (e) {
    console.log("commonPagination_e--->>", e);
    dispatch(
      setListingState({
        listing: listingAction,
        value: {
          initialLoading: false,
          loadingMore: "",
          loadingFilters: "",
          hasMore: false,
        },
      })
    );
    // dispatch(stateValueSetter([]));

    return false;
  }
}
export async function commonPaginationWithLoading(
  getState,
  paramsTemp,
  params,
  dispatch,
  stateValueSetter,
  listingAction,
  loadingAction,
  apiURL,
  conditionalDispatch,
  conditionalApiURL,
  conditionalStateValueSetter
) {
  try {
    let sliceState = getState().npReducers.filters[listingAction];
    let cacheData = { ...sliceState.cachePagination };
    let cachParamsState = { ...sliceState.cacheParams };
    let cache = cacheData[`page_${sliceState.pageNumber}`];

    if (
      cache &&
      // cache.claims.length > 0 &&
      JSON.stringify(cachParamsState) === JSON.stringify(paramsTemp)
    ) {
      dispatch(stateValueSetter(cache));

      // return;
    } else {
      dispatch(
        setListingState({
          listing: listingAction,
          value: {
            loadingMore: "data-loading",
            // loadingFilters: "data-loading",
          },
        })
      );

      if (JSON.stringify(cachParamsState) !== JSON.stringify(paramsTemp)) {
        await dispatch(
          setListingState({
            listing: listingAction,
            value: {
              cachePagination: {},
            },
          })
        );
        sliceState = getState().npReducers.filters[listingAction];
      }
      let filteredItems;
      // conditional func call here
      if (conditionalDispatch) {
        let dateObj = {
          to: params.to,
          from: params.from,
        };

        ///// Conditional API CALL start //////
        dispatch(
          setListingState({
            listing: listingAction,
            value: {
              // loadingMore: "data-loading",
              loadingFilters: "data-loading",
            },
          })
        );

        filteredItems = await genericAxiosCall(
          serverRoutes[conditionalApiURL],
          "get",
          "",
          params
        ).then((res) => {
          if (res?.data?.success) {
            dispatch(conditionalStateValueSetter(res?.data?.data));
            filteredItems = res?.data?.data;
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  loadingFilters: "",
                },
              })
            );
          } else {
            dispatch(conditionalStateValueSetter([]));
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  loadingFilters: "",
                },
              })
            );
          }
        });

        ///// Conditional API CALL end //////
      }

      ///// API CALL //////
      dispatch(
        setListingState({
          listing: listingAction,
          value: {
            cacheParams: paramsTemp,
          },
        })
      );
      cacheParams = paramsTemp;

      const dataItems = await genericAxiosCall(
        serverRoutes[apiURL],
        "get",
        "",
        params
      )
        .then((res) => {
          if (!res?.data?.success) {
            // toast.error(`Something went wrong`, {
            //   position: toast.POSITION.TOP_CENTER,
            // });
            dispatch(stateValueSetter([]));

            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  // hasMore: res?.data?.data?.claims?.length === sliceState.pageSize,
                  loadingMore: "",
                },
              })
            );
          } else {
            // cachePagination[`page_${sliceState.pageNumber}`] = res?.data?.data;
            let data = { ...sliceState.cachePagination };

            data[`page_${sliceState.pageNumber}`] = res.data.data;

            dispatch(stateValueSetter(res?.data?.data));
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  // hasMore: res?.data?.data?.claims?.length === sliceState.pageSize,
                  cachePagination: data,
                },
              })
            );
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  // hasMore: res?.data?.data?.claims?.length === sliceState.pageSize,
                  loadingMore: "",
                },
              })
            );
          }
          // axios.all([dataItems, filteredItems]).then((res) => {
          //   dispatch(
          //     setListingState({
          //       listing: listingAction,
          //       value: {
          //         loadingMore: "",
          //       },
          //     })
          //   );
          // });
          return res?.data;
        })
        .catch((error) => {
          // console.log("_error--->>", error);
          dispatch(
            setListingState({
              listing: listingAction,
              value: {
                initialLoading: false,
                loadingMore: "",
                hasMore: false,
              },
            })
          );
        });

      ////// //////////////
    }
  } catch (e) {
    // console.log("commonPagination_e--->>", e);
    dispatch(
      setListingState({
        listing: listingAction,
        value: {
          initialLoading: false,
          loadingMore: "",
          loadingFilters: "",
          hasMore: false,
        },
      })
    );
    // dispatch(stateValueSetter([]));

    return false;
  }
}
export async function commonPaginationWithTimeStamp(
  getState,
  paramsTemp,
  params,
  dispatch,
  stateValueSetter,
  listingAction,
  apiURL,
  conditionalDispatch,
  conditionalApiURL,
  conditionalStateValueSetter
) {
  try {
    let sliceState = getState().npReducers.filters[listingAction];
    let cacheData = { ...sliceState.cachePagination };
    let cachParamsState = { ...sliceState.cacheParams };
    let cache = cacheData[`page_${sliceState.pageNumber}`];

    if (
      cache &&
      // cache.claims.length > 0 &&
      JSON.stringify(cachParamsState) === JSON.stringify(paramsTemp)
    ) {
      dispatch(stateValueSetter(cache));

      // return;
    } else {
      dispatch(
        setListingState({
          listing: listingAction,
          value: {
            loadingMore: "data-loading",
            // loadingFilters: "data-loading",
          },
        })
      );

      if (JSON.stringify(cachParamsState) !== JSON.stringify(paramsTemp)) {
        await dispatch(
          setListingState({
            listing: listingAction,
            value: {
              cachePagination: {},
            },
          })
        );
        sliceState = getState().npReducers.filters[listingAction];
      }
      let filteredItems;
      // conditional func call here
      if (conditionalDispatch) {
        let dateObj = {
          to: params.to,
          from: params.from,
        };

        ///// Conditional API CALL start //////
        dispatch(
          setListingState({
            listing: listingAction,
            value: {
              // loadingMore: "data-loading",
              loadingFilters: "data-loading",
            },
          })
        );

        filteredItems = await genericAxiosCall(
          serverRoutes[conditionalApiURL],
          "get",
          "",
          params
        ).then((res) => {
          if (res?.data?.success) {
            dispatch(conditionalStateValueSetter(res?.data?.data));
            filteredItems = res?.data?.data;
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  loadingFilters: "",
                },
              })
            );
          } else {
            dispatch(conditionalStateValueSetter([]));
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  loadingFilters: "",
                },
              })
            );
          }
        });

        ///// Conditional API CALL end //////
      }

      ///// API CALL //////
      dispatch(
        setListingState({
          listing: listingAction,
          value: {
            cacheParams: paramsTemp,
          },
        })
      );
      cacheParams = paramsTemp;
      params = { ...params, timestamp: new Date().getTime() };
      const dataItems = await genericAxiosCall(
        serverRoutes[apiURL],
        "get",
        "",
        params
      )
        .then((res) => {
          if (!res?.data?.success) {
            // toast.error(`Something went wrong`, {
            //   position: toast.POSITION.TOP_CENTER,
            // });
            dispatch(stateValueSetter([]));

            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  // hasMore: res?.data?.data?.claims?.length === sliceState.pageSize,
                  loadingMore: "",
                },
              })
            );
          } else {
            // cachePagination[`page_${sliceState.pageNumber}`] = res?.data?.data;
            let data = { ...sliceState.cachePagination };

            data[`page_${sliceState.pageNumber}`] = res.data.data;

            dispatch(stateValueSetter(res?.data?.data));
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  // hasMore: res?.data?.data?.claims?.length === sliceState.pageSize,
                  cachePagination: data,
                },
              })
            );
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  // hasMore: res?.data?.data?.claims?.length === sliceState.pageSize,
                  loadingMore: "",
                },
              })
            );
          }
          // axios.all([dataItems, filteredItems]).then((res) => {
          //   dispatch(
          //     setListingState({
          //       listing: listingAction,
          //       value: {
          //         loadingMore: "",
          //       },
          //     })
          //   );
          // });
          return res?.data;
        })
        .catch((error) => {
          // console.log("_error--->>", error);
          dispatch(
            setListingState({
              listing: listingAction,
              value: {
                initialLoading: false,
                loadingMore: "",
                hasMore: false,
              },
            })
          );
        });

      ////// //////////////
    }
  } catch (e) {
    // console.log("_catch--->>", e);
    dispatch(
      setListingState({
        listing: listingAction,
        value: {
          initialLoading: false,
          loadingMore: "",
          loadingFilters: "",
          hasMore: false,
        },
      })
    );
    // dispatch(stateValueSetter([]));

    return false;
  }
}
export async function commonPaginationWithCatch({
  getState,
  paramsTemp,
  params,
  dispatch,
  stateValueGetter,
  stateValueSetter,
  stateValueFailed,
  listingAction,
  apiURL,
  conditionalDispatch,
  conditionalApiURL,
  conditionalStateValueSetter,
}) {
  try {
    let sliceState = getState().npReducers.filters[listingAction];
    let cacheData = { ...sliceState.cachePagination };
    let cachParamsState = { ...sliceState.cacheParams };
    let cache = cacheData[`page_${sliceState.pageNumber}`];

    if (
      cache &&
      // cache.claims.length > 0 &&
      JSON.stringify(cachParamsState) === JSON.stringify(paramsTemp)
    ) {
      // dispatch(stateValueSetter(cache));
      // return;
    } else {
      dispatch(stateValueGetter());

      if (JSON.stringify(cachParamsState) !== JSON.stringify(paramsTemp)) {
        await dispatch(
          setListingState({
            listing: listingAction,
            value: {
              cachePagination: {},
            },
          })
        );
        sliceState = getState().npReducers.filters[listingAction];
      }
      let filteredItems;
      // conditional func call here

      ///// API CALL //////
      dispatch(
        setListingState({
          listing: listingAction,
          value: {
            cacheParams: paramsTemp,
          },
        })
      );
      cacheParams = paramsTemp;

      genericAxiosCall(serverRoutes[apiURL], "get", "", params)
        .then((res) => {
          if (!res?.data?.success) {
            // toast.error(`Something went wrong`, {
            //   position: toast.POSITION.TOP_CENTER,
            // });
            // dispatch(stateValueSetter([]));
            dispatch(stateValueFailed());
          } else {
            // cachePagination[`page_${sliceState.pageNumber}`] = res?.data?.data;
            let data = { ...sliceState.cachePagination };

            data[`page_${sliceState.pageNumber}`] = res.data.data;

            dispatch(stateValueSetter(res?.data?.data));
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  // hasMore: res?.data?.data?.claims?.length === sliceState.pageSize,
                  cachePagination: data,
                },
              })
            );
          }
          // axios.all([dataItems, filteredItems]).then((res) => {
          //   dispatch(
          //     setListingState({
          //       listing: listingAction,
          //       value: {
          //         loadingMore: "",
          //       },
          //     })
          //   );
          // });
          return res?.data;
        })
        .catch((error) => {
          // console.log("_error--->>", error?.response?.data);
          dispatch(stateValueFailed());
        });

      ////// //////////////
    }
  } catch (e) {
    // console.log("_catch--->>", e);

    store.dispatch(stateValueFailed());
    // dispatch(stateValueSetter([]));

    return false;
  }
}
export async function commonPaginationForPost(
  getState,
  paramsTemp,
  params,
  dispatch,
  stateValueSetter,
  listingAction,
  apiURL,
  conditionalDispatch,
  conditionalApiURL,
  conditionalStateValueSetter,
  noReload
) {
  try {
    let sliceState = getState().npReducers.filters[listingAction];
    let cacheData = { ...sliceState.cachePagination };
    let cachParamsState = { ...sliceState.cacheParams };
    let cache = cacheData[`page_${sliceState.pageNumber}`];

    if (
      cache &&
      // cache.claims.length > 0 &&
      JSON.stringify(cachParamsState) === JSON.stringify(paramsTemp)
    ) {
      dispatch(
        setListingState({
          listing: listingAction,
          value: {
            loadingMore: "",
            // loadingFilters: "data-loading",
          },
        })
      );
      dispatch(stateValueSetter(cache));

      // return;
      // console.log(
      //   "commonPaginationForPost_if--->>",
      //   apiURL,
      //   "cachParamsState:",
      //   cachParamsState,
      //   "paramsTemp:",
      //   paramsTemp
      // );
    } else {
      // console.log(
      //   "commonPaginationForPost_else--->>",
      //   apiURL,
      //   "cachParamsState:",
      //   cachParamsState,
      //   "paramsTemp:",
      //   paramsTemp
      // );

      if (!noReload) {
        await dispatch(
          setListingState({
            listing: listingAction,
            value: {
              loadingMore: "data-loading",
              // loadingFilters: "data-loading",
            },
          })
        );
      }

      if (JSON.stringify(cachParamsState) !== JSON.stringify(paramsTemp)) {
        await dispatch(
          setListingState({
            listing: listingAction,
            value: {
              cachePagination: {},
            },
          })
        );
        sliceState = getState().npReducers.filters[listingAction];
      }
      let filteredItems;
      // conditional func call here
      if (conditionalDispatch) {
        let dateObj = {
          to: params.to,
          from: params.from,
        };

        ///// Conditional API CALL start //////
        dispatch(
          setListingState({
            listing: listingAction,
            value: {
              // loadingMore: "data-loading",
              loadingFilters: "data-loading",
            },
          })
        );

        filteredItems = await genericAxiosCall(
          serverRoutes[conditionalApiURL],
          "post",
          params,
          ""
        ).then((res) => {
          if (res?.data?.success) {
            dispatch(conditionalStateValueSetter(res?.data?.data));
            filteredItems = res?.data?.data;
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  loadingFilters: "",
                },
              })
            );
          } else {
            dispatch(conditionalStateValueSetter([]));
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  loadingFilters: "",
                },
              })
            );
          }
        });

        ///// Conditional API CALL end //////
      }

      ///// API CALL //////
      dispatch(
        setListingState({
          listing: listingAction,
          value: {
            cacheParams: paramsTemp,
          },
        })
      );
      cacheParams = paramsTemp;

      // console.log("genericAxiosCall_post--->>", apiURL, params);

      const { userPreferences } = store?.getState()?.pReducers.user;

      // Check if params do not contain 'limit' or 'Limit'
      if (
        !("limit" in params || "Limit" in params) &&
        (apiURL === "EXECUTE_QUERY" || apiURL === "EXECUTE_QUERY_MY_TASKS")
      ) {
        params["limit"] = userPreferences?.NoOfRecordInTable || 10;
        // singleLogout(dispatch);
      }

      const dataItems = await genericAxiosCall(
        serverRoutes[apiURL],
        "post",
        params,
        ""
      )
        .then((res) => {
          if (!res?.data?.success) {
            // toast.error(`Something went wrong`, {
            //   position: toast.POSITION.TOP_CENTER,
            // });
            dispatch(stateValueSetter([]));

            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  // hasMore: res?.data?.data?.claims?.length === sliceState.pageSize,
                  loadingMore: "",
                },
              })
            );
          } else {
            // cachePagination[`page_${sliceState.pageNumber}`] = res?.data?.data;
            let data = { ...sliceState.cachePagination };

            data[`page_${sliceState.pageNumber}`] = res.data.data;

            dispatch(stateValueSetter(res?.data?.data));
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  // hasMore: res?.data?.data?.claims?.length === sliceState.pageSize,
                  cachePagination: data,
                },
              })
            );
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  // hasMore: res?.data?.data?.claims?.length === sliceState.pageSize,
                  loadingMore: "",
                },
              })
            );
          }
          // axios.all([dataItems, filteredItems]).then((res) => {
          //   dispatch(
          //     setListingState({
          //       listing: listingAction,
          //       value: {
          //         loadingMore: "",
          //       },
          //     })
          //   );
          // });
          return res?.data;
        })
        .catch((error) => {
          // console.log("_error--->>", error?.response?.data);
          dispatch(stateValueSetter([]));

          dispatch(
            setListingState({
              listing: listingAction,
              value: {
                initialLoading: false,
                loadingMore: "",
                hasMore: false,
              },
            })
          );
        });

      return dataItems;
      ////// //////////////
    }
  } catch (e) {
    console.log("_catch--->>", e);
    dispatch(
      setListingState({
        listing: listingAction,
        value: {
          initialLoading: false,
          loadingMore: "",
          loadingFilters: "",
          hasMore: false,
        },
      })
    );
    // dispatch(stateValueSetter([]));

    return false;
  }
}

// ---

export async function commonPaginationWithParams(
  getState,
  paramsTemp,
  params,
  dispatch,
  stateValueSetter,
  listingAction,
  apiURL,
  conditionalDispatch,
  conditionalApiURL,
  conditionalStateValueSetter
) {
  try {
    let sliceState = getState().npReducers.filters[listingAction];
    let cacheData = { ...sliceState.cachePagination };
    let cachParamsState = { ...sliceState.cacheParams };
    let cache = cacheData[`page_${sliceState.pageNumber}`];

    if (
      cache &&
      // cache.claims.length > 0 &&
      JSON.stringify(cachParamsState) === JSON.stringify(paramsTemp)
    ) {
      dispatch(
        stateValueSetter({
          data: cache,
          params: params,
        })
      );

      // return;
    } else {
      dispatch(
        setListingState({
          listing: listingAction,
          value: {
            loadingMore: "data-loading",
            // loadingFilters: "data-loading",
          },
        })
      );

      if (JSON.stringify(cachParamsState) !== JSON.stringify(paramsTemp)) {
        await dispatch(
          setListingState({
            listing: listingAction,
            value: {
              cachePagination: {},
            },
          })
        );
        sliceState = getState().npReducers.filters[listingAction];
      }
      let filteredItems;
      // conditional func call here
      if (conditionalDispatch) {
        let dateObj = {
          to: params.to,
          from: params.from,
        };

        ///// Conditional API CALL start //////
        dispatch(
          setListingState({
            listing: listingAction,
            value: {
              // loadingMore: "data-loading",
              loadingFilters: "data-loading",
            },
          })
        );

        filteredItems = await genericAxiosCall(
          serverRoutes[conditionalApiURL],
          "get",
          "",
          params
        ).then((res) => {
          if (res?.data?.success) {
            dispatch(conditionalStateValueSetter(res?.data?.data));
            filteredItems = res?.data?.data;
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  loadingFilters: "",
                },
              })
            );
          } else {
            dispatch(conditionalStateValueSetter([]));
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  loadingFilters: "",
                },
              })
            );
          }
        });

        ///// Conditional API CALL end //////
      }

      ///// API CALL //////
      dispatch(
        setListingState({
          listing: listingAction,
          value: {
            cacheParams: paramsTemp,
          },
        })
      );
      cacheParams = paramsTemp;

      const dataItems = await genericAxiosCall(
        serverRoutes[apiURL],
        "get",
        "",
        params
      )
        .then((res) => {
          if (!res?.data?.success) {
            // toast.error(`Something went wrong`, {
            //   position: toast.POSITION.TOP_CENTER,
            // });
            dispatch(stateValueSetter([]));

            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  // hasMore: res?.data?.data?.claims?.length === sliceState.pageSize,
                  loadingMore: "",
                },
              })
            );
          } else {
            // cachePagination[`page_${sliceState.pageNumber}`] = res?.data?.data;
            let data = { ...sliceState.cachePagination };

            data[`page_${sliceState.pageNumber}`] = res.data.data;

            dispatch(
              stateValueSetter({
                data: res?.data?.data,
                params: params,
              })
            );

            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  // hasMore: res?.data?.data?.claims?.length === sliceState.pageSize,
                  cachePagination: data,
                },
              })
            );
            dispatch(
              setListingState({
                listing: listingAction,
                value: {
                  // hasMore: res?.data?.data?.claims?.length === sliceState.pageSize,
                  loadingMore: "",
                },
              })
            );
          }
          // axios.all([dataItems, filteredItems]).then((res) => {
          //   dispatch(
          //     setListingState({
          //       listing: listingAction,
          //       value: {
          //         loadingMore: "",
          //       },
          //     })
          //   );
          // });
          return res?.data;
        })
        .catch((error) => {
          // console.log("_error--->>", error?.response?.data);
          dispatch(
            setListingState({
              listing: listingAction,
              value: {
                initialLoading: false,
                loadingMore: "",
                hasMore: false,
              },
            })
          );
        });

      ////// //////////////
    }
  } catch (e) {
    // console.log("_catch--->>", e);
    dispatch(
      setListingState({
        listing: listingAction,
        value: {
          initialLoading: false,
          loadingMore: "",
          loadingFilters: "",
          hasMore: false,
        },
      })
    );
    // dispatch(stateValueSetter([]));

    return false;
  }
}

//----

// common function for getting every page preference setting like column hide/show

export async function commonPagePrefeSetting(
  dispatch,
  stateValueSetter,
  userId,
  pageName,
  setLoading
) {
  try {
    // console.log("commonPagePrefeSetting--->>");
    // if (setLoading) {
    //   setLoading(true);
    // }
    const res = await genericAxiosCall(
      `${serverRoutes?.GET_PAGE_COLUMN_HIDE_SHOW_PREF}userId=${userId}&page=${pageName}`,
      "get",
      "",
      ""
    );

    if (res?.data?.success) {
      if (res?.data?.data?.length > 0) {
        let obj = {
          pageName: res?.data?.data[0].page,
          column: {
            ...JSON.parse(res?.data?.data[0].preference),
          },
        };
        dispatch(stateValueSetter(obj));
      }
    }
    // if (setLoading) {
    //   setLoading(false);
    // }
  } catch (e) {
    console.log("catch_e--->>", e);
  } finally {
    setLoading && setLoading(false);
  }
}

export const getPicPlaceholderInitials = (displayName) => {
  return `${displayName?.split(" ")?.[0]?.substring(0, 1)?.toUpperCase()}${
    displayName?.split(" ")?.[1]
      ? displayName?.split(" ")?.[1]?.substring(0, 1)?.toUpperCase()
      : ""
  }`;
};

export const getAmountInKAndM = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
};

export const roundAmount = (value) =>
  value % 1 >= 0.5 ? Math.ceil(value) : Math.floor(value);

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const daysRemainingUntilSpecifiedDateColor = (specifiedDate) => {
  // Convert the specified date to a JavaScript Date object
  const specifiedDateObj = new Date(specifiedDate);

  // Get the current date
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = specifiedDateObj.getTime() - currentDate.getTime();

  // Calculate the number of days remaining
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  // if (daysRemaining < 0) {
  //   return "darkred";
  // } else
  if (daysRemaining < 0) {
    return "red";
  } else if (daysRemaining <= 30) {
    return "#ff9800";
  } else {
    return "#3f943f";
  }
  // return daysRemaining;
};

export const daysRemainingUntilSpecifiedDate = (specifiedDate) => {
  // Convert the specified date to a JavaScript Date object
  const specifiedDateObj = new Date(specifiedDate);

  // Get the current date
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = specifiedDateObj.getTime() - currentDate.getTime();

  // Calculate the number of days remaining
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  if (daysRemaining < 0) {
    return "Expired";
  } else {
    return `${daysRemaining} Days Remaining`;
  }

  // return daysRemaining;
};

// ---------- For Query builder ------------------------------

export const handleExecuteForQueryBuilder = async ({
  noOfRows,
  setNoOfRows,
  dispatch,
  transType,
  removeExecute,
  bucketId = "",
  workQueueId,
  parentWorkQueueId,
  inQueue,
  isManual,
}) => {
  // console.log("123123 params  --- ", noOfRows);
  try {
    let modifiedArr = [...noOfRows];
    let errCheck = false;

    // validateFields
    for (let i = 0; i < noOfRows.length; i++) {
      // console.log("123123 length ", noOfRows[i]?.data?.length);
      if (
        (noOfRows[i]?.operator == "BETWEEN" &&
          noOfRows[i]?.data?.split("AND")?.[0]?.trim().length < 1 &&
          noOfRows[i]?.data?.split("AND")?.[1]?.trim().length < 1) ||
        JSON.stringify(`${noOfRows[i]?.data}`).length < 1 ||
        !noOfRows[i]?.data ||
        noOfRows[i]?.data?.length < 1
      ) {
        modifiedArr[i] = {
          ...modifiedArr[i],
          emptyError: true,
        };
        // setNoOfRows(modifiedArr);
      } else {
        modifiedArr[i] = {
          ...modifiedArr[i],
          emptyError: false,
        };
        // setNoOfRows(modifiedArr);
      }
      // setNoOfRows(modifiedArr);
    }

    for (let i = 0; i < modifiedArr.length; i++) {
      if (modifiedArr[i]?.emptyError == true) {
        // errCheck = true;
        return;
      }
    }

    if (noOfRows[0]?.data && !errCheck) {
      for (let i = 0; i < noOfRows.length; i++) {
        if (noOfRows[i]?.value == "CR.DOS" || noOfRows[i]?.value == "CP.DOS") {
          if (noOfRows[i]?.operator == "BETWEEN") {
            let dummy = noOfRows[i]?.data?.split("AND");
            let startDate = new Date(dummy[0]);
            let endDate = new Date(dummy[1]);
            modifiedArr[i] = {
              ...modifiedArr[i],
              data: `'${startDate?.getFullYear()}-${
                startDate?.getMonth() + 1
              }-${startDate?.getDate()}' AND '${endDate?.getFullYear()}-${
                endDate?.getMonth() + 1
              }-${endDate?.getDate()}'`,
            };
          } else {
            let singleDate = new Date(`${noOfRows[i]?.data}`);
            modifiedArr[i] = {
              ...modifiedArr[i],
              data: `'${singleDate.getFullYear()}-${
                singleDate.getMonth() + 1
              }-${singleDate.getDate()}'`,
            };
          }
        }
        if (
          noOfRows[i]?.valueEditorType == "multi select" &&
          noOfRows[i]?.inputType == "multi select"
        ) {
          let myData = noOfRows[i]?.data;
          let conditionalOperator = noOfRows[i]?.ConditionalOperator;
          // if (myData?.length < 1) {
          //   modifiedArr[i] = {
          //     //  ...modifiedArr[i], emptyError: true

          //     ...modifiedArr[i],
          //     emptyError: true,
          //     // id: modifiedArr[i]?.id,
          //     // inputType: modifiedArr[i]?.inputType,
          //     // label: modifiedArr[i]?.label,
          //     // operators: modifiedArr[i]?.operators,
          //     // operator: modifiedArr[i]?.operator,
          //     // transactionType: modifiedArr[i]?.transactionType,
          //     // value: modifiedArr[i]?.value,
          //     // valueEditorType: modifiedArr[i]?.valueEditorType,
          //   };
          //   setNoOfRows(modifiedArr);
          //   return;
          // } else {
          const formattedString = `(${myData
            .map((str) => `'${str}'`)
            .join(",")})`;

          if (conditionalOperator) {
            modifiedArr[i] = {
              ...modifiedArr[i],
              data: formattedString,
              conditionalOperator: conditionalOperator,
            };
          } else {
            modifiedArr[i] = {
              ...modifiedArr[i],
              data: formattedString,
            };
          }
          // }
        }

        if (
          noOfRows[i]?.inputType == "text" ||
          noOfRows[i]?.inputType == "select"
        ) {
          if (noOfRows[i]?.operator == "BETWEEN") {
            let dummy = noOfRows[i]?.data?.split("AND");
            let start = dummy[0];
            let end = dummy[1];
            modifiedArr[i] = {
              ...modifiedArr[i],
              data: `'${start}' AND '${end}'`,
            };
          } else {
            modifiedArr[i] = {
              ...modifiedArr[i],
              data: `'${noOfRows[i]?.data}'`,
            };
          }
        }
      }
      let whereJson = modifiedArr?.map((item) => {
        return {
          // ...item,
          Id: item?.id,
          TableName: transType == 3 ? "ClaimRequest" : "ClaimPayment",
          Column: item?.value,
          IsSelected: true,
          Operator: item?.operator
            ? item?.operator
            : `${item?.operators}`.split(",")?.[0],
          Value: item?.data,
          ConditionalOperator: item?.conditionalOperator || null,
        };
      });
      let finalObj = {};
      if (inQueue) {
        finalObj = {
          WorkQueueId: workQueueId || 0,
          Start: 0,
          Limit: 10,
          isManual: isManual,
          // Type: transType == 3 || transType == 837 ? "837" : "835",
          Type:
            transType == 1 || transType == 3 || transType == 837
              ? "837"
              : "835",

          WhereJson: JSON.stringify(whereJson),
          RunById: false,
          ...(bucketId && {
            BucketId: bucketId,
          }),
        };
      } else {
        finalObj = {
          WorkQueueId: inQueue ? 0 : workQueueId || 0,
          Start: 0,
          Limit: 10,
          isManual: isManual,
          // Type: transType == 3 || transType == 837 ? "837" : "835",
          Type:
            transType == 1 || transType == 3 || transType == 837
              ? "837"
              : "835",
          WhereJson: JSON.stringify(whereJson),
          RunById: false,
          ...(bucketId && {
            BucketId: bucketId,
          }),
          ParentWorkQueueId: parentWorkQueueId || 0,
        };
      }

      // await setCustomWhereJson(JSON.stringify(whereJson));

      // setMyCustomJson(noOfRows);
      let newArr = [...noOfRows];
      let operatorCheck = newArr.map((item) => {
        return {
          ...item,
          operator: item?.operator
            ? item?.operator
            : `${item?.operators}`.split(",")?.[0],
        };
      });
      const data = {
        myCustomJson: operatorCheck,
        customWhereJson: JSON.stringify(whereJson),
      };
      if (removeExecute) {
        // return JSON.stringify(whereJson);
        return data;
        // console.log("check ", JSON.stringify(whereJson));
      } else {
        dispatch(executeQuery(finalObj));
        return data;
      }
      // setQueryTrigger &&
      // setQueryTrigger(false);

      // return data;
    } else {
    }
  } catch (err) {
    // console.log("err--->>", err);
  }
};

// ---------- For Query builder ------------------------------

export const checkForSupportingDoc = (arr) => {
  for (let index = 0; index < arr.length; index++) {
    if (
      arr[index]?.contentType == "text/plain" ||
      arr[index].contentType === "image/jpeg" ||
      arr[index].contentType === "image/png"
    ) {
      return true;
    }
  }
  return false;
};

export const getCodeWithMultipleDescription = (
  codeString,
  descriptionString
) => {
  if (!codeString) return "-";

  const codes = codeString.split(",");
  const descriptions = descriptionString
    ? descriptionString.match(/"[^"]*"|[^,]+/g)
    : [];

  return codes.map((code, index) => {
    const description = descriptions[index]
      ? descriptions[index].replace(/(^"|"$)/g, "").trim()
      : "-";
    return (
      <span key={index}>
        <BootstrapTooltip title={description}>
          <span>{code.trim()}</span>
        </BootstrapTooltip>
        {index !== codes.length - 1 && ", "}
      </span>
    );
  });
};

export const getCurrentClientId = () => {
  // console.log(
  //   "getCurrentClientId()_1--->>",
  //   store.getState().pReducers.user.clientId
  // );
  return store.getState().pReducers?.user?.clientId;
};
export const isParsableToJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const displayTextWithTooltip = (text, maxLength = 15) => {
  if (!text) return "-";
  return text.length > maxLength ? text : text;
};

// dashboard ---- >> "insightsSreensCommonFilter"
// tabular ---- >> "denialSreensCommonFilter"

// ====================== set Intial dates for app ===============

export function getDefaultInitialDate4App(dispatch, obj) {
  try {
    // console.log("getDefault=================--->>", obj);
    let { dateMainState, dateForMonth, dateForYear, dateForQuarter } =
      obj?.date;

    // console.log("dateMainState--->>", dateMainState);
    // console.log("dateForMonth--->>", dateForMonth);
    // console.log("dateForYear--->>", dateForYear);
    // console.log("dateForQuarter--->>", dateForQuarter);

    function formatDate(date) {
      let month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is zero-based
      let day = String(date.getUTCDate()).padStart(2, "0");
      let year = date.getUTCFullYear();
      return `${month}/${day}/${year}`;
    }

    if (!dateMainState) {
      throw new Error("Invalid or missing dateMainState");
    }

    switch (dateMainState) {
      case "Months":
        if (!dateForMonth || !dateForYear) {
          throw new Error(
            "Missing data for Months: dateForMonth or dateForYear"
          );
        }
        let month = parseInt(dateForMonth.value) - 1; // Convert to zero-based month index
        let yearForMonth = parseInt(dateForYear.value);

        if (isNaN(month) || isNaN(yearForMonth)) {
          throw new Error("Invalid month or year values for Months");
        }

        const fromDateMonth = formatDate(
          new Date(Date.UTC(yearForMonth, month, 1))
        ); // First day of the month
        const toDateMonth = formatDate(
          new Date(Date.UTC(yearForMonth, month + 1, 0))
        ); // Last day of the month

        // dashboard ---- >> "insightsSreensCommonFilter"
        // tabular ---- >> "denialSreensCommonFilter"

        // console.log("Months_output--->>", {
        //   from: fromDateMonth,
        //   to: toDateMonth,
        // });

        dispatch(
          setListingState({
            listing: "insightsSreensCommonFilter",
            value: {
              dateMainState: dateMainState,
              dateForMonth: dateForMonth,
              dateForYear: dateForYear,
              dateForQuarter: dateForQuarter,
              from: fromDateMonth,
              to: toDateMonth,
            },
          })
        );

        dispatch(
          setListingState({
            listing: "denialSreensCommonFilter",
            value: {
              from: fromDateMonth,
              to: toDateMonth,
            },
          })
        );
        return; // End function execution

      case "Years":
        if (!dateForYear) {
          throw new Error("Missing data for Years: dateForYear");
        }
        let year = parseInt(dateForYear.value);

        if (isNaN(year)) {
          throw new Error("Invalid year value for Years");
        }

        const fromDateYear = formatDate(new Date(Date.UTC(year, 0, 1))); // January 1st
        const toDateYear = formatDate(new Date(Date.UTC(year, 0, 31))); // January 31st

        const exactYearDates = {
          from: formatDate(new Date(Date.UTC(year, 0, 1))), // Full year start
          to: formatDate(new Date(Date.UTC(year, 11, 31))), // Full year end
        };
        // console.log("Years_output--->>", {
        //   from: fromDateYear,
        //   to: toDateYear,
        //   exactYearDates,
        // });

        dispatch(
          setListingState({
            listing: "insightsSreensCommonFilter",
            value: {
              dateMainState: dateMainState,
              dateForMonth: dateForMonth,
              dateForYear: dateForYear,
              dateForQuarter: dateForQuarter,
              from: exactYearDates?.from,
              to: exactYearDates?.to,
            },
          })
        );

        dispatch(
          setListingState({
            listing: "denialSreensCommonFilter",
            value: {
              from: fromDateYear,
              to: toDateYear,
            },
          })
        );
        return; // End function execution

      case "Quarter":
        if (!dateForQuarter) {
          throw new Error("Missing data for Quarters: dateForQuarter");
        }
        let quarter = parseInt(
          dateForQuarter.value.split("-")[0].replace("Q", "")
        );
        let yearForQuarter = parseInt(dateForQuarter.value.split("-")[1]);

        if (isNaN(quarter) || isNaN(yearForQuarter)) {
          throw new Error("Invalid quarter or year values for Quarters");
        }

        let startMonth = (quarter - 1) * 3; // Q1: 0, Q2: 3, Q3: 6, Q4: 9
        const fromDateQuarter = formatDate(
          new Date(Date.UTC(yearForQuarter, startMonth, 1))
        ); // First day of the first month in the quarter
        const toDateQuarter = formatDate(
          new Date(Date.UTC(yearForQuarter, startMonth + 1, 0))
        ); // Last day of the first month in the quarter

        const exactQuarterDates = {
          from: formatDate(new Date(Date.UTC(yearForQuarter, startMonth, 1))), // Quarter start
          to: formatDate(
            new Date(Date.UTC(yearForQuarter, startMonth + 3, 0)) // Quarter end
          ),
        };

        // console.log("Quarter_output--->>", {
        //   from: fromDateQuarter,
        //   to: toDateQuarter,
        //   exactQuarterDates,
        // });

        dispatch(
          setListingState({
            listing: "insightsSreensCommonFilter",
            value: {
              dateMainState: dateMainState,
              dateForMonth: dateForMonth,
              dateForYear: dateForYear,
              dateForQuarter: dateForQuarter,
              from: exactQuarterDates?.from,
              to: exactQuarterDates?.to,
            },
          })
        );

        dispatch(
          setListingState({
            listing: "denialSreensCommonFilter",
            value: {
              from: fromDateQuarter,
              to: toDateQuarter,
            },
          })
        );
        return; // End function execution

      default:
        throw new Error("Invalid dateMainState");
    }
  } catch (err) {
    console.log("Err--->>", err.message);
  }
}

// ============= used inside the NewPreference compo ================
export const getDefaultDateForDashboard2 = (option) => {
  try {
    const currentDate = new Date();

    const getCurrentMonthLabel = (month) => {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return monthNames[month - 1];
    };

    const getDateForMonth = (monthOffset) => {
      const date = new Date();
      date.setMonth(date.getMonth() + monthOffset);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return {
        label: getCurrentMonthLabel(month),
        value: month.toString(),
        year: year,
      };
    };

    const getDateForYear = (yearOffset = 0) => {
      const year = currentDate.getFullYear() + yearOffset;
      return {
        label: year.toString(),
        value: year,
      };
    };

    const getLastQuarter = () => {
      const date = new Date();
      date.setMonth(date.getMonth() - 3);
      const quarter = Math.floor(date.getMonth() / 3) + 1;
      const year = date.getFullYear();
      return {
        label: `Q${quarter} ${year}`,
        value: `Q${quarter}-${year}`,
      };
    };

    const getCurrentQuarter = () => {
      const date = new Date();
      const quarter = Math.floor(date.getMonth() / 3) + 1;
      const year = date.getFullYear();
      return {
        label: `Q${quarter} ${year}`,
        value: `Q${quarter}-${year}`,
      };
    };

    const getDateForQuarter = (quarterOffset) => {
      const quarters = getLastEightQuarters();
      return quarters[quarterOffset];
    };

    const getFromAndToDates = (option) => {
      let fromDate, toDate;

      if (option === "Current Month") {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();

        fromDate = new Date(year, month, 1); // First day of the month
        toDate = new Date(year, month + 1, 0); // Last day of the month
      } else if (option === "Last Month") {
        const date = new Date();
        date.setMonth(date.getMonth() - 1); // Go to last month
        const year = date.getFullYear();
        const month = date.getMonth();

        fromDate = new Date(year, month, 1); // First day of last month
        toDate = new Date(year, month + 1, 0); // Last day of last month
      } else if (option === "Current Quarter") {
        const date = new Date();
        const currentMonth = date.getMonth();
        const currentYear = date.getFullYear();

        const quarter = Math.floor(currentMonth / 3); // Current quarter
        const startMonth = quarter * 3;
        const endMonth = startMonth + 2;

        fromDate = new Date(currentYear, startMonth, 1); // First day of the quarter
        toDate = new Date(currentYear, endMonth + 1, 0); // Last day of the quarter
      } else if (option === "Last Quarter") {
        const date = new Date();
        const currentMonth = date.getMonth();
        const currentYear = date.getFullYear();

        let quarter = Math.floor(currentMonth / 3) - 1; // Previous quarter
        let year = currentYear;

        if (quarter < 0) {
          // If the previous quarter is negative, go to last year's last quarter
          quarter = 3; // Last quarter of the previous year
          year = currentYear - 1;
        }

        const startMonth = quarter * 3;
        const endMonth = startMonth + 2;

        fromDate = new Date(year, startMonth, 1); // First day of the last quarter
        toDate = new Date(year, endMonth + 1, 0); // Last day of the last quarter
      }

      return {
        from: `${("0" + (fromDate.getMonth() + 1)).slice(-2)}/${(
          "0" + fromDate.getDate()
        ).slice(-2)}/${fromDate.getFullYear()}`,
        to: `${("0" + (toDate.getMonth() + 1)).slice(-2)}/${(
          "0" + toDate.getDate()
        ).slice(-2)}/${toDate.getFullYear()}`,
      };
    };

    const getQuarterFirstMonthDates = (option) => {
      let fromDate, toDate;

      const currentDate = new Date(); // Use the current date for calculations

      if (option === "Current Quarter") {
        // Get the current quarter's first month
        const currentMonth = currentDate.getMonth();
        const currentQuarter = Math.floor(currentMonth / 3); // 0 - First Quarter, 1 - Second Quarter, etc.
        const startMonth = currentQuarter * 3; // First month of the current quarter

        fromDate = new Date(currentDate.getFullYear(), startMonth, 1); // First day of the first month of the quarter
        toDate = new Date(currentDate.getFullYear(), startMonth + 1, 0); // Last day of the first month of the quarter
      } else if (option === "Last Quarter") {
        // Get the last quarter's first month
        const currentMonth = currentDate.getMonth();
        const currentQuarter = Math.floor(currentMonth / 3);
        let lastQuarter = currentQuarter - 1;
        let year = currentDate.getFullYear();

        if (lastQuarter < 0) {
          // If the last quarter is negative, go to the last quarter of the previous year
          lastQuarter = 3; // Last quarter of the previous year
          year -= 1;
        }

        const startMonth = lastQuarter * 3; // First month of the last quarter

        fromDate = new Date(year, startMonth, 1); // First day of the first month of the last quarter
        toDate = new Date(year, startMonth + 1, 0); // Last day of the first month of the last quarter
      } else if (option === "Current Month") {
        // Get the first and last days of the current month
        fromDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ); // First day of the current month
        toDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ); // Last day of the current month
      } else if (option === "Last Month") {
        // Get the first and last days of the last month
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth();

        // If it's January, last month is December of the previous year
        if (month === 0) {
          month = 11; // December
          year -= 1;
        } else {
          month -= 1;
        }

        fromDate = new Date(year, month, 1); // First day of the last month
        toDate = new Date(year, month + 1, 0); // Last day of the last month
      }

      return {
        tabularFrom: `${("0" + (fromDate.getMonth() + 1)).slice(-2)}/${(
          "0" + fromDate.getDate()
        ).slice(-2)}/${fromDate.getFullYear()}`,
        tabularTo: `${("0" + (toDate.getMonth() + 1)).slice(-2)}/${(
          "0" + toDate.getDate()
        ).slice(-2)}/${toDate.getFullYear()}`,
      };
    };

    const { from, to } = getFromAndToDates(option);
    const { tabularFrom, tabularTo } = getQuarterFirstMonthDates(option);

    // console.log(`from: "${from}"`);
    // console.log(`to: "${to}"`);

    switch (option) {
      case "Current Month":
        return {
          dateForMonth: getDateForMonth(0),
          dateForYear: getDateForYear(),
          dateForQuarter: getCurrentQuarter(),
          dateMainState: "Months",
          from,
          to,
          tabularFrom,
          tabularTo,
        };
      case "Last Month":
        return {
          dateForMonth: getDateForMonth(-1),
          dateForYear: getDateForYear(-1), // Adjusted to get the correct year for "Last Month"
          dateForQuarter: getLastQuarter(), // Adjusted to get the correct quarter for "Last Month"
          dateMainState: "Months",
          from,
          to,
          tabularFrom,
          tabularTo,
        };

      case "Current Quarter":
        return {
          dateForMonth: getDateForMonth(0),
          dateForYear: getDateForYear(),
          dateForQuarter: getDateForQuarter(0),
          dateMainState: "Quarter",
          from,
          to,
          tabularFrom,
          tabularTo,
        };
      case "Last Quarter":
        return {
          dateForMonth: getDateForMonth(0),
          dateForYear: getDateForYear(),
          dateForQuarter: getDateForQuarter(1),
          dateMainState: "Quarter",
          from,
          to,
          tabularFrom,
          tabularTo,
        };

      default:
        return null;
    }
  } catch (err) {
    console.log("err--->>", err);
  }
};
